<div class="module-card"
    [ngClass]="{'blue-module' :module.cardColor == 'blue', 'yellow-module' : module.cardColor == 'yellow', 'welcome' : welcomeDialog}"
    [go]="!welcomeDialog ? v.moduleDetails : undefined" [param]="{id : module.id}">
    <div class="module-header">

        <div class="df aic">
            <img class="module-img medium" [src]="module.img">
            <p class="ml5_i nmb fw600 fsm">{{module.name}}</p>
        </div>

        <div class="df aic mla" >
            <app-module-price-tag [module]="module"></app-module-price-tag>

            <ng-container *ngIf="!welcomeDialog">
                <button prevent [disabled]="!companyS.canTogglemodule" *ngIf="!companyS.hasModule(module.id)" mat-flat-button
                    color="primary" (click)="companyS.toggleModule(this.module.id)">Activar ahora</button>
                <button prevent [disabled]="!companyS.canTogglemodule" *ngIf="companyS.hasModule(module.id)" mat-flat-button
                    color="warn" (click)="companyS.toggleModule(this.module.id)">Quitar</button>
            </ng-container>

            <ng-container *ngIf="welcomeDialog">
                <!-- <mat-slide-toggle [formControl]="welcomeFc" >Acivar</mat-slide-toggle> -->
                <mat-checkbox class="activate-check" [formControl]="welcomeFc">Acivar</mat-checkbox>
            </ng-container>

        </div>
    </div>
    <div class="module-desc">
        <p class="fw600 nmb tal">{{module.title}}</p>
        <p class="fss tal">{{module.desc}}</p>
    </div>
    <div class="module-bottom" *ngIf="!welcomeDialog">
        <div class="module-funcionality" *ngFor="let funcionality of module.functionalities"
            [matTooltip]="funcionality.tooltip">
            <mat-icon class="mb10">{{funcionality.icon}}</mat-icon>
            <p class="module-funcionality-title">{{funcionality.title}}</p>
        </div>
    </div>
</div>