<mat-form-field  appearance="outline" *ngIf="!notShowFiler">
    <mat-label>Buscar</mat-label>
    <input type="text" #searchInput matInput placeholder="Buscar" (input)="applySearchFilter(searchInput.value)">
    <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
</mat-form-field>
<app-filters #eventFilter [filters]="action_filters" (onfiltersDone)="refreshAction($event)" *ngIf="!notShowFiler"></app-filters>
<table mat-table [dataSource]="dataMostrada" *ngIf="dataMostrada.length; else noData">
    <ng-container matColumnDef="Ornumber">
        <th mat-header-cell *matHeaderCellDef> {{responsiveS.w > 514 ? 'Documento' : 'Documento'}} </th>
        <td mat-cell *matCellDef="let element">
            <div class="df aic">
                <app-or-status-label *ngIf="responsiveS.w <= 514" class="df jcc" [circularStatus]="true"
                    [status]="element.status">
                </app-or-status-label>
                <mat-icon class="mr5 scaled-icon displayIcon">
                    {{getIcon(element)}}
                </mat-icon>
                <p class="nmb">
                    <span *ngIf="isAction(element) && element.type_id.isOr"><span class="c_t2">Nº {{element.title_id}}</span></span>
                    <span *ngIf="isAction(element) && element.type_id.isBudget"><span class="c_t2">Nº {{element.title_id}}</span></span>
                    <span *ngIf="isInvoice(element)"><span class="c_t2">Nº {{element.company_scope_id}}</span></span>
                    <span *ngIf="isComercial(element)"><span class="c_t2">Nº {{element.num_purchase}}</span></span>
                </p>
            </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="status" *ngIf="responsiveS.w > 514">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element">
            <app-or-status-label class="df" *ngIf="isAction(element)" [action]="element"></app-or-status-label>
            <app-invoice-status class="df" *ngIf="isInvoice(element)" [obj]="element" [status]="element.state"></app-invoice-status>
            <app-invoice-status class="df" *ngIf="isComercial(element)" [obj]="element" [status]="element.state"></app-invoice-status>
        </td>
    </ng-container>

    <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at?.shortFormat() }} </td>
    </ng-container>

    <ng-container matColumnDef="worker">
        <th mat-header-cell *matHeaderCellDef class="start-align">Tipo</th>
        <td mat-cell *matCellDef="let element" class="start-align">
            <app-or-type-label *ngIf="isDiferentArray(element.type)"  [showText]="false" [tipo]="element.type" [wrapOn]="2" [small]="responsiveS.w <= 514" [showTooltip]="true"></app-or-type-label>
            <span class="text_invoices" *ngIf="isInvoice(element)">
                {{ element.type_invoice?.name ? element.type_invoice?.name : 'Abono' }}
            </span>
            <span class="text_invoices" *ngIf="isComercial(element)">
                {{ element.typeName}}
            </span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goItem(row)"></tr>
</table>
<div class="df jcc mt10" *ngIf="this.dataSource.data.length > filasAdicionales ">
    <button  mat-stroked-button (click)="mostrarMasFilas()">{{this.textoBoton}}<mat-icon>expand_more</mat-icon></button>
</div>

<ng-template #noData>
    <p class="c_t2" style="padding: 0px 10px 0px 10px" *ngIf="!notShowFiler">No hay resultados</p>
</ng-template>

<div style="height: 10px;"></div>