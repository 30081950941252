import { Component } from '@angular/core';
import { endpoints } from 'src/app/constants/Endpoints';
import { WorkloadData } from 'src/app/custom-classes/WorkloadData';
import { IWorkload } from 'src/app/interfaces/IWorkload';
import { ApiService } from 'src/app/services/Api/api.service';

@Component({
  selector: 'app-shared-acces-workload',
  templateUrl: './shared-acces-workload.component.html',
  styleUrls: ['./shared-acces-workload.component.css']
})
export class SharedAccesWorkloadComponent{


  wd : WorkloadData;

  constructor(private apiS: ApiService) {
    var configuration: IWorkload = {
      defaultView: "or",
      switch: false,
      general: {
        users: this.apiS.usersCenters.bind(this.apiS),
        center: this.apiS.getMyCenter.bind(this.apiS)
      },
      or: {
        schedules: this.apiS.action.schedules.bind(this.apiS),
        updateSchedule: endpoints.updateSchedule,
        reorderAction: endpoints.reorderActions,
        assignAction: endpoints.assignAction,
        userPermission: true,
        views: {
          editOr: "dialog",
          createOr: undefined
        },
        draggable: {
          day: true,
          week: true,
          month: true,
        },
        canUserAssign: true,
      },
      appointments: {
        appointments: this.apiS.appointments.bind(this.apiS),
        updateAppointment: undefined,
        assignUserAppointment : undefined,
        canUserAssign : false,
        userPermission: false,
        views: {
          createAppointment: undefined,
          editAppointment: undefined
        },
        draggable: {
          day: true,
          week: true,
          month: true,
        }
      }
    }

    this.wd = new WorkloadData(configuration)
  }
  
}
