<app-page-structure [masterClass]="undefined" pageTitle="Conceptos" [autocompleteID]="'products'"
    searchLabel="Buscar concepto" listTitleText="Listado de conceptos"
    noDataCreateNew="Crear un nuevo concepto" [data]="[]"
    [displayedHeader]="displayedHeaders" [displayedColumns]="displayedColumns" [specialText]="specialText"
    [specialClass]="specialClass" [cellZize]="cellZize" [filters]="filters" [filter]=""
    [basicUserSubscription]="subS.isBasic"
    [goBackCustom]="{
        text:'Listado vehículos',
        view:v.garage
    }"
    [createButton]="
        {
            text : 'Crear concepto' , 
            icon : 'playlist_add_check_circle', 
            view : v.createConcept, 
            cssClass : 'onboarding-create-product'
        }"
    (onclickRow)="params.go(v.conceptDetails, $event.id)">
</app-page-structure>