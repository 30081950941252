<app-page-structure [pageTitle]="'Entrada de almacén'" [autocompleteID]="'storehouse-entry'"
    searchLabel="Buscar entrada"
    listTitleText="Listado de entradas"
    [data]="[]"
    [displayedHeader]=" ['Número',      'Tipo',             'Proveedor',        'Nº Documento',     'Productos',    'Total',    'Fecha']"
    [displayedColumns]="['number',      'entranceTypeName', 'provider.name',    'dnote_prov_num',   'prodLen',      'total',    'created_at']"
    [specialText]="     [undefined,     undefined,          getProvName,        undefined,           undefined,     'money',    undefined]"
    [specialClass]="    [undefined,     undefined,          undefined,          undefined,           undefined,     undefined,  undefined]"
    [preIcon]="         [entranceTypeIcon,undefined,        undefined,          undefined,           undefined,     undefined,  undefined]"
    [filter]="f" [filters]="filters"
    [dots]="dots"
    [quickFilter]="quickFilter"
    [createButton]="
        {
            text : 'Hacer entrada' , 
            icon : 'archive', view : 
            v.createStoreHouseEntry, 
            cssClass: 'onboarding-company-storehouse-create'
        }">
</app-page-structure>

<ng-template #dots>
    <button mat-menu-item (click)="showPreview(ps.selected!)">
        <mat-icon>find_in_page</mat-icon>
        Entrada de almacén
    </button>
</ng-template>