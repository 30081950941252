<app-close-dialog-button *ngIf="isOnDialog"></app-close-dialog-button>

<p mat-dialog-title *ngIf="isOnDialog">Pasar a stock</p>

<div class="create-item" [ngClass]="{'not-dialog' : !isOnDialog}" mat-dialog-content>
    <app-go-back *ngIf=" isEdit && ve" [text]="ve!.getName()" (click)="goBackVehicle()"></app-go-back>
    <app-card [contentLoaded]="vehicleForm && vehicleForm.loaded && loaded" [noStyle]="isOnDialog">
        <app-vn-vo-form></app-vn-vo-form>
        <div class="df jcc mt10" *ngIf="!isOnDialog">
            <div [ngTemplateOutlet]="createBtn"></div>
        </div>
    </app-card>
</div>

<div mat-dialog-actions *ngIf="isOnDialog">
    <div [ngTemplateOutlet]="createBtn"></div>
</div>


<ng-template #createBtn>
    <button [disabled]="!vehicleForm.form.valid" mat-flat-button color="primary" (click)="create()">
        Guardar
    </button>
</ng-template>


<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.garage">
    </app-model-not-found>
</ng-template>