import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { RouterService } from './router.service';
import { StorageService } from './storage.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ISessionService } from '../interfaces/ISessionService';
import { projectConfiguration } from '../app.module';
import { ViewPath } from '../app-routing.module';
import { ActivatedRouteSnapshot } from '@angular/router';
import { M_User } from '../models/M_User';
import { ApiService } from './Api/api.service';
import { getDestroyableServices } from '../decorators/DestroyOnLogOut';


/** Ein acces token */
export const eToken = "access_token";
/** Shared acces workload token*/
export const sharedAccesToekn = "shared_acces_token"
/** Current user ID key */
export const userIdKey = "user_id"
/** Is shared access view ? */
export function isSharedAccessView(rs: RouterService) {
  return rs.is(ViewPath.sharedAccesLogin, ViewPath.sharedAccesWorkload);
}

@Injectable({
  providedIn: 'root'
})

/**
 * [Service]
 * Manage the user session. Has the token.
 */
export class SessionService implements ISessionService {

  /** Ein acces token */
  readonly eToken = eToken
  /** Multi user workload access token*/
  readonly multiToken = sharedAccesToekn;

  /** Shared user */
  private sharedUser: M_User | undefined;

  NO_SESSION: string = "Unauthenticated.";

  constructor(private routerServie: RouterService, private storageS: StorageService, private confirmD: ConfirmDialogService, private apiS: ApiService, private injector: Injector) { }

  /** Obtiene el token de sesión */
  getToken() {
    if (this.isSharedAccessView) {
      // console.log("🧑🧑 Multi user. Token :", this.storageS.get(this.multiToken))
      return this.storageS.get(this.multiToken);
    }
    return this.storageS.get(this.eToken);
  }

  /**Genera el 'Header' de autorización para las llamadas http */
  geAuthorizationHeader() {
    let header = new HttpHeaders();
    header = header.set('Authorization', 'Bearer ' + this.getToken());
    return header;
  }

  /**Guarda el token de la sesión */
  setToken(token: string) {
    if (!this.isSharedAccessView) {
      this.storageS.save(this.eToken, token)
    }
    else {
      this.storageS.save(this.multiToken, token)
    }
  }

  hasSession(futureView?: ActivatedRouteSnapshot): boolean {

    if (futureView == undefined) {
      return this.getToken() != null;
    }

    const path = futureView.routeConfig?.path;
    if (path == undefined) { return false; }

    /** Well... */
    console.log("oke")
    const tokenKey = path == ViewPath.sharedAccesWorkload.path
      ? this.multiToken
      : this.eToken;

    return this.storageS.get(tokenKey) != null;
  }

  setSharedUser(u: M_User) {
    this.sharedUser = u;
  }

  get sharedUserId() {
    return this.sharedUser?.id;
  }

  /** Show a logout dialog. If res == true, call logout endpoint */
  logOutDialog(checkOtherSession: boolean = false) {
    this.confirmD.show({
      title: "Cerrar sesión",
      body: "¿Estás seguro de que quieres cerrar sesión?"
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.logOut(checkOtherSession);
      }
    })
  }

  /** Call the logout endpoint */
  logOut(checkOtherSession: boolean = false) {
    this.apiS.profile.logout().then(res => {
      this.clearEinaAndGoLanding(checkOtherSession);
    })
  }

  /** Clear the saved user data and the services and go to landing || shared acces */
  clearEinaAndGoLanding(checkOtherSession: boolean = false) {

    let token = this.getToken();
    this.storageS.clear();

    if (this.isSharedAccessView) {
      this.routerServie.goTo(projectConfiguration.multiUserLanding)?.then(v => {
        this.destroyServices();
      });
    }
    else {
      this.routerServie.goTo(projectConfiguration.landingView)?.then(v => {
        this.destroyServices();
      });
    }

    console.log("Tokenardo :", token)
    if (checkOtherSession && token != null) {
      this.confirmD.show({
        title: "La sesión ha caducado",
        body: "Vuelve a iniciar sesión",
        confirmTxt: "Ok",
        showCancel: false,
        type: "info"
      })
    }
  }

  get isSharedAccessView() {
    return isSharedAccessView(this.routerServie);
  }

  /** Search and execute all the services using (@DestroyOnLogOut) decorator */
  private destroyServices() {
    const destroyableServices = getDestroyableServices();
    destroyableServices.forEach(serviceClass => {
      const instance = this.injector.get(serviceClass);
      if (instance && typeof instance.destroyData === 'function') {
        instance.destroyData();
        console.log("🗑️ Destroyed on log out :", instance)
      }
    });
  }
}