import { EventEmitter } from "@angular/core";
import { Views } from "../View";
import { Step } from "./Step";

export class Section {

    /** Emits when the section found the target of the current step */
    onElementFound: EventEmitter<any> = new EventEmitter();

    /** Emits when the section is closed */
    onCloseSection: EventEmitter<number> = new EventEmitter();

    /** Emit when the section is finished */
    onCompleteSection: EventEmitter<boolean> = new EventEmitter();

    /** Section title */
    public title: string;

    /** Section description */
    public description: string;

    /** Steps of section */
    public steps: Step[];

    /** !! */
    public isPhone: boolean;

    /** Section is completed or not */
    public completed: boolean = false;

    /** Section is searching the current step target  */
    public searching: boolean = false;

    /** Section is closed or not  */
    public closed: boolean = true;

    /** Curren step array index */
    public index: number = 0;

    /**The step current focus element */
    target: any | undefined;

    /** If this variable is not undefined anf the user is not on the last screen that is suposed to be, the Section ends */
    userMustStayOn: Views | undefined;

    /** The section must show on the 'Inicio rápido'? */
    hidden : boolean = false;

    /** Component class */
    _class? : string;

    /** Start opened on 'Inicio rápido' section */
    startOpened : boolean = false;

    /** Show the close button onfy if the user hover the card */
    closeOnHover : boolean = false;

    constructor(title: string, description: string, steps: Step[], isPhone: boolean, hidden = false, _class? : string, startOpened? : boolean, closeOnHover? : boolean) {
        this.title = title;
        this.description = description;
        this.isPhone = isPhone;
        this.hidden = hidden;
        this._class = _class;
        this.startOpened = startOpened ? startOpened : false;
        this.closeOnHover = closeOnHover ? closeOnHover : false;
        this.steps = this.filterStepsByScreenSize(steps);
    }

    /** Called on the constructor. This funcion filter depending of the device and screen width */
    filterStepsByScreenSize(steps: Step[]) {
        var filtered = steps.filter(s =>
            (s.device == "all" || s.device == "phone" && this.isPhone || s.device == "pc" && !this.isPhone) &&
            this.filterByRemoveStepOnAttribue(s)
        )
        return filtered;
    }

    filterByRemoveStepOnAttribue(s : Step){

        var returnvalue = true;
        if (s.showStepOn == undefined){
            return returnvalue;
        }
        var value = s.showStepOn[1];
        var conditional = s.showStepOn[0];

        if (conditional == "smaller") {
            returnvalue = window.innerWidth < value;
        }
        else {
            returnvalue =  window.innerWidth >= value;
        }

        return returnvalue;
    }


    /** Start the section */
    startSection() {
        this.closed = false;
        this.userMustStayOn = this.step.view;
        this.searchElement().then(res => {
            this.onElmenet(res);
        });
    }

    /** Go to the next step. If the curren step is the last one, the section is compleated and closed */
    nextStep() {
        this.userMustStayOn = this.step.view;
        this.removeElementFocus();
        this.doStepAction();
        if (this.isLastStep) {
            this.closeSection();
            this.completed = true;
            this.onCompleteSection.emit(true);
        }
        else {
            this.index++;
            this.searchElement().then(res => {
                this.onElmenet(res);
            });
        }

    }

    /** If the current substep has {action : true}, click the elements by default*/
    doStepAction() {
        if (this.target && this.step.action) {
            let element = this.target
            if (element instanceof HTMLElement) {
                element.click();
            }
        }
    }

    /** Puts the focus on the current target. If is not on the screen, scrolls to it */
    setElementFocus() {
        if (this.target) {
            this.target.classList.add("onboarding-h");
            if (this.step.nextOnClickTarget) {
                this.target.addEventListener('mousedown', (e: any) => { this.doStepAction(); this.nextStep(); }, true);
            }
            if (!this.isInViewport(this.target)) {
                scrollTo({ top: (this.target.getBoundingClientRect().top) + scrollY - 20, behavior: "smooth" })
            }
        }
    }

    /** Determies if the current target is is the screen  */
    isInViewport(el: Element) {
        let rect = el.getBoundingClientRect();
        return rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }

    /** Remove the focus of the current step target */
    removeElementFocus() {
        if (this.target) {
            this.target.removeAllListeners!('mousedown');
            this.target.classList.remove("onboarding-h")
        }
    }

    /** When the current step target is found */
    onElmenet(element: Element | undefined) {
        this.target = element;
        this.setElementFocus();
        this.onElementFound.emit();
    }



    wait(n: number) { return new Promise(resolve => setTimeout(resolve, n)); }

    /** Search the current step target on the DOM. This funcion calls itself until found the element.*/
    private async searchElement(time?: number) {
        let count = time == undefined ? 0 : time;
        this.searching = true;
        return new Promise<Element | undefined>(resolve => {
            if (count >= 50) {
                resolve(undefined);
            }
            this.wait(100).then(res => {
                var element = document.getElementsByClassName(this.step.target)[0];
                if (element == undefined) {
                    /** Calls itself */
                    resolve(this.searchElement(count + 1));
                }
                else {
                    this.searching = false;
                    if (element instanceof HTMLElement) {
                        /** If the current element has the style 'display : "none", we search for the next element on the dom */
                        if (getComputedStyle(element).display == "none") {
                            var secondElement = document.getElementsByClassName(this.step.target)[1];
                            if (secondElement != undefined) {
                                element = secondElement;
                            }
                        }
                    }
                    resolve(element);
                }
            })
        })
    }

    /** Close the current section. Reset all tghe values */
    closeSection() {
        this.onCloseSection.emit(this.index);
        this.closed = true;
        this.searching = false;
        this.index = 0;
        this.removeElementFocus();
        this.target = undefined;
        this.userMustStayOn = undefined;
    }

    /** Process the screen change url.
     * If the 'userMustStayOn' variable is not undefined and the user is not on the 'userMustStayOn' view, close the section.
     */
    onChangeScreen(url: string) {
        if (this.userMustStayOn) {
            if ("/" + this.userMustStayOn.path != url) {
                this.closeSection();
            }
        }
    }

    /** Get the current step */
    get step() {
        return this.steps[this.index]
    }

    get onProcess() {
        return !this.searching && !this.closed;
    }

    get isLastStep() {
        return this.index == this.steps.length - 1;
    }
}