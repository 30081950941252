import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/Api/api.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { CustomFile } from 'src/app/custom-classes/CustomFile';
import { DayFilter, EnumFilter, Filter, FilterOption, SliderFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_PayCometInvoice } from 'src/app/models/M_PayCometInvoice';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

@Component({
  selector: 'app-pay-comet-invoices',
  templateUrl: './pay-comet-invoices.component.html',
  styleUrls: ['./pay-comet-invoices.component.css']
})
export class PayCometInvoicesComponent {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_PayCometInvoice>;
  f = filter;
  company_id: any;

  priceFilter = new SliderFilter("Precio", 200);
  filters: Filter[] = [
    new DayFilter("Fecha de creación"),
    new EnumFilter("Estado", new FilterOption("Pendiente"), new FilterOption("Pagada")),
    this.priceFilter
  ]

  constructor(private apiS: ApiService, private companyS: CompanyService) {
    this.initComponent();
  }

  async initComponent() {
    this.apiS.paymentByCompany(this.companyS.company.id!).then(res => {
      this.ps.initTable(res);
      this.priceFilter.changeMax(Math.max(...res.map(i => i.amount || 0)));
    });
  }

  async requestPDF(reference: string) {
    this.apiS.requestPDF(this.companyS.company, reference);
  }


  previewPDF(cf: CustomFile) {
    window.open(cf.src, '_blank')

  }
  havePDF(invoice: M_PayCometInvoice) {
    return invoice.file ? "Descargar" : "Pendiente"
  }

  payedName(invoice: M_PayCometInvoice) {
    return invoice.payed ? "Pagada" : "Pendiente"
  }

  payedClass(invoice: M_PayCometInvoice) {
    return invoice.payed ? "payed-paycomet-invoice" : "pending-paycomet-invoice"
  }


}
