import { Component, Inject, Optional } from '@angular/core';
import { M_Brand } from '../models/M_Brand';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/Api/api.service';
import { RouterService } from '../services/router.service';
import { ParameterStateComponent } from '../components/parameter-state/parameter-state.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-edit-brand',
  templateUrl: './create-edit-brand.component.html',
  styleUrls: ['./create-edit-brand.component.css']
})
export class CreateEditBrandComponent extends ParameterStateComponent {
  public form: UntypedFormGroup;
  loaded : boolean = false;
  isEdit : boolean = false;
  public brand : M_Brand | undefined;
  constructor(private apiS: ApiService,private formBuilder: UntypedFormBuilder,route: ActivatedRoute, routerS: RouterService,
    @Optional() public dialogRef: MatDialogRef<CreateEditBrandComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Brand | undefined,
  ){
    super(routerS, route, ["brand"]);
    this.form = this.formBuilder.group({
      name :['',[Validators.required]],
      type:['',[Validators.required]]});
    
      if(this.data != undefined && this.isOnDialog){ 
        this.brand = new M_Brand(this.data);
        console.log(this.data)
        this.form.patchValue({name:this.brand.name});
        this.form.patchValue({type:this.brand.type});
        this.form.get('name')?.disable()
        this.form.get('type')?.disable();
        this.isEdit = true;
        this.loaded = true;
      }
  }
  override onParam(_k: string, v: any) {
    console.log(v);
    this.apiS.getBrandById(v).then(res => {
      if (res) {
        this.form.patchValue({name:res.name});
        this.brand = res;
        this.loaded = true;
      }
    })
  }
  get noEdit(){
    return this.brand?.original ? false : true;
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  create(){
    
  }
}
