<div class="advanced-details-container">
    <to-do *ngIf="m && m.isIntegration"
        [txt]="'Las integraciones todavía estan en desarrollo y los precios son aleatorios'"></to-do>
    <app-go-back (click)="goSettignsModule()" [text]="'Configuración'"></app-go-back>
    <app-card [noStyle]="true" [contentLoaded]="m != undefined">
        <ng-container *ngIf="m != undefined">

            <div class="df aic">
                <img class="module-img" [src]="m.img">
                <div class="ml10_i">
                    <p class="fw600 fsm nmb">{{m.name}}</p>
                    <p class="c_t2 nmb">{{m.desc}}</p>
                    <a *ngIf="m.link" [href]="m.link" target="_blank" rel="noopener noreferrer">Ver web</a>
                </div>
                <div class="df aic mla">
                    <app-module-price-tag [module]="m"></app-module-price-tag>
                    <button [disabled]="!companyS.canTogglemodule" *ngIf="!companyS.hasModule(m.id)" mat-flat-button
                        color="primary" (click)="toggle()">Activar ahora</button>
                    <button [disabled]="!companyS.canTogglemodule" *ngIf="companyS.hasModule(m.id)" mat-flat-button
                        color="warn" (click)="toggle()">Quitar</button>
                </div>
            </div>

            <div class="mb20"></div>

            <mat-tab-group [color]="'primary'" [mat-stretch-tabs]="false" class="module-tabs border-bottom-tab" (selectedTabChange)="refreshCharts()">
                <ng-container [ngSwitch]="m.id">

                    <!-- Common TAB-->
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{generalDesc}}
                        </ng-template>
                        <div class="tab-content generic-tab">
                            <div *ngFor="let details of m.moduleDetails; let index = index"
                                [ngClass]="index%2 == 0 ? 'generic-section1' : 'generic-section2'">
                                <div class="generic-desc">{{ details.text }}</div>

                                <div class="generic-video-image">
                                    <video controls="controls" playsinline loop autoplay [muted]="false"
                                        *ngIf="details.scrType == 'video'; else imageDetails">
                                        <source [src]="details.src" type="video/mp4">
                                    </video>
                                    <ng-template #imageDetails>
                                        <img [src]="details.src">
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                    </mat-tab>


                    <!-- RECAMBIOS -->
                    <ng-container *ngSwitchCase="me.RECAMBIOS">
                    </ng-container>


                    <!-- VNVO -->
                    <ng-container *ngSwitchCase="me.VNVO">
                    </ng-container>


                    <!-- HONDA -->
                    <ng-container *ngSwitchCase="me.HONDA">
                        <mat-tab [disabled]="!has(me.HONDA)">
                            <ng-template mat-tab-label>
                                Pasos de taller
                            </ng-template>

                            <app-card-subtitle>Exportar pasos de taller</app-card-subtitle>
                            <form eForm>
                                <div eForm-wrapper eForm-section>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Desde</mat-label>
                                        <input matInput [matDatepicker]="picker_1" [max]="dataEnd.value"
                                            [formControl]="dataIni" placeholder="dd/mm/yyyy" (click)="picker_1.open()">
                                        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker_1></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline">
                                        <mat-label>Hasta</mat-label>
                                        <input matInput [matDatepicker]="picker_2" [min]="dataIni.value"
                                            [formControl]="dataEnd" placeholder="dd/mm/yyyy" (click)="picker_2.open()">
                                        <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker_2></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <p></p>

                                <div class="df jcc">
                                    <button mat-flat-button color="primary"
                                        [disabled]="!dataIni.valid || !dataEnd.valid" (click)="exportPasosTaller()">
                                        Exportar
                                    </button>
                                </div>

                            </form>

                        </mat-tab>
                        <mat-tab [disabled]="!has(me.HONDA)">
                            <ng-template mat-tab-label>
                                iCIS
                            </ng-template>
                            <app-icis></app-icis>
                        </mat-tab>
                        <mat-tab [disabled]="!has(me.HONDA)">
                            <ng-template mat-tab-label>
                                Informes
                            </ng-template>
                            <app-generic-reports [config]="{
                                showReport : {
                                    stock : true,
                                    sells : true,
                                    ors : true,
                                },
                                dataOf : 'honda'
                            }">
                            </app-generic-reports>
                        </mat-tab>

                    </ng-container>

                    <!-- TEMPLATES -->
                    <ng-container *ngSwitchCase="me.TEMPLATES">
                    </ng-container>

                    <!-- MTR -->
                    <ng-container *ngSwitchCase="me.MTR">
                        <mat-tab [disabled]="!has(me.MTR)">
                            <ng-template mat-tab-label>
                                Configuración
                            </ng-template>
                            <app-card *ngIf="m.isMTR()" class="card-fit-content">
                                <div eForm style="width: fit-content;">
                                    <app-card-title>Configuración MTR</app-card-title>
                                    <div eForm-wrapper>
                                        <div eForm-section>
                                            <mat-checkbox [(ngModel)]="m.mTRData.advances_collections">
                                                ¿Deseas traspasar datos de anticipos y cobros a contabilidad?
                                            </mat-checkbox>
                                        </div>
                                        <div eForm-section>
                                            <mat-checkbox [(ngModel)]="m.mTRData.internal_cargo">
                                                ¿Deseas traspasar documentos de cargo interno a contabilidad?
                                            </mat-checkbox>
                                        </div>
                                        <div eForm-section>
                                            <mat-checkbox [(ngModel)]="m.mTRData.cost_analytics">
                                                ¿Deseas traspasar datos de coste (analítica) a contabilidad?
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                    <p></p>
                                    <div class="df jcc">
                                        <button mat-flat-button color="primary" (click)="saveMtrConfig()">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </app-card>
                        </mat-tab>
                        <mat-tab [disabled]="!has(me.MTR)">
                            <ng-template mat-tab-label>
                                Exportación
                            </ng-template>
                            <app-mtr></app-mtr>
                        </mat-tab>
                    </ng-container>


                </ng-container>
            </mat-tab-group>
        </ng-container>
    </app-card>
</div>