import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrFormComponent } from '../or-form/or-form.component';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent {
  webkitSpeechRecognitionSupported = true;
  recognition: any;
  isRecording: boolean = false;
  @Input() canEdit = true;
  @Input({ required: true }) form_! : OrFormComponent;
  @Output() onUserSpeach = new EventEmitter<string>();
  @ViewChild("content", { static: true }) content!: ElementRef<HTMLDivElement>;

  constructor(private chdRef: ChangeDetectorRef) {
    try {
      const { webkitSpeechRecognition }: IWindow = window as any;
      this.recognition = new webkitSpeechRecognition();
      this.recognition.onresult = (event: any) => {
        let result = event.results[0][0].transcript;
        console.log("🎙️ Speech result", result)
        let notesForm = this.form_.form.get('notes');
        let finalText = notesForm?.setValue(notesForm.value + result);
        this.onUserSpeach.emit(this.form_.form.get('notes')?.value);
      };

      this.recognition.onstart = () => {
        this.isRecording = true;
        this.chdRef.detectChanges();
      };

      this.recognition.onend = () => {
        this.isRecording = false;
        this.chdRef.detectChanges();
      };
    }
    catch (e: any) {
      this.webkitSpeechRecognitionSupported = false;
      console.log("🎙️🚫 webkitSpeechRecognition not supported");
    }

  }

  record(event: Event) {
    if (this.isRecording) { this.recognition.stop(); }
    this.recognition.start();
  }

}

export interface IWindow extends Window {
  webkitSpeechRecognition: any;
}