<div class="df fdc jcsa dialog">
    <app-close-dialog-button></app-close-dialog-button>

    <mat-dialog-content class="df fdc jcsa" style="scrollbar-width: none;">

        <app-card [noStyle]="true" [contentLoaded]="loaded">

            <div class="mb20"></div>

            <!-- First section-->
            <div class="big-icons-preview">
                <div class="icon-and-contact">
                    <div>
                        <mat-icon class="mr10 c_b" *ngIf="pm.factor === moveEnum.ENTRADA"
                            [filled]="true">arrow_circle_right</mat-icon>
                        <mat-icon class="mr10" *ngIf="pm.factor === moveEnum.SALIDA"
                            [filled]="true">arrow_circle_left</mat-icon>
                        <mat-icon class="mr10" *ngIf="pm.factor === moveEnum.NO_MOVE" [filled]="true">equal</mat-icon>
                    </div>
                    <app-circular-letter [user]="user" [showFullName]="false"></app-circular-letter>
                </div>
                <div>
                    <p class="fw400 nmb">
                        <span class="fss">{{pm.transac_name}} </span>
                    </p>
                    <p class="nmb fss c_t2">{{pm.created_at?.shortFormat()}}</p>
                </div>
                <div>
                    <span class="fsl">
                        <span *ngIf="pm.factor === moveEnum.ENTRADA">+</span>
                        <span *ngIf="pm.factor === moveEnum.SALIDA">-</span>
                        <span prod-move-number [val]="pm.quanti_mov"></span>
                    </span>
                </div>
            </div>

            <div class="mb20"></div>

            <!-- Movement details -->
            <div eForm-wrapper>
                <table class="mov-details eina-table">
                    <thead>
                        <tr>
                            <th>PVP</th>
                            <th>PMP</th>
                            <th>Coste</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span *ngIf="pm.pvp != undefined" money [val]="pm.pvp"></span>
                            </td>
                            <td>
                                <span *ngIf="pm.pmp != undefined" money [val]="pm.pmp"></span>
                            </td>
                            <td>
                                <span *ngIf="pm.porte != undefined" money [val]="pm.porte"></span>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="df aic jcsb fww eina-form-wrapper no-back mw24-icons gap10">
                <div class="df vac" *ngIf="pm.location_name">
                    <mat-icon [filled]="true" class="mr10">warehouse</mat-icon>
                    <p> {{pm.location}}</p>
                </div>
                <div class="df vac" *ngIf="pm.location_name">
                    <mat-icon [filled]="true" class="mr10" smartIcon>barcode</mat-icon>
                    <p> {{pm.ref_cli}}</p>
                </div>
                <div class="df vac">
                    <mat-icon [filled]="true" class="mr10">insert_drive_file</mat-icon>
                    <p> {{pm.document}} </p>
                </div>
                <div class="df vac">
                    <mat-icon [filled]="true" class="mr10">comment</mat-icon>
                    <p [ngClass]="{'c_t2' : !pm.coment}"> {{pm.coment ? pm.coment : 'Sin comentario'}}</p>
                </div>
            </div>


        </app-card>
    </mat-dialog-content>
</div>