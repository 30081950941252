import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Vehicle } from 'src/app/models/Vehicles/M_Vehicle';
import { ParamsService } from 'src/app/services/params.service';
import { TemplateFieldsService } from '../../services/template-fields.service';
import { validatorTax } from '../../validators/tax';
import { PdfDocumentsUploadComponent } from '../pdf-documents-upload/pdf-documents-upload.component';
import { PreviewService } from '../../services/preview.service';
import { ParameterStateComponent } from '../parameter-state/parameter-state.component';
import { getAccentColor, getPrimaryColor, getWarnColor } from 'src/app/utils/FunctionUtils';
import { FileType } from 'src/app/enums/FileType';
import { RouterService } from 'src/app/services/router.service';
import { FormService } from 'src/app/services/form.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { M_Contact } from 'src/app/models/M_Contact';

@Component({
  selector: 'app-sell-vehicle-client',
  templateUrl: './sell-vehicle-client.component.html',
  styleUrls: ['./sell-vehicle-client.component.css']
})
export class SellVehicleClientComponent extends ParameterStateComponent {
  primary = getPrimaryColor;
  warn = getWarnColor;
  accent = getAccentColor;

  ve: M_Vehicle | undefined;
  v = ViewPath;
  minDate: Date;
  ft = FileType;
  price: number = 0;
  public form: UntypedFormGroup;
  selectedFile: File | null = null;
  formData = new FormData();
  @Input() vehicle: M_Vehicle | undefined;
  @Input() client: M_Contact | undefined;
  @ViewChild(PdfDocumentsUploadComponent) pdfUploadComponent!: PdfDocumentsUploadComponent; // Referencia al componente hijo
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public params: ParamsService, public fs: FormService,
    private formBuilder: UntypedFormBuilder, public confirmD: ConfirmDialogService,
    private templateService: TemplateFieldsService, public previewS: PreviewService) {
    super(routerS, route, ["vehicle"]);
    this.form = this.formBuilder.group({
      vehicle_id: [''],
      client_id: [''],
      price: ['', Validators.required],
      tax: ['', validatorTax()],
      comment: [''],
      pdf_file: [''],
      type: [2],
      docum_prov_num: [''],
      mode: [''],
      buy_transac_id: [''],
      date: [''],
      token:['CV']
    });
    this.minDate = new Date();
    if(this.vehicle != undefined){

      this.ve = this.vehicle;
    }
  }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
    }
  }
  override onParam(_param: string, value: any) {
    this.apiS.getVehicleById(value).then(resp => {
      this.ve = resp;
    })
  }
  returnClient() {
    if (this.ve && this.ve.client) {
      this.routerS.goWithQueryParams(ViewPath.vnvoDetails, { vehicle: this.ve.vehicle_id })
    }
    else {
      this.routerS.goTo(ViewPath.contacts);
    }
  }
  onFileSelected(file: File | null) {
    if (file) {
      this.selectedFile = file;
      // Aquí puedes agregar la lógica para enviar el archivo a la API
      this.uploadFile(file);
    } else {
      this.selectedFile = null;
    }
  }

  uploadFile(file: File) {
    const formData = new FormData();
    if (this.selectedFile) {
      formData.append('pdf_file', this.selectedFile);
    }
  }
  showTemplateFields() {
    const vehicle_id = this.ve?.vehicle_id;
    const client_id = this.ve?.client?.client_id;
    if (!this.form.valid) {
      this.confirmD.show({
        title: "¡No tan deprisa!",
        body: "No puedes hacer una compra venta sin añadir el precio",
        type: "danger",
        showCancel: false,
        confirmTxt: "Ok"
      })
      return;
    }
    if (this.form.valid) {
      if (vehicle_id != undefined && client_id != undefined) {
        this.form.patchValue({ client_id: client_id, vehicle_id: vehicle_id, comment: 'Contrato inicial', type: 2, mode: "view" });
      }

      this.formData.append('vehicle_id', this.form.get('vehicle_id')?.value);
      this.formData.append('client_id', this.form.get('client_id')?.value);
      this.formData.append('price', this.form.get('price')?.value);
      this.formData.append('tax', this.form.get('tax')?.value);
      this.formData.append('docum_prov_num', this.form.get('docum_prov_num')?.value);
      this.formData.append('type', this.form.get('type')?.value);
      this.formData.append('date', this.form.get('date')?.value);
      this.formData.append('mode', 'view');
      this.formData.append('token', 'CV');
      if (this.ve?.client?.type === 2 || this.ve?.client?.type === 3) {
        if (this.pdfUploadComponent.selectedFile) {
          this.formData.append('pdf_file', this.pdfUploadComponent.selectedFile);
        }
      }
      this.templateService.showTemplateSteps("CV", this.ve?.vehicle_id, "CV", this.ve?.type, undefined, this.form.value).afterClosed().subscribe(res => {
        if (res) {
          if (this.ve && this.ve.client) {
            //preview finish and save pdf after call endpoint sell vehicle.
            this.form.patchValue({ mode: "save", buy_transac_id: res });
            this.previewS.showPreview("CV", "CV", undefined, undefined, false, undefined, undefined, undefined, this.form.value);
            this.apiS.sellVehicleClient(this.formData).then(_resp => {
              this.params.go(this.v.vnvoDetails, this.ve!.vehicle_id)
            });
          }
        }
      })
    }
  }

  get haveLocation() {
    return this.ve?.client?.billing_address != undefined ? true : false;
  }
}
