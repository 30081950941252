
<app-info-panel-rectangle [panel]="'info'">
    <p class="fw600">¿No tienes suficientes dispositivos para tu equipo? </p>
    <p class="nmb">¡No te preocupes! Descubre cómo nuestro <span [go]="v.sharedAccesLogin" [newTab]="true" class="tdu fw600">acceso compartido</span> puede ayudarte a optimizar recursos 🤝🏻</p>
</app-info-panel-rectangle>
<app-page-structure #ps [pageTitle]="'Equipo'" class="users-table"
    [masterClass]="'user'" [autocompleteID]="'users'" searchLabel="Buscar usuario"
    listTitleText="Listado de usuarios"
    [data]="[]"
    [displayedHeader]=" ['Nombre',  'Email',    'Rol',      'Centro']" 
    [displayedColumns]="['name',    'email',    'role',     'center']"
    [specialText]="     [undefined, undefined,  roleName,   center]" 
    [specialClass]="    [undefined, undefined,  undefined,  undefined]"
    [circularLetter]="true"
    [showPagination]="false" 
    [filter]="f"
    [filters]="filters"
    [createButton]="
        {
            text : 'Crear usuario' , 
            icon : 'groups', 
            view : v.createEditUser,
            tooltip : circleTooltip,
            loading : !subS.status || loading,
            cssClass: 'onboarding-company-users-create'
        }"
    (onclickRow)="params.go(v.userdetails, $event.id)">
</app-page-structure>