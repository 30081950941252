<form [formGroup]="form" eForm>
    <app-card-subtitle>{{requiredFieldsTitle ? requiredFieldsTitle : 'Datos básicos'}}</app-card-subtitle>
    <div class="onboarding-vn-vo-vehicle-required">
        <div eForm-wrapper>

            <div eForm-section>
                <mat-button-toggle-group [value]="CVT.VN" formControlName="comercialType" class="alternative-color">
                    <mat-button-toggle [value]="CVT.VN">
                        Vehículo nuevo
                        <app-vn-vo-label [type]="CVT.VN"></app-vn-vo-label>
                    </mat-button-toggle>
                    <mat-button-toggle [value]="CVT.VO">
                        Vehículo de ocasión
                        <app-vn-vo-label [type]="CVT.VO"></app-vn-vo-label>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type"  [(ngModel)]="selectedType" (selectionChange)="onTypeChange($event)">
                        <mat-option [value]="1">Moto</mat-option>
                        <mat-option [value]="2">Coche</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container [ngTemplateOutlet]="plate" *ngIf="isVo"></ng-container>
                <ng-container *ngIf="isVn" [ngTemplateOutlet]="chassis"></ng-container>

            </div>

            <div eForm-section>
                <app-brands-models-series #brandsModelSerie [formVnVo]="true" [filterAuto]="true" [notRequiredSerie]="true" [form]="form">
                    <mat-form-field appearance="outline">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código versión">
                    </mat-form-field>
                </app-brands-models-series>
            </div>

            <div eForm-section>
                <mat-form-field appearance="outline" *ngIf="!isVo">
                    <mat-label>Impuesto matriculación</mat-label>
                    <mat-select formControlName="registration_tax">
                        <mat-option [value]="RTE.Low"> {{registrationText(RTE.Low)}}</mat-option>
                        <mat-option [value]="RTE.MediumLow">{{registrationText(RTE.MediumLow)}}</mat-option>
                        <mat-option [value]="RTE.MediumHigh">{{registrationText(RTE.MediumHigh)}}</mat-option>
                        <mat-option [value]="RTE.High">{{registrationText(RTE.High)}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>





    </div>

    <p></p>

    <mat-tab-group color="primary" mat-stretch-tabs="true" class="vehicle-tabs" [ngClass]="{'dn' : onlyRequired}">


        <mat-tab *ngIf="this.vehicle == undefined">
            <ng-template mat-tab-label>
                <mat-icon>shopping_bag_speed</mat-icon>
                Compra <span class="c_r" *ngIf="!buyTabIsOk">*</span>
            </ng-template>
            <app-card-subtitle>Compra</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <app-class-searcher [showLeftNumber]="false" #ownerProvider [masterClass]="client" [allData]=""
                        [width100]="true" searchPlaceHolder="Propietario o proveedor" [extendedInfo]="false"
                        (onSelect)="checkPdfRequired()" (onRemove)="checkPdfRequired()"
                        [form_]="form" [formCname]="'client_id'" [required]="true" [createData]="{
                                text : 'Nuevo contacto',
                                by : v.createContact
                            }">
                    </app-class-searcher>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" min="0" matInput formControlName="price">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Coste</mat-label>
                        <input type="number" min="0" matInput formControlName="cost">
                    </mat-form-field>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline" class="w50">
                        <mat-label>Fecha de Compra</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                            (click)="picker_3.open()" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                        <mat-datepicker #picker_3></mat-datepicker>
                    </mat-form-field>
                </div>


                <!-- On creation ... -->
                <div eForm-section *ngIf="this.vehicle == undefined">
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>IVA</mat-label>
                        <input #input type="number" matInput placeholder="IVA" formControlName="tax" type="number"
                            DecimalInput>
                        <mat-icon matSuffix>percent</mat-icon>
                        <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">
                            Valor máx. : 21
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="w50" appearance="outline"
                        *ngIf="isPdfRequired">
                        <mat-label>Número de Factura</mat-label>
                        <input type="text" min="0" matInput formControlName="doc_prov_num">
                    </mat-form-field>
                </div>

                <!-- Document to show if the buyer is company or provider -->
                <div *ngIf="isPdfRequired">
                    <app-card-subtitle>Documentación de compra <span class="c_r">*</span> </app-card-subtitle>
                    <div>
                        <app-pdf-documents-upload (fileSelected)="form.get('pdf_vn_vo')?.patchValue($event);"></app-pdf-documents-upload>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>playlist_add_check_circle</mat-icon>
                Conceptos
            </ng-template>
            <app-card-subtitle>Conceptos</app-card-subtitle>
            <app-concepts-line-table [concepts]="getConcepts()" [canModifyTable]="!isOnDialog" [isDialog]="isOnDialog"
                [showSave]="false" [isInfo]="true"></app-concepts-line-table>
        </mat-tab>

        <!-- FICHA -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>swap_driving_apps_wheel</mat-icon>
                Ficha
            </ng-template>

            <app-card-subtitle>Datos opcionales</app-card-subtitle>

            <div eForm-wrapper>
                <div eForm-section>
                    <ng-container [ngTemplateOutlet]="plate" *ngIf="isVn"></ng-container>
                    <ng-container [ngTemplateOutlet]="chassis" *ngIf="isVo"></ng-container>
                    <mat-form-field appearance="outline" *ngIf="vehicleIs('vo')">
                        <mat-label>Kilómetros</mat-label>
                        <input type="number" matInput formControlName="km">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Matriculación</mat-label>
                        <input placeholder="dd/mm/aaaa" #pickerInput matInput [matDatepicker]="picker" [max]="minDate"
                            (click)="picker.open()" formControlName="license_date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Última ITV</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_1" [max]="minDate"
                            (click)="picker_1.open()" formControlName="last_itv">
                        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                        <mat-datepicker #picker_1 [min]="minDate"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Próxima ITV</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_2" [min]="minDate"
                            (click)="picker_2.open()" formControlName="next_itv">
                        <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                        <mat-datepicker #picker_2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Fin de garantía</mat-label>
                        <input matInput formControlName="end_warranty" [matDatepicker]="pickerEndWarranty">
                        <mat-datepicker-toggle matSuffix [for]="pickerEndWarranty"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEndWarranty></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Número de garantía</mat-label>
                        <input matInput formControlName="number_warranty" placeholder="Número de garantía">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-label>Observaciones</mat-label>
                        <textarea matInput formControlName="observations" cdkTextareaAutosize cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5" [placeholder]="'Observaciones'">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>

        </mat-tab>


        <!-- MOTOR -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>construction</mat-icon>
                Motor
            </ng-template>
            <app-card-subtitle>Motor</app-card-subtitle>
            <div eForm-wrapper>


                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de motor</mat-label>
                        <mat-select formControlName="engine_type" #et>
                            <mat-option [value]="0">Gasolina</mat-option>
                            <mat-option [value]="1">Eléctrico</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Nº serie batería </mat-label>
                        <input matInput placeholder="Nº serie batería" formControlName="battery_1_no">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Nº serie batería extra</mat-label>
                        <input matInput placeholder="Nº serie batería extra" formControlName="battery_2_no">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <div eForm-section>
                        <mat-form-field appearance="outline">
                            <mat-label>Cilindrada</mat-label>
                            <input type="number" matInput placeholder="Cilindrada" formControlName="cc">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de cambio</mat-label>
                        <input matInput formControlName="gears_type" placeholder="Tipo de cambio">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>
</form>



<ng-template #chassis>
    <app-chassis-input [form]="form" [currentVehicle]="vehicle" [required]="false"></app-chassis-input>
</ng-template>

<ng-template #plate>
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>Matricula</mat-label>
            <app-async-validator-spinner matSuffix [control]="form.get('license')"></app-async-validator-spinner>
            <input matInput placeholder="Matricula" [disabled]="licenseBlocked" formControlName="license" />
            <button *ngIf="licenseBlocked" mat-icon-button matPrefix type="button" (click)="enablePlateEdition()">
                <mat-icon>edit</mat-icon>
            </button>
            <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                La matrícula ya existe.
            </mat-error>
        </mat-form-field>
    </form>
</ng-template>



<!-- <div eForm-section *ngIf="isVo">
        <app-class-searcher [showLeftNumber]="false" #sellvo [masterClass]="client" [allData]=""
            [width100]="true" searchPlaceHolder="Propietario VO" [extendedInfo]="false" [form_]="form"
            [formCname]="'client_origin'" [required]="true" [createData]="{
                                text : 'Crear cliente',
                                by : 'dialog'
                            }">
        </app-class-searcher>
    </div> -->

<!-- <div eForm-section eForm-wrapper *ngIf="isVn">
        <mat-form-field appearance="fill">
            <mat-label>En depósito</mat-label>
            <mat-select formControlName="in_deposit">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Gastos financieros</mat-label>
            <input matInput type="number" formControlName="financial_expenses" placeholder="Gastos financieros">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Albarán del proveedor</mat-label>
            <input matInput formControlName="delivery_num" placeholder="Número del albarán">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Vencimiento de documentación</mat-label>
            <input matInput formControlName="documentation_expiration" [matDatepicker]="pickerDocExp"
                (click)="pickerDocExp.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerDocExp"></mat-datepicker-toggle>
            <mat-datepicker #pickerDocExp></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Grupo contable</mat-label>
            <input matInput formControlName="accounting" placeholder="Grupo contable">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Año</mat-label>
            <input matInput type="number" formControlName="year" placeholder="Año del vehículo">
        </mat-form-field>

    </div> -->


<!-- 
    <div eForm-section>
        <mat-form-field appearance="outline">
            <mat-label>Nº motor</mat-label>
            <input matInput placeholder="Nº motor" formControlName="num_motor">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Modelo taller</mat-label>
            <input matInput placeholder="Nº serie batería extra" formControlName="workshop_model">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Deposito</mat-label>
            <input matInput placeholder="Deposito" formControlName="deposit">
        </mat-form-field>

    </div> -->