<app-card [noStyle]="true">
    <mat-stepper linear #stepper class="hidden-stepper-header">
        <!-- FIST STEP -->
        <mat-step [stepControl]="companyName">
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¡Bienvenido a Eina!</p>
                    <div mat-dialog-content>
                        <p>Con <span class="fw500">Eina</span> puedes gestionar tu taller de forma sencilla. </p>
                        <p>Empecemos por introducir el nombre de tu empresa:</p>
                        <form>
                            <ng-template matStepLabel>Fill out your name</ng-template>
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de tu empresa</mat-label>
                                <input (keyup.enter)="stepper.next()" matInput placeholder="Nombre de la empresa"
                                    [formControl]="companyName">
                            </mat-form-field>
                        </form>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/qiflnafy.json" trigger="loop" delay="2000"
                        colors="primary:{{primary()}},secondary:#ebe6ef,tertiary:{{accent()}},quaternary:{{primary()}},quinary:{{primary()}}"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">¡Bienvenido a Eina!</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" [disabled]="!companyName.value"
                    matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- SECOND STEP -->
        <mat-step [stepControl]="places">
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¿Quantas plazas tiene tu taller?</p>
                    <div mat-dialog-content>
                        <p>
                            Las plazas de taller son la capacidad máxima de vehículos que tu taller puede atender
                            simultáneamente.
                            <mat-icon color="blue" class="vam"
                                [matTooltip]="'Estas plazas determinan la ocupación actual de tu taller, y en la sección de carga de taller, podrás visualizar de manera gráfica cómo se distribuye esa ocupación a lo largo del día. Introduce el número de plazas disponibles para gestionar eficientemente la capacidad de tu taller y optimizar la atención a tus clientes.'">
                                info
                            </mat-icon>
                        </p>
                        <div class="df jcc">
                            <p>{{ms.min}}</p>
                            <mat-slider #ms min="1" max="50" step="1" thumbLabel showTickMarks discrete>
                                <input [formControl]="places" matSliderThumb />
                            </mat-slider>
                            <p>{{ms.max}}</p>
                        </div>
                    </div>
                </div>
                <div class="side-icon">
                    <lord-icon src="https://cdn.lordicon.com/eqfustsc.json" trigger="loop" delay="2000"
                        colors="primary:#121331,secondary:#ebe6ef,tertiary:#3a3347,quaternary:{{primary()}},quinary:{{accent()}}"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">¿Quantas plazas tiene tu taller?</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>


        <!-- THIRD STEP -->
        <mat-step [stepControl]="defaultVehicle">
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¿Qué reparas habitualmente?</p>

                    <div mat-dialog-content>
                        <div class="df" style="justify-content: space-evenly; gap: 10px;">
                            <div class="lord-selector" [ngClass]="{'selected' : defaultVehicle.value == vt.bike}"
                                (click)="defaultVehicle.setValue(vt.bike)">
                                <lord-icon src="https://cdn.lordicon.com/nrmtiuty.json" trigger="loop" delay="9999"
                                    colors="primary:#121331,secondary:#3a3347,tertiary:{{accent()}},quaternary:#ebe6ef"
                                    class="lord-big"></lord-icon>
                            </div>
                            <div class="lord-selector" [ngClass]="{'selected' : defaultVehicle.value == vt.car}"
                                (click)="defaultVehicle.setValue(vt.car)">
                                <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                <lord-icon src="https://cdn.lordicon.com/olaxuwzv.json" trigger="loop" delay="9999"
                                    colors="primary:#121331,secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#3a3347,quinary:#ffffff"
                                    class="lord-big">
                                </lord-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <p mat-dialog-title class="phone">¿Qué reparas habitualmente?</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext
                    [disabled]="defaultVehicle.value == null">Siguiente</button>
            </div>
        </mat-step>

        <!-- FOURTH STEP -->
        <mat-step [stepControl]="defaultVehicle">
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¿Qué módulos quieres activar?</p>
                    <div mat-dialog-content>
                        <div *ngFor="let module of modules">
                            <app-module [welcomeDialog]="true" [module]="module"></app-module>
                        </div>
                    </div>
                </div>
                <p mat-dialog-title class="phone">¿Qué módulos quieres activar?</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext
                    [disabled]="defaultVehicle.value == null">Siguiente</button>
            </div>
        </mat-step>


        <!-- FIFTH STEP -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>Periodo de prueba gratuito</p>
                    <div mat-dialog-content>
                        <p mat-dialog-subtitle>¡Hoy empieza tu período de prueba de 14 días!</p>
                        <p>Termina el día {{calculateDay()}}</p>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/ciqarrsc.json" trigger="loop"
                        colors="primary:{{primary()}},secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#1663c7"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">Periodo de prueba gratuito</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="primary" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- INVOICE REQUIREMENTS -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¡Empecemos!</p>
                    <div mat-dialog-content>
                        <p>El primer paso es rellenar la información de empresa.</p>
                        <p>Un paso fundamental para la <span class="fw600">emisión de facturas</span>.</p>
                        <p>Haz clic en 'Empezar', y te guiaremos en el proceso.</p>
                        <button color="primary" (click)="openHelp(); updateCompanyData()" mat-flat-button
                            mat-dialog-close>Empezar</button>
                    </div>
                </div>
                <div class="side-icon">
                    <lord-icon src="https://cdn.lordicon.com/yphfrmut.json" trigger="in" trigger="loop" delay="2000"
                        colors="primary:#121331,secondary:{{primary()}},tertiary:#ebe6ef,quaternary:#646e78,quinary:{{accent()}},senary:#3a3347"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">¡Empecemos!</p>
            </div>
        </mat-step>

        <!-- LAST STEP
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¡Todo listo!</p>
                    <div mat-dialog-content>
                        <p>Si en algún momento necesitas ayuda, busca el icono
                            <mat-icon style="vertical-align: bottom;">help_outlined</mat-icon>
                            en la barra de tareas superior.
                        </p>
                        <p>Te sera útil para realizar los primeros pasos.</p>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/ncouatyu.json" trigger="loop" delay="2000"
                        colors="primary:#4bb3fd,secondary:#fae6d1" class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">¡Todo listo!</p>
            </div>
            <div class="df jcfe">
                <button (click)="openHelp(); updateCompanyData()" mat-button mat-dialog-close>Empezar</button>
            </div>
        </mat-step>-->

    </mat-stepper>

    <app-stepper-step-indicator [stepper]="stepper"></app-stepper-step-indicator>

</app-card>