import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { defaultSchedule } from 'src/app/constants/constants';
import { M_Schedule } from 'src/app/models/M_Schdeule';
import { ApiService } from 'src/app/services/Api/api.service';
import { SnackService } from 'src/app/services/snack.service';

export type dialogSchedule = { showCheckbox: boolean, prespective: "user" | "center", userId?: number };

@Component({
  selector: 'app-week-schedule',
  templateUrl: './week-schedule.component.html',
  styleUrls: ['./week-schedule.component.css']
})
export class WeekScheduleComponent implements OnInit {

  @Input({ required: true }) prespective!: "user" | "center";
  @Input({ required: true }) userId!: number | undefined;
  @Input() showCheckbox: boolean = false;
  @Input() showSave: boolean = true;
  @Input() fullWidthTable: boolean = false;

  horario: M_Schedule[] = defaultSchedule();
  accountForWorkload: boolean = true;

  daysOfWeek: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  fc: FormControl = new FormControl(false);

  loading = false;

  constructor(@Optional() public dialogRef: MatDialogRef<WeekScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: dialogSchedule,
    private apiS: ApiService, private snackS: SnackService) {
    if (this.isOnDialog) {
      this.showCheckbox = data.showCheckbox;
      this.prespective = data.prespective;
      this.userId = data.userId;
    }
  }

  ngOnInit(): void {
    if (this.prespective == "user" && this.userId) {
      this.loading = true;
      this.apiS.signing.getUserSchedule(this.userId).then(res => {
        if (res.schedule.length) {
          this.horario = res.schedule;
          this.fc.setValue(res.accountForWorkload);
        }
        if (res.accountForWorkload != null) {
          this.fc.setValue(res.accountForWorkload);
        }
        this.loading = false;
      })
    }
  }

  getTotalTimeForWeek(): string {
    const totalMinutes = this.horario.reduce((total, schedule) => total + schedule.getTotalTimeInMinutes(), 0);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours < 0 || minutes < 0) { return "" }
    return `${hours}h ${minutes}m`;
  }

  setSchedule(schedule: M_Schedule[]) {
    if (schedule.length != this.daysOfWeek.length) {
      console.warn("Impossile to set schedule")
    }
    else {
      this.horario = schedule;
    }
  }

  get isDisabled() {
    return this.horario.some(schdule => {
      return !schdule.isOk;
    });
  }

  get isOk() {
    return this.horario.every(schdule => {
      return schdule.isOk;
    });
  }

  save() {
    const schedule = this.getScheduleValue();
    this.apiS.signing.updateSchedule(schedule).then(res => {
      if (this.isOnDialog) {
        this.dialogRef.close(this.horario);
        this.snackS.show("¡Horario actualizado! 🕘");
      }
    })

  }

  getSchedule(): M_Schedule[] {
    return this.horario;
  }

  dayName(i: number): string {
    return this.daysOfWeek[i];
  }

  getScheduleValue(): { accountForWorkload: boolean; schedule: M_Schedule[], user_id?: number } | M_Schedule[] {

    if (this.showCheckbox) {
      return {
        accountForWorkload: this.fc.value,
        schedule: this.horario,
        user_id: this.data.userId
      }
    }
    return this.horario;
  }

  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
}

