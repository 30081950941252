
<mat-form-field appearance="outline">
    <mat-label>Buscar</mat-label>
    <input type="text" #searchInput matInput placeholder="Buscar" (input)="applySearchFilter(searchInput.value)">
    <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
</mat-form-field>
<app-filters #eventFilter [filters]="action_filters" (onfiltersDone)="refreshAction($event)"></app-filters>


<table mat-table [dataSource]="dataMostrada" *ngIf="dataMostrada.length; else noData">
    <ng-container matColumnDef="Ornumber">
        <th mat-header-cell *matHeaderCellDef> {{responsiveS.w > 514 ? 'Tipo ' : 'Action'}} </th>
        <td mat-cell *matCellDef="let element" class="fa fa-angle-double-right">
            <div class="df aic">
                <app-or-status-label *ngIf="responsiveS.w <= 514" class="df jcc" [circularStatus]="true"
                    [status]="element.status">
                </app-or-status-label>
                <mat-icon class="mr5 scaled-icon">
                    {{getIconName(element)}}
                </mat-icon>
                <p class="nmb fsm fw400">
                    <span class="c_t2" *ngIf="!isProductInvoice(element)">{{isAddOrRemove(element)}}</span>
                    <span class="c_t2" *ngIf="isProductInvoice(element)">{{isAddOrRemove(element)}}</span>
                </p>
            </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="status" *ngIf="responsiveS.w > 514">
        <th mat-header-cell *matHeaderCellDef> Unidades </th>
        <td mat-cell *matCellDef="let element">
            <span class="prd_entry">
                <p [ngClass]="getClassForElement(element)">
                    <span>{{ getSymbolForElement(element) }}</span>
                    <span>{{ element.quantity }}</span>
                </p>
            </span>
        </td>
    </ng-container>


    <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef>Fecha</th>
        <td mat-cell *matCellDef="let element"> {{element.created_at.shortFormat()}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="start-align">Documento</th>
        <td mat-cell *matCellDef="let element" class="start-align">
            <span class="text_invoices"> {{element.invoice_id_company ? element?.invoice_id_company : (element.type ==
                'manual' ? 'Sin Datos' : 'Archivo')}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goItem(row)"></tr>
</table>
<div class="btn_center tac p10">
    <button *ngIf="this.data.length > 5" mat-stroked-button
        (click)="mostrarMasFilas()">{{this.textoBoton}}<mat-icon>expand_more</mat-icon></button>
</div>

<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>

<ng-template #noData>
    <p class="c_t2" style="padding: 0px 10px 0px 10px">No hay existencias</p>
</ng-template>

<div style="height: 10px;"></div>