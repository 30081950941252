import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { icisPostData, GenericReportsComponent } from '../generic-reports.component';
import { FormControl, Validators } from '@angular/forms';
import { MASTER_USER } from 'src/app/constants/masters';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { M_User } from 'src/app/models/M_User';

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.css']
})
export class ReportFiltersComponent implements OnInit {

  USER = MASTER_USER;

  @ViewChild(ClassSearcherComponent) cs?: ClassSearcherComponent<M_User>;
  @Output() onFilters: EventEmitter<[icisPostData, icisPostData | undefined]> = new EventEmitter();
  @Input({ required: true }) comparable!: boolean;
  @Input({ required: true }) hasTimeRange!: boolean;
  @Input({ required: true }) showUser!: boolean;
  @Input({ required: true }) defaultDay!: Date;

  today = new Date();


  years = this.generateYears(2000, this.today.getFullYear())
  months = [
    { num: 1, month: 'Enero' },
    { num: 2, month: 'Febrero' },
    { num: 3, month: 'Marzo' },
    { num: 4, month: 'Abril' },
    { num: 5, month: 'Mayo' },
    { num: 6, month: 'Junio' },
    { num: 7, month: 'Julio' },
    { num: 8, month: 'Agosto' },
    { num: 9, month: 'Septiembre' },
    { num: 10, month: 'Octubre' },
    { num: 11, month: 'Noviembre' },
    { num: 12, month: 'Diciembre' }
  ];


  year1: FormControl<number | null> = new FormControl(null);
  month1: FormControl<number | null> = new FormControl(null);

  year2: FormControl<number | null> = new FormControl(null);
  month2: FormControl<number | null> = new FormControl(null);

  constructor(@Inject(GenericReportsComponent) private reportsParent: GenericReportsComponent) {

  }

  ngOnInit(): void {

    if (this.hasTimeRange) {
      this.year1.setValue(this.defaultDay.getFullYear())
      this.month1.setValue(this.defaultDay.getMonth() + 1)

      this.year1.addValidators(Validators.required);
      this.month1.addValidators(Validators.required);
    }
  }


  emitFilters() {

    let f1: icisPostData = {
      center_id: this.reportsParent.center.value || undefined,
    }

    let f2: icisPostData | undefined = undefined;

    if (this.hasTimeRange) {
      f1.range = { year: this.year1.value || undefined, month: this.month1.value || undefined }
      if (this.comparable && this.year2.value != null && this.month2.value != null) {
        f2 = {
          center_id: this.reportsParent.center.value || undefined,
        };
        f2.range = {
          year: this.year2.value == null ? undefined : this.year2.value,
          month: this.month2.value == null ? undefined : this.month2.value
        }
      }
    }
    if (this.showUser) {
      f1.user_id = this.cs?.selected?.id;
      if (f2) { f2.user_id = this.cs?.selected?.id; }
    }


    this.onFilters.emit([f1, f2])
  }

  generateYears(startYear: number, endYear: number): number[] {
    const years = [];
    for (let year = endYear; year >= startYear; year--) { years.push(year); }
    return years;
  }

  get isDisabled() {
    let isOk = this.reportsParent.center.valid && this.year1.valid && this.month1.valid &&
      ((this.year2.value != null && this.month2.value != null) || (this.year2.value == null && this.month2.value == null));
    return !isOk;
  }

}
