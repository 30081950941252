import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateClientComponent } from 'src/app/views/create-client/create-client.component';
import { M_Contact } from '../models/M_Contact';
import { ClassSearcherComponent } from '../components/class-searcher/class-searcher.component';
import { ContactEnum } from '../enums/ContactEnum';
import { M_Vehicle } from '../models/Vehicles/M_Vehicle';
import { CreateVehicleComponent } from '../views/create-vehicle/create-vehicle.component';
import { M_Concept } from '../models/M_Concept';
import { CreateConceptComponent } from '../components/create-concept/create-concept.component';
import { CreateProductComponent } from '../views/create-product/create-product.component';
import { M_Product } from '../models/Products/M_Product';
import { M_Brand } from '../models/M_Brand';
import { CreateEditBrandComponent } from '../create-edit-brand/create-edit-brand.component';
import { CreateModelsComponent } from '../components/create-models/create-models.component';
import { CreateSerieComponent } from '../core/components/create-serie/create-serie.component';
import { M_Serie } from '../models/M_Serie';
import { M_Model } from '../models/M_Model';

@Injectable({
  providedIn: 'root'
})
export class CreateMasterInDialogService {

  constructor(private d: MatDialog) { }

  createClient(searcher: ClassSearcherComponent<M_Contact> | undefined, forceType?: ContactEnum) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateClientComponent, { data: forceType, maxWidth: 800, autoFocus: false })
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Contact) {
        searcher?.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createVehicle(searcher: ClassSearcherComponent<M_Vehicle> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateVehicleComponent, { maxWidth: 800, autoFocus: false })
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Vehicle) {
        searcher.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createConcept(searcher: ClassSearcherComponent<M_Concept> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateConceptComponent)
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Concept) {
        searcher.addAndSelectNewOption(res);
      }
    })
    return dialogRef;
  }

  createProduct(searcher: ClassSearcherComponent<M_Product> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateProductComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res instanceof M_Product) {
        searcher.addAndSelectNewOption(res);
      }
    });
    return dialogRef;
  }
  openCreateBrandDialog(searcher: ClassSearcherComponent<M_Brand> | undefined){
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateEditBrandComponent);
    dialogRef.afterClosed().subscribe(res=>{
      if (res instanceof M_Brand) {
        searcher.addAndSelectNewOption(res);
      }
    });
    return dialogRef;
  }
  openCreateSerieDialog(searcher: ClassSearcherComponent<M_Serie> | undefined){
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateSerieComponent);
    dialogRef.afterClosed().subscribe(res=>{
      if (res instanceof M_Serie) {
        searcher.addAndSelectNewOption(res);
      }
    });
    return dialogRef;
  }
  openCreateModel(searcher: ClassSearcherComponent<M_Model> | undefined) {
    if (!searcher) { return; }
    let dialogRef = this.d.open(CreateModelsComponent);
    dialogRef.afterClosed().subscribe(res=>{
      if (res instanceof M_Model) {
        searcher.addAndSelectNewOption(res);
      }
    });
    return dialogRef;
  }
}
