<div class="linkParent">

    <app-go-back [show]="ve != undefined" [text]="'Volver atrás'" (click)="returnClient()"></app-go-back>

    <app-view-title>Compra venta de vehículo</app-view-title>
    
    <div class="mt20">
        <app-card [contentLoaded]="ve != undefined" class="bc_b1"
            [contentLoaded]="ve != undefined || vehicle != undefined">

            <ng-container *ngIf="ve">
                <app-advanced-details [showButton]="false">
                    <app-advanced-title>
                        {{ve.getName()}}
                    </app-advanced-title>
                    <app-advanced-subtitle>
                        {{ve.license}}
                    </app-advanced-subtitle>
                </app-advanced-details>

                <app-advanced-details-footer [showTitle]="false">
                    <app-footer-right>
                        <app-contact-client-rectangle *ngIf="ve.client"
                            [client]="ve.client"></app-contact-client-rectangle>
                    </app-footer-right>
                </app-advanced-details-footer>
            </ng-container>
        </app-card>

        <div class="df jcc mb20 mt20"></div>

        <app-card [contentLoaded]="ve != undefined || vehicle != undefined">
            <app-card-title>Datos de compra</app-card-title>
            <form eForm style="max-width: unset;" [formGroup]="form">
                <div eForm-section eForm-wrapper>
                    <mat-form-field appearance="outline">
                        <mat-label>Precio</mat-label>
                        <input type="number" min="0" matInput formControlName="price">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Fecha de Compra</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_3" [min]="minDate"
                            (click)="picker_3.open()" formControlName="date">
                        <mat-datepicker-toggle matSuffix [for]="picker_3"></mat-datepicker-toggle>
                        <mat-datepicker #picker_3></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>IVA</mat-label>
                        <input #input type="number" matInput placeholder="IVA." formControlName="tax" type="number"
                            DecimalInput>
                        <mat-icon matSuffix>percent</mat-icon>
                        <mat-error *ngIf="form.get('tax')?.touched && form.get('tax')?.errors">Valor máx.
                            :
                            21</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="ve?.client?.type == 2 || ve?.client?.type == 3" class="pdf-div">
                    <!-- <lib-file-upload [phoneMultipleUpload]="true" #documentation class="hidde_add"
                        [uploadtype]="'drag'" [maxImagesLength]="5" [maxSize]="6" [acceptedTypes]="[ft.document]"
                        (stateChanged)="onFileUpload($event)">
                    </lib-file-upload> -->
                    <app-card-title>Adjuntar Documentación de Compra</app-card-title>
                    <mat-form-field class="w50" appearance="outline">
                        <mat-label>Número de Factura</mat-label>
                        <input type="text" min="0" matInput formControlName="docum_prov_num">
                    </mat-form-field>
                    <app-pdf-documents-upload></app-pdf-documents-upload>
                </div>
            </form>

            <div class=" df jcc aic" [matTooltip]="haveLocation ? '' : 'El cliente no tiene ubicación'"
                matTooltipPosition="above">
                <button [disabled]="!haveLocation || !form.valid" mat-flat-button color="primary" style="margin-top: 10px"
                    (click)="showTemplateFields()">
                    <span>Comprar vehículo</span>
                </button>
            </div>

        </app-card>
    </div>

</div>