import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/Api/api.service';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ChartComponent
} from "ng-apexcharts";
import { M_Icis } from 'src/app/models/M_Icis';
import { forkJoin } from 'rxjs';
import { M_Center } from 'src/app/models/M_Center';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  colors: string[];
  fill: ApexFill;
  legend: ApexLegend;
};

export type icisPostData = {
  company_id?: number | undefined,
  center_id?: number | undefined,
  user_id?: number | undefined,
  range?: { year?: number, month?: number } | { start: Date, end: Date }
}

type reportChartType = "stock" | "sells" | "ors"

@Component({
  selector: 'app-generic-reports',
  templateUrl: './generic-reports.component.html',
  styleUrls: ['./generic-reports.component.css']
})
export class GenericReportsComponent implements OnInit {
  loaded = false;

  /** https://apexcharts.com/angular-chart-demos/column-charts/grouped-stacked-columns/ */
  public stockChart: Partial<ChartOptions> | undefined;
  public sellsChart: Partial<ChartOptions> | undefined;
  public orsChart: Partial<ChartOptions> | undefined;

  icisStock: M_Icis | undefined;
  icisSells: [M_Icis, M_Icis | undefined] | undefined;
  icisOrs: [M_Icis, M_Icis | undefined] | undefined;

  center: FormControl<number | null> = new FormControl();
  defaultCenter: M_Center | undefined;
  defaultDay = new Date();

  @Input({ required: true }) config!: {
    showReport: {
      stock: boolean,
      sells: boolean,
      ors: boolean,
    }
    dataOf: "einaCompany" | "honda"
  }

  @ViewChildren(ChartComponent) charts?: QueryList<ChartComponent>;


  constructor(public companyS: CompanyService, private apiS: ApiService) { }

  ngOnInit(): void {
    this.center.setValue(this.companyS.company.centers[0].id);
    this.apiS.icis({ center_id: this.defaultCenter?.id, range: { year: this.defaultDay.getFullYear(), month: this.defaultDay.getMonth() + 1 } }).then(res => {
      this.icisStock = new M_Icis(res);
      this.icisSells = [new M_Icis(res), undefined];
      this.icisOrs = [new M_Icis(res), undefined];
      this.generateChartOrCharts("stock", "sells", "ors")
      this.loaded = true;
    })
    this.center.addValidators(Validators.required);
  }

  private generateChartOrCharts(...chart: reportChartType[]) {
    chart.forEach(c => {
      if (c == "stock" && this.icisStock) { this.generateStockChart(this.icisStock) }
      if (c == "sells" && this.icisSells) { this.generateSellsChart(this.icisSells); }
      if (c == "ors" && this.icisOrs) { this.generateOrsChart(this.icisOrs); }
    })
  }

  reRender() {
    console.log("Re rendering charts")
    this.charts?.forEach(c => {
      c.resetSeries();
    })
  }

  updateIcis(postData: [icisPostData, icisPostData | undefined], setTo: M_Icis | [M_Icis, M_Icis | undefined] | undefined, chartType: reportChartType) {
    if (!postData[1]) {
      this.apiS.icis(postData[0], true).then(res => {
        if (Array.isArray(setTo)) {
          setTo[0] = res;
          setTo[1] = undefined;
        }
        else { setTo = res; }
        this.generateChartOrCharts(chartType);
      })
    }
    else {
      const icis1 = this.apiS.icis(postData[0], true);
      const icis2 = this.apiS.icis(postData[1], true);
      forkJoin([icis1, icis2]).subscribe(res => {
        if (Array.isArray(setTo)) {
          setTo[0] = res[0];
          setTo[1] = res[1];
        }
        else { setTo = res; }
        this.generateChartOrCharts(chartType);
      });
    }
  }

  generateStockChart(icis: M_Icis) {
    this.stockChart = {
      series: [
        {
          name: "VN Honda",
          data: [
            icis.stock_nuevas_0_30_dias?.value || 0,
            icis.stock_nuevas_31_60_dias?.value || 0,
            icis.stock_nuevas_61_90_dias?.value || 0,
            icis.stock_nuevas_91_180_dias?.value || 0,
          ]
        },
        {
          name: "VO Honda",
          data: [
            icis.stock_usadas_honda_0_30_dias?.value || 0,
            icis.stock_usadas_honda_31_60_dias?.value || 0,
            icis.stock_usadas_honda_61_90_dias?.value || 0,
            icis.stock_usadas_honda_91_180_dias?.value || 0,
          ]
        },
        {
          name: "VO Otras",
          data: [
            icis.stock_usadas_otras_marcas_0_30_dias?.value || 0,
            icis.stock_usadas_otras_marcas_31_60_dias?.value || 0,
            icis.stock_usadas_otras_marcas_61_90_dias?.value || 0,
            icis.stock_usadas_otras_marcas_91_180_dias?.value || 0,
          ]
        }
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      dataLabels: {
        formatter: (val: any) => {
          return Number(val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: [
          "0 - 30 días",
          "31 - 60 días",
          "61 - 90 días",
          "91 - 180 días",
        ]
      },
      fill: {
        opacity: 1
      },
      colors: ["#5fa7f8", "#8dce50", "#fcad73"],
      yaxis: {
        labels: {
          formatter: (val: any) => {
            return val;
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

  generateSellsChart(icisData: [M_Icis, M_Icis | undefined]) {

    let first = icisData[0];
    let second = icisData[1];

    this.sellsChart = {
      series: [
        {
          name: "Directas".concat(second ? " (1)" : ""),
          group: "range1",
          data: [
            first.ventas_directas_super_sport_unidades?.value || 0,
            first.ventas_directas_sport_touring_unidades?.value || 0,
            first.ventas_directas_touring_unidades?.value || 0,
            first.ventas_directas_naked_unidades?.value || 0,
            first.ventas_directas_custom_unidades?.value || 0,
            first.ventas_directas_on_off_unidades?.value || 0,
            first.ventas_directas_125cc_on_unidades?.value || 0,
            first.ventas_directas_125_on_off_unidades?.value || 0,
            first.ventas_directas_big_scooter_unidades?.value || 0,
            first.ventas_directas_scooter_125cc_unidades?.value || 0,
            first.ventas_directas_off_road_unidades?.value || 0,
            first.ventas_directas_otros_unidades?.value || 0,
          ]
        },
        ...(second ?
          [
            {
              name: "Directas (2)",
              group: "range2",
              data: [
                second.ventas_directas_super_sport_unidades?.value || 0,
                second.ventas_directas_sport_touring_unidades?.value || 0,
                second.ventas_directas_touring_unidades?.value || 0,
                second.ventas_directas_naked_unidades?.value || 0,
                second.ventas_directas_custom_unidades?.value || 0,
                second.ventas_directas_on_off_unidades?.value || 0,
                second.ventas_directas_125cc_on_unidades?.value || 0,
                second.ventas_directas_125_on_off_unidades?.value || 0,
                second.ventas_directas_big_scooter_unidades?.value || 0,
                second.ventas_directas_scooter_125cc_unidades?.value || 0,
                second.ventas_directas_off_road_unidades?.value || 0,
                second.ventas_directas_otros_unidades?.value || 0,
              ],
            },
          ]
          : []),
        {
          name: "Agentes".concat(second ? " (1)" : ""),
          group: "range1",
          data: [
            first.ventas_subagentes_super_sport_unidades?.value || 0,
            first.ventas_subagentes_sport_touring_unidades?.value || 0,
            first.ventas_subagentes_touring_unidades?.value || 0,
            first.ventas_subagentes_naked_unidades?.value || 0,
            first.ventas_subagentes_custom_unidades?.value || 0,
            first.ventas_subagentes_on_off_unidades?.value || 0,
            first.ventas_subagentes_125cc_on_unidades?.value || 0,
            first.ventas_subagentes_125_on_off_unidades?.value || 0,
            first.ventas_subagentes_big_scooter_unidades?.value || 0,
            first.ventas_subagentes_scooter_125cc_unidades?.value || 0,
            first.ventas_subagentes_off_road_unidades?.value || 0,
            first.ventas_subagentes_otros_unidades?.value || 0,
          ]
        },
        ...(second ?
          [
            {
              name: "Agentes (2)",
              group: "range2",
              data: [
                second.ventas_subagentes_super_sport_unidades?.value || 0,
                second.ventas_subagentes_sport_touring_unidades?.value || 0,
                second.ventas_subagentes_touring_unidades?.value || 0,
                second.ventas_subagentes_naked_unidades?.value || 0,
                second.ventas_subagentes_custom_unidades?.value || 0,
                second.ventas_subagentes_on_off_unidades?.value || 0,
                second.ventas_subagentes_125cc_on_unidades?.value || 0,
                second.ventas_subagentes_125_on_off_unidades?.value || 0,
                second.ventas_subagentes_big_scooter_unidades?.value || 0,
                second.ventas_subagentes_scooter_125cc_unidades?.value || 0,
                second.ventas_subagentes_off_road_unidades?.value || 0,
                second.ventas_subagentes_otros_unidades?.value || 0,
              ],
            },
          ]
          : []),
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      dataLabels: {
        formatter: (val: any) => {
          return Number(val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: [
          "Super sport",
          "Sport touring",
          "Touring",
          "Naked",
          "Cusstom",
          "On off",
          "125cc",
          "125cc on off",
          "Big scooter 125cc",
          "Off road",
          "Otras"
        ]
      },
      fill: {
        opacity: 1
      },
      colors: second ? this.comparativeColors : this.defaultColors,
      yaxis: {
        labels: {
          formatter: (val: any) => {
            return val;
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

  generateOrsChart(icisData: [M_Icis, M_Icis | undefined]) {

    let first = icisData[0];
    let second = icisData[1];

    this.orsChart = {
      series: this.ORsSeries(icisData),
      chart: {
        type: "bar",
        height: 350,
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      dataLabels: {
        formatter: (val: any) => {
          return Number(val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        categories: [
          "Normal",
          "Interno",
          "Garantia",
          "Siniestro",
        ]
      },
      fill: {
        opacity: 1
      },
      colors: second ? this.comparativeColors : this.defaultColors,
      yaxis: {
        labels: {
          formatter: (val: any) => {
            return val;
          }
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

  ORsSeries(icisData: [M_Icis, M_Icis | undefined]) {
    let first = icisData[0];
    let second = icisData[1];

    /** If is honda module */
    if (this.isHonda) {
      return [
        {
          name: "Honda".concat(second ? " (1)" : ""),
          data: [
            first.mano_de_obra_honda_clientes_num_or?.value || 0,
            first.mano_de_obra_honda_internas_num_or?.value || 0,
            first.mano_de_obra_honda_garantias_num_or?.value || 0,
            first.mano_de_obra_honda_siniestro_num_or?.value || 0
          ]
        },
        ...(second
          ? [
            {
              name: "Honda (2)",
              data: [
                second.mano_de_obra_honda_clientes_num_or?.value || 0,
                second.mano_de_obra_honda_internas_num_or?.value || 0,
                second.mano_de_obra_honda_garantias_num_or?.value || 0,
                second.mano_de_obra_honda_siniestro_num_or?.value || 0,
              ],
            },
          ]
          : []),
        {
          name: "Otras".concat(second ? " (1)" : ""),
          data: [
            first.mano_de_obra_otras_marcas_clientes_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_internas_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_garantias_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_siniestro_num_or?.value || 0
          ]
        },
        ...(second
          ? [
            {
              name: "Otras (2)",
              data: [
                second.mano_de_obra_otras_marcas_clientes_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_internas_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_garantias_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_siniestro_num_or?.value || 0,
              ],
            },
          ]
          : [])
      ]
    }

    /** Not Honda */
    else {
      return [
        {
          name: "Total".concat(second ? " (1)" : ""),
          data: [
            first.mano_de_obra_otras_marcas_clientes_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_internas_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_garantias_num_or?.value || 0,
            first.mano_de_obra_otras_marcas_siniestro_num_or?.value || 0
          ]
        },
        ...(second
          ? [
            {
              name: "Total (2)",
              data: [
                second.mano_de_obra_otras_marcas_clientes_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_internas_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_garantias_num_or?.value || 0,
                second.mano_de_obra_otras_marcas_siniestro_num_or?.value || 0,
              ],
            },
          ]
          : []),
      ]
    }
  }

  get isHonda() { return this.config.dataOf == "honda"; }
  get isGeneric() { return this.config.dataOf == "einaCompany"; }
  get comparativeColors() { return ["#5fa7f8", "#cfe5fd", "#8dce50", "#ddf0cb"] }
  get defaultColors() { return ["#5fa7f8", "#8dce50"] }

}