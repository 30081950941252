<div class="business-cards">
    <app-card [contentLoaded]="loaded" [noStyle]="true">
        <app-card-title>Configuración de correo electrónico</app-card-title>

        <div class="info-and-send">
            <p class="c_t2 pr10">En este apartado puedes configurar tu servidor de correo para que podamos enviar los
                correos
                electrónicos a
                partir de los datos que nos proporciones.</p>
            <app-layout-send-email style="min-width: max-content;" [temaplte]="'smtp'"
                [endpoint]="sendTest.bind(this)"></app-layout-send-email>
        </div>


        <form [formGroup]="form" (ngSubmit)="makeCall()" eForm>
            <div class="onboarding-company-genera-tab-email">
                <div class="form">
                    <app-card-subtitle [first]="true">Información del servidor</app-card-subtitle>
                    <div eForm-section>
                        <mat-form-field appearance="outline">
                            <mat-label>Correo</mat-label>
                            <input type="string" matInput placeholder="ej. : usuario@ejemplo.com"
                                formControlName="username">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input type="password" matInput placeholder="Introduce la constraseña"
                                formControlName="password">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Puerto</mat-label>
                            <input type="number" matInput placeholder="Puerto" formControlName="port">
                        </mat-form-field>
                    </div>
                    <div eForm-section>
                        <mat-form-field appearance="outline">
                            <mat-label>Protocolo</mat-label>
                            <mat-select formControlName="protocol">
                                <mat-option [value]="null">Sin protocolo</mat-option>
                                <mat-option value="ssl">SSL</mat-option>
                                <mat-option value="tls">TLS</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Host</mat-label>
                            <input type="string" matInput placeholder="SMTP Host" formControlName="host">
                        </mat-form-field>
                    </div>
                </div>

                <app-card-subtitle>Contenido correo básico</app-card-subtitle>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Pie de página</mat-label>
                        <input type="string" matInput placeholder="Pie de página" formControlName="footer">
                    </mat-form-field>
                </div>
            </div>

            <div class="df jcc" style="margin-top: 20px;">
                <button subscribed-user color="primary" class="onboarding-company-genera-tab-email-finish"
                    mat-flat-button type="submit">Guardar</button>
            </div>
        </form>
    </app-card>
</div>