import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_GroupTask, notitle } from 'src/app/models/M_GroupTask';
import { M_Product } from 'src/app/models/Products/M_Product';
import { PreviewService } from 'src/app/services/preview.service';
import { MatInput } from '@angular/material/input';
import { DragComponent } from '../drag.component';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { IProductLineTableComponent } from 'src/app/interfaces/IProductLineTableComponent';
import { ProductLineTableComponent } from '../../product-line-table/product-line-table/product-line-table.component';
import { CanInvoiceService } from 'src/app/services/can-invoice.service';
import { or_types, or_types_cargo_interno, or_types_garantia, or_types_normal, or_types_siniestro } from 'src/app/custom-classes/or_types';
import { action_type_or, action_types } from 'src/app/custom-classes/action_types';
import { or_status, or_status_close, or_status_invoiced, or_status_open } from 'src/app/custom-classes/or_states';
import { FormService } from 'src/app/services/form.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { SnackService } from 'src/app/services/snack.service';
import { RolesEnum } from 'src/app/enums/RolesEnum';
import { MASTER_CLIENT } from 'src/app/constants/masters';
import { ViewPath } from 'src/app/app-routing.module';


export interface InvoiceGroupDetails {
  id: FormControl<number | null>;
  invoice_contact_id: FormControl<number | null>;
  invoice_doc_number: FormControl<string | null>;
  invoice_state_id: FormControl<number | null>;
}


@Component({
  selector: 'app-drag-group',
  templateUrl: './drag-group.component.html',
  styleUrls: ['./drag-group.component.css', '../drag.component.css']
})

export class DragGroupComponent implements OnInit, IProductLineTableComponent {

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  @ViewChild(ProductLineTableComponent) productLineTable!: ProductLineTableComponent;
  get blocksLine(): boolean { return true; }

  @ViewChild("nameInput") nameInput!: MatInput;
  @Output() onRemoveGroup: EventEmitter<any> = new EventEmitter();
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() onTypeChangeEvent: EventEmitter<or_types> = new EventEmitter();
  @Output() onInvoiceTask: EventEmitter<M_GroupTask> = new EventEmitter();
  @Output() onGroupChanges: EventEmitter<any> = new EventEmitter();

  @Input({ required: true }) group!: M_GroupTask;
  @Input({required : true}) canModifyGroup!: boolean;
  @Input({required : true}) canModifyTable!: boolean;

  @Input() isBudgetPage: boolean = false;
  @Input() isRecambiosBudget: boolean = false;
  @Input() price_hour: number = 0;
  @Input() type!: action_types;
  @Input() product_list: M_Product[] = [];
  @Input() abono: boolean = false;
  @Input() locked: boolean = false;
  @Input() isOrder: boolean = false;

  v = ViewPath;
  isEdit = false;
  t_n = or_types_normal;
  t_ci = or_types_cargo_interno;
  t_g = or_types_garantia;
  t_s = or_types_siniestro;
  s_o = or_status_open;
  s_c = or_status_close;
  s_i = or_status_invoiced;

  R = RolesEnum;

  client = MASTER_CLIENT;

  public groupName: UntypedFormGroup;
  public groupDetailedInvoice: FormGroup<InvoiceGroupDetails>;

  constructor(private fb: UntypedFormBuilder, private fs: FormService, private apiS: ApiService, private snackS: SnackService,
    private chdRef: ChangeDetectorRef, private previewS: PreviewService, @Optional() public dg: DragComponent,
    public responsiveS: ResponsiveService, private confirmD: ConfirmDialogService, public companyS: CompanyService, public canInvoiceS: CanInvoiceService) {
    this.groupName = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.groupDetailedInvoice = new FormGroup<InvoiceGroupDetails>({
      id: new FormControl<number | null>(null),
      invoice_contact_id: new FormControl<number | null>(null),
      invoice_doc_number: new FormControl<string | null>(''),
      invoice_state_id: new FormControl<number | null>(null),
    })
  }

  getClientDiscount(p: M_Product | M_CustomProduct): number | null {
    if (this.group.type.interno) { return null; }
    let disc = this.dg.action?.defaultInvoiceClient?.getClientDiscount(p);
    return disc ? disc : null;
  }

  ngOnInit(): void {
    this.groupDetailedInvoice.patchValue(this.group);
  }

  billPreview() {
    this.previewS.showPreview("I", this.group.token, undefined, this.group.invoice_id);
  }

  hasChanges() {
    return this.group.products.some(p => {
      return p.line_hasChanges;
    });
  }

  getAllProducts(): M_Product[] {
    let products: M_Product[] = [];
    this.dg.groupsComponents?.forEach(g => {
      products.push(...g.getInProcessProducts());
    })
    return products;
  }

  getOtherOnView(p: M_Product) {
    let others: M_Product[] = [];
    let dragComponent: DragComponent = this.dg;
    /** Early exit condition */
    if (dragComponent.groupsComponents == undefined) { return others; }
    dragComponent.groupsComponents.filter(g => g.group.id != this.group.id).forEach(g => {
      g.group.products.forEach(prod => {
        /** Here, the same product is found on other gorup task */
        if (prod instanceof M_Product && prod.product_id == p.product_id) {
          others.push(prod);
        }
      })
    })
    return others;
  }


  get isDeleteDisabled() {
    return this.group.products.some(p => p instanceof M_Product && p.isRequested);
  }

  getInProcessProducts(): M_Product[] {
    let products: M_Product[] = [];
    this.group.products.forEach(p => {
      if (p instanceof M_Product) {
        products.push(p);
      }
    });
    return products;
  }

  hasTasks() {
    return this.group.products.length != 0;
  }

  /** TODO --> Always open dialog. */
  invoiceGroupTask() {
    if (this.group.hasChanges()) {
      this.saveChanges();
    }
    this.onInvoiceTask.emit(this.group);
    this.onGroupChanges.emit();
  }

  getStatus() {
    if (this.group.type.interno && this.group.isInvoiced()) {
      let a: [or_status, string] = [this.group.state, 'Tramitado']
      return a;
    };
    return this.group.state;
  }

  toggleEdit(text: string) {
    if (this.canModifyGroup) {
      this.isEdit = !this.isEdit;
      if (text == notitle) {
        this.groupName.patchValue({ name: null })
      }
      else {
        this.groupName.patchValue({ name: text })
      }
    }

    setTimeout(() => {
      this.nameInput.focus();
    })
  }

  onTypeChange(val: number) {
    let beforeType = this.group.type;
    let futureType = new or_types(val);
    this.apiS.action.addEditGroup(this.group.action_id, this.group.id, this.group.title, futureType).then(res => {
      if (res != -1) {
        this.group.type = futureType;
        if (this.group.type.interno) { this.showCargoInternoMessage(); }
        if (beforeType.interno && !futureType.interno) {
          this.group.products.forEach(p => {
            if (p) { p.discount = this.getClientDiscount(p); p.line_hasChanges = true; }
          })
        }
        this.onTypeChangeEvent.emit(this.group.type);
        this.onGroupChanges.emit();
      }
    })
  }

  showCargoInternoMessage() {
    let discountMessage = false;
    let priceMessage = false;


    if (this.group.products) {
      discountMessage = this.group.products.some(p => {
        return (p != undefined && p != null && p.discount != 0);
      });
      priceMessage = this.group.products.some(p => {
        return p != undefined && !p.buy_price && !p.isTime;
      })
    }

    if (discountMessage || priceMessage) {

      let dMessage = "• Se ha eliminado el descuento de todos los productos.";
      let pMessage = "• Hay productos que NO tienen establecido el coste.";
      let bodyMessage: string[] = [];
      bodyMessage.push("A tener en cuenta : ")
      if (discountMessage) { bodyMessage.push(dMessage) }
      if (priceMessage) { bodyMessage.push(pMessage) }

      this.confirmD.show({
        title: "Cambio a grupo interno",
        body: bodyMessage,
        showCancel: false,
        type: "info",
        confirmTxt: "Ok"
      })
    }

    this.group.products.forEach(p => p.discount = 0);

  }

  editGroupName(val: string) {
    if (this.fs.isOk(this.groupName)) {
      this.apiS.action.addEditGroup(this.group.action_id, this.group.id, val, this.group.type).then(res => {
        if (res != -1) {
          this.group.title = val;
        }
        if (this.isEdit) {
          this.isEdit = false;
        }
      })
    }
  }

  hasFacturableTaskas() {
    let tasks = false;
    this.group.products.forEach(p => {
      tasks = true;
    })
    return tasks;
  }

  isMobileOrTablet(): boolean {
    const width = window.innerWidth;
    return width <= 768;
  }

  openGroup() {
    this.saveChanges();
    this.apiS.action.openGroup(this.group.id).then(_res => {
      this.group.changeStatus(or_status_open);
      this.chdRef.detectChanges();
      this.onStatusChange.emit();
    })
  }

  closeGroup() {
    this.saveChanges();
    this.apiS.action.closeGroup(this.group.id).then(_res => {
      this.group.changeStatus(or_status_close);
      this.chdRef.detectChanges();
      this.onStatusChange.emit();
    })
  }

  isGroupInvoiced() {
    return this.group.state.num == or_status_invoiced.num;
  }

  isGroupClosed() {
    return this.group.state.num == or_status_close.num;
  }

  isGroupOpen() {
    return this.group.state.num == or_status_open.num;
  }

  async saveChanges(_e?: Event | undefined, from?: "by-general" | undefined): Promise<boolean> {
    let tasksToSave = this.group.products.filter(p => { return p.line_hasChanges; })

    this.preventDropDownOpening();
    //this.saveS.hide();

    return new Promise(resolve => {
      if (tasksToSave.length) {
        this.apiS.action.addEditTasks(tasksToSave).then(_res => {
          this.group.markTaskAsSaved();
          this.onGroupChanges.emit();
          if (from != "by-general") { // --> General saved
            this.snackS.show("Cambios guardados")
          }
          resolve(true);
        })
      }
      else {
        resolve(true);
      }
    })

  }

  preventDropDownOpening(e?: Event | undefined) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  isOr() {
    return this.type.num == action_type_or.num;
  }

  emitOnGroupChanges() {
    this.onGroupChanges.emit();
    //this.saveS.show();
  }

  addProduct(p: M_Product | M_CustomProduct): void {
    p.line_hasChanges = true;
    this.group.addProduct(p)
  }

  addTime(time: M_CustomProduct) {
    this.group.addProduct(time);
    this.emitOnGroupChanges();
    this.chdRef.detectChanges();
  }

  addComment(comment: M_CustomProduct): void {
    this.group.addProduct(comment);
    this.emitOnGroupChanges();
    this.chdRef.detectChanges();
  }

  removeProduct(p: M_CustomProduct | M_Product): void {
    if (p && p.task_id) {
      this.apiS.action.rmTask(p.task_id);
      this.group.removeProduct(p);
    }
    else if (p) {
      this.group.removeProduct(p);
    }
  }

  getClassSearcherOf(p: M_Product): M_Product[] {
    let classSearcherP: M_Product[] = []
    if (this.dg && this.productLineTable) {
      this.productLineTable.getSearchers().forEach(s => {
        const classSearcherProduct = s.allData.find(product => product.product_id == p.product_id);
        if (classSearcherProduct) {
          classSearcherP.push(classSearcherProduct);
        }
      });
    }
    return classSearcherP;
  }

  get isVnVoOr() {
    return this.isOr() && this.dg.action?.isVnVoOr;
  }

  getClient() {
    return this.dg.action?.client;
  }

  saveGroupDetails() {
    const formValue = this.groupDetailedInvoice.value;
    this.apiS.saveGroupDetails(formValue).then(res => {
      this.group.setNewDetails(formValue);
    })
  }

}
