import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomFile } from '../../custom-classes/CustomFile';
import { ProfilePictureService } from '../../services/profile-picture.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { M_User } from 'src/app/models/M_User';
import { UserService } from 'src/app/services/EinaMainData/user.service';

/**
 * Component that displays the initial letter of the user who receives input. 
 * If the user has an image, show the image
 * 
 * This component, is comlpemented by the ProfilePictureService.
 * If the ProfilePictureService emits the event 'profileImageSubject', 
 * and this component 'user' has the same id, this component image is updated.
 * 
 */

@Component({
  selector: 'app-circular-letter',
  templateUrl: './circular-letter.component.html',
  styleUrls: ['./circular-letter.component.css']
})
export class CircularLetterComponent implements OnInit {
  @Input() contentLoaded : boolean = true;
  /** User object of the component  */
  @Input() user?: M_User;
  /** Only show the ciruclar component and not the user full name */
  @Input() showFullName = true;
  /** Show the component on grayout */
  @Input() grayOut = false;
  /** Small size (24px x 24px)*/
  @Input() small = false;
  /** Big size (120px x 120px)*/
  @Input() big = false;
  /** Medius size (100px x 100px)*/
  @Input() medium = false;
  /** Check if the input user is the current logged user and shows 'TÚ'. (SessionService ID) */
  @Input() checkIsMe = true;
  /** Show user name with tooltip on hover */
  @Input() showTooltip = true;
  /** Letter box shadow */
  @Input() boxShadow = false;
  /** Upload image when user click this component. Result on 'onUploadImage' output */
  @Input() imageUpload = false;
  /** Diasble interactions with the ciruclar letter */
  @Input() disabled = false;
  /** Show edit icon */
  @Input() showEditIcon: boolean = false;
  /** Show edit icon */
  @Input() showChangeIcon: boolean = false;
  /** Emission when user upload a image */
  @Output() onUploadImage: EventEmitter<[CustomFile, M_User]> = new EventEmitter();

  @ViewChild(FileUploadComponent) fuc?: FileUploadComponent;

  /** Defines the background color by generating a random number from the user name.*/
  circleBackground: number | undefined;

  loadImgError = false;

  constructor(private profileS: ProfilePictureService, private userS: UserService) {
    this.profileS.profileImageSubject.subscribe(data => {
      if (this.user && this.user.id == data[1].id) {
        this.user.img = data[0].src;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getColorByName();
  }

  ngOnInit(): void {
    this.getColorByName();
  }

  get hasImage() {
    return this.user && this.user.img != null;
  }

  get userName() {
    return this.user ? this.user.name : '';
  }

  /** Get the first letter of the user name. If 'checkIsMe' is true, return "TÚ" if the user is the current logged user" */
  get firstLetter() {
    if (this.isMe && this.checkIsMe) {
      return "TÚ";
    }
    else {
      return this.user ? this.user.name ? this.user.name.substring(0, 1).toUpperCase() : "?" : "?";
    }
  }

  get isMe() {
    if (this.user && this.userS.isMe(this.user.id)) {
      return true;
    }
    return false;
  }

  /** Generates a color by the user name */
  getColorByName() {
    let n = this.userName;
    if (n && n.length >= 1) {

      n = n.toLocaleLowerCase();
      let indexFirstLetter = n.charCodeAt(0) - 97;

      /** The color is the result of the combination of the first two letters index.  */
      if (n.length >= 2) {
        let indexSecondLetter = n.charCodeAt(1) - 97;
        this.circleBackground = (indexFirstLetter + indexSecondLetter + 10) % 25;
      }
      /** The color is the result of the first letter index.  */
      else {
        let indexFirstLetter = n.charCodeAt(0) - 97;
        this.circleBackground = (indexFirstLetter + 10) % 25;
      }
    }
    /** No user name, default color 99 --> var(--t) */
    else {
      this.circleBackground = 99;
    }

    /** Avoid white background on names with numbers */
    if (this.circleBackground < 0) {
      this.circleBackground = 99;
    }

  }

  uploadNewImage() {
    if (this.fuc && this.imageUpload) {
      this.fuc.clickInputTrigger();
    }
  }

  processNewUpload(cf: CustomFile) {
    if (this.user) {
      this.user.img = cf.src;
      this.onUploadImage.emit([cf, this.user])
    }
  }

  imgError() {
    this.loadImgError = true;
  }

}
