<app-page-structure #ps [pageTitle]="'Colores'" [masterClass]="undefined" [autocompleteID]="'color'"
    searchLabel="Buscar color" listTitleText="Listado de colores" [data]="[]"
    [displayedHeader]=" ['Cod.Color', 'Nombre','Marca','Grp.Contable','serie']"
    [displayedColumns]="['code','name','brand_name','codeAccounting','serie_name']" 
    [specialText]="[undefined,undefined,undefined,codeAccounting,undefined]"
    [quickFilter]="quickFilter"
    [goBackCustom]="{
        text: 'Listado vehículos',
        view: v.garage
    }"
    [filter]="f" [filters]="filtersToShow" 
    (onclickRow)="onDialogRef($event)" 
    (onClickCreateButton)="onDialogRef($event)"
    [createButton]="
        {
            text : 'Crear color' , 
            icon : 'palette',
        }"  [quickFilter]="quickFilter">
</app-page-structure>