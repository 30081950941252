import { IPageStructureItem } from "src/app/interfaces/IPageStructureItem";
import { M_AccountingGroupRow } from "./M_AccountingGroupRow";
import { getArrayOf } from "src/app/utils/FunctionUtils";
import { match } from "src/app/services/search.service";
import { M_Center } from "../M_Center";

export type acType = "C" | "T" | "A";
export type type_FAC = "F" | "A" | "C";

export class M_AccountingGroup implements IPageStructureItem {
    id: number;
    code: string;
    name: string;
    type: acType;
    company_id: number;
    center_id: number;
    lines: M_AccountingGroupRow[] = [];
    center: M_Center | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.code = d.code;
        this.name = d.name;
        this.type = d.type;
        this.company_id = d.company_id;
        this.center_id = d.center_id;
        this.center = d.center ? new M_Center(d.center) : undefined;
        this.lines = d.lines ?? getArrayOf(M_AccountingGroupRow, d.lines);
    }

    get type_name() {
        return this.type == "C" ? "Comercial" : this.type == "T" ? "Tiempo" : "Productos";
    }

    get itemId(): number | undefined {
        return this.id;
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.id, this.code, this.name, this.type_name, this.center_name);
    }

    get center_name(): string {
        return this.center?.name || "";
    }

}