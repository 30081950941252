import { environment } from "src/environments/environment";

/** Maintain control of which functionalities should be shown or hidden */
export const feature = {

    /** Translations */
    translations: environment.local || environment.dev,

    /** Show developer helprer */
    developerHelper : environment.local || environment.dev || environment.preproduction,

    /** Data upload */
    dataUpload: false,

    /** Colors */
    colors : false,

    /** Import tariff */
    importTariff: false,

    /** Global searcher */
    bigMama: environment.local || environment.production,

    /** Tracking user actions */
    logRocket: environment.production,

    /** HubSpot */
    hubSpot : environment.production,

    /** Reports */
    reports : environment.local || environment.dev
}
