<!-- Filters -->
<div>
    <mat-form-field appearance="outline">
        <mat-label>Buscar</mat-label>
        <input type="text" #searchInput matInput placeholder="Buscar" (input)="applySearchFilter(searchInput.value)">
        <mat-icon matSuffix class="cp" (click)="applySearchFilter(searchInput.value)">search</mat-icon>
    </mat-form-field>
    <app-filters #eventFilter [filters]="filters" (onfiltersDone)="filter($event)"></app-filters>
</div>

<!-- Table -->
<table mat-table [dataSource]="dataFiltrada">

    <!-- Type Mov Col -->
    <ng-container matColumnDef="factor">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element">
            <div class="df aic vac">
                <div>
                    <mat-icon class="mr10 c_b" *ngIf="element.factor === moveEnum.ENTRADA"
                        [filled]="true">arrow_circle_right</mat-icon>
                    <mat-icon class="mr10" *ngIf="element.factor === moveEnum.SALIDA"
                        [filled]="true">arrow_circle_left</mat-icon>
                    <mat-icon class="mr10" *ngIf="element.factor === moveEnum.NO_MOVE" [filled]="true">equal</mat-icon>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Transaction name-->
    <ng-container matColumnDef="transac_name">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
            <p class="nmb fw400 nmb">
                <span class="fss">{{element.transac_name}} </span>
            </p>
            <p class="nmb fss c_t2">{{element.created_at.shortFormat()}}</p>
    </ng-container>

    <!-- Location Col -->
    <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>Ubicación</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.location_name; else noRow"> {{element.location}}</span>
        </td>
    </ng-container>

    <!-- Documents Col -->
    <ng-container matColumnDef="document">
        <th mat-header-cell *matHeaderCellDef>Documento</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.document; else noRow">{{element.document}}</span>
        </td>
    </ng-container>

    <!-- Created Col -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef>Fecha</th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.created_at;else noRow">{{element.created_at.shortFormat()}} </span>
        </td>
    </ng-container>

    <!-- Disponible Col -->
    <ng-container matColumnDef="quanti_mov">
        <th mat-header-cell *matHeaderCellDef class="quantityColumn">Cantidad</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">

            <span>
                <span *ngIf="element.factor === moveEnum.ENTRADA">+</span>
                <span *ngIf="element.factor === moveEnum.SALIDA">-</span>
                <span prod-move-number [val]="element.quanti_mov"></span>
            </span>

        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDialog(row)"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>

<ng-template #noRow>
    <span class="c_t2">Sin datos</span>
</ng-template>