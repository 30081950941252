import { Component, Inject, ViewChild } from '@angular/core';
import { SettingsComponent } from '../settings.component';
import { SmtpComponent } from './smtp/smtp.component';
import { DocumentationConfigComponent } from './documentation-config/documentation-config.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { MemoryParamsService } from 'src/app/services/memory-params.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { RouterService } from 'src/app/services/router.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-company-tab',
  templateUrl: './company-tab.component.html',
  styleUrls: ['./company-tab.component.css']
})
export class CompanyTabComponent extends ParameterStateComponent {
  @ViewChild(CompanyFormComponent) companyForm!: CompanyFormComponent;
  @ViewChild(DocumentationConfigComponent) documentationTab!: DocumentationConfigComponent;
  @ViewChild(SmtpComponent) smtpTab!: SmtpComponent;
  selectedTab = 0;
  constructor(@Inject(SettingsComponent) public settingsComponent: SettingsComponent, routerS: RouterService, route: ActivatedRoute,
    private mps: MemoryParamsService, public responsiveS: ResponsiveService) {
    super(routerS, route, ['subtab']);
  }

  /** If the 'subtab' param exist, select corresponding tab  */
  override onParam(param: string, value: string): void {
    if (param == 'subtab' && value.getNumber()) {
      this.selectedTab = value.getNumber();
    }
  }

  get loaded() {
    return this.settingsComponent.loaded;
  }

  /** Necessary to update the selected tab */
  tabChanged(event: MatTabChangeEvent) {
    this.mps.add(['subtab', event.index.toString()]);
  }
}
