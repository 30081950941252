import { endpoints } from "../constants/Endpoints";
import { RegistrationTaxEnum } from "../enums/RegistrationTaxEnum";
import { ComercialConceptType } from "../enums/ComercialConceptType";
import { IClassSearcher } from "../interfaces/IClassSearcher";
import { Endpoint } from "../custom-classes/Endpoint";
import { match } from "../services/search.service";

/**
 * Represents various concepts related to vehicle selling within a company.
 * These concepts include pricing, taxation, and accounting details necessary for managing vehicle sales.
 */
export class M_Concept implements IClassSearcher<M_Concept> {
    id: number;
    created_at?: Date;
    updated_at?: Date;
    company_id: number;
    name: string;
    price: number;
    cost?: number;
    tax: number;
    tax_type?: number;
    exempt_type?: number;
    registration_tax?: boolean;
    in_invoice?: boolean;
    accounting_id? : number;
    concept_type?: number;
    record?: number;
    record_historic?: boolean;
    percentage?: number;
    line_hasChanges: boolean = false;

    constructor(d: any) {
        this.id = d.id;
        this.company_id = d.company_id;
        this.name = d.name;
        this.price = d.price;
        this.cost = d.cost;
        this.tax = d.tax;
        this.tax_type = d.tax_type;
        this.exempt_type = d.exempt_type;
        this.registration_tax = d.registration_tax;
        if (d.in_invoice == 1) {
            this.in_invoice = true;
        } else {
            this.in_invoice = false;
        }
        this.accounting_id = d.accounting_id;
        this.concept_type = d.concept_type;
        this.record = d.record;
        this.record_historic = d.record_historic;
        this.percentage = d.percentage;
        this.created_at = d.created_at ? new Date(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new Date(d.updated_at) : undefined;
    }


    get endpoint(): Endpoint | undefined { return endpoints.getConcepts; }
    get itemId(): number { return this.id }
    get icon(): string { return "playlist_add_check_circle" }
    get typeof() { return M_Concept }

    get getType() {
        switch (this.concept_type) {
            case ComercialConceptType.NORMAL: {
                return 'Normal';
            }
            case ComercialConceptType.DESCUENTO: {
                return 'Descuento'
            }
            case ComercialConceptType.COMPRA: {
                return 'Compra'
            }
        }
        return "";
    }

    getTag(): string {
        switch (this.concept_type) {
            case ComercialConceptType.NORMAL: {
                return 'normal-concept';
            }
            case ComercialConceptType.DESCUENTO: {
                return 'discount-concept'
            }
            case ComercialConceptType.COMPRA: {
                return 'buy-concept'
            }
        }
        return "";
    }

    createNew(d: any): M_Concept {
        return new M_Concept(d);
    }

    getInputText(): string {
        return this.name;
    }

    defaultSearchFilter(v: string): boolean {
        return match(v, this.name)
    }

    get lineBlock() {
        return false;
    }
    get isIVA4() { return this.tax === 4 ? true : false; }
    get rgTax() { return this.registration_tax ? true : false; }
    get isIVA10() { return this.tax === 10 ? true : false; }
    get isIVA21() { return this.tax === 21 ? true : false; }
    get exento() { return this.tax === 0 ? true : false; }

    getPriceAndDiscount(precioTotal: number, ivaPorcentaje: number) {

        if (ivaPorcentaje === 0) {
            return precioTotal;
        }
        const iva = precioTotal * (ivaPorcentaje / 100);
        return iva;
    }
    getTaxVehicle(precioTotal: number, ivaPorcentaje: RegistrationTaxEnum) {

        const iva = precioTotal * (ivaPorcentaje / 100);
        return iva;
    }

    getOptionText(modules?: number[] | undefined): [string, string | undefined] {
        return [this.name, this.price ? this.price?.toString() + "€" : ''];
    }

    getLeftNumber?(modules?: number[] | undefined): { value: string | number; class_: string | undefined; } {
        return { value: this.getType, class_: "concept-hint-class-searcher" + " " + this.getTag() }
    }

    minify?: boolean | undefined;
    onlyCopies?: boolean | undefined;
    extraOptionText?: string | undefined;
}
