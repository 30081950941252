import { invoiceCallProduct } from "../../types/invoiceCallProduct";
import { M_CustomProduct } from "./M_CustomProduct";
import { M_Product } from "./M_Product";

export type mainPrice = "price" | "pvp_t" | "buy_price";

/**
 * Class with base atributes of a product. 
 * M_Product and M_CustomProduct extends from this class 
 */
export class M_BaseProduct {

    task_id: number | undefined;
    group_id: number | undefined;
    //id comercial
    id : number | undefined;
    name: string;
    quantity: number;
    buy_price: number;
    price: number;
    discount: number | null;
    tax: number;
    aboned: boolean = false;
    initialValue: number;
    control_stock: boolean | undefined;
    stock_min: number | undefined;
    stock_max: number | undefined;
    reserve: boolean = false;

    /** Product line table attributes */
    line_id: number | undefined;
    line_hasChanges: boolean = false;
    line_destroyed: boolean = false;
    enter_by_cost: boolean = false;
    hasChanges: boolean = false;
    pvp_t: number;

    /** Max value to add on product-line-table */
    maxValue: number = 999;

    /** Only TRUE on the custom products. */
    readonly custom: boolean = false;
    /** Only TRUE on the custom products (time). */
    readonly is_hours: boolean = false;
    /** Only TRUE on the custom products (comment). */
    readonly is_comment: boolean = false;

    constructor(d: any) {
        this.task_id = d.task_id;
        this.line_id = d.line_id ? d.line_id : undefined;
        this.name = d.name;
        this.quantity = d.quantity;
        this.buy_price = d.buy_price;
        this.price = d.price ? d.price : 0;
        this.stock_min = d.stock_min ? d.stock_min : 0;
        this.stock_max = d.stock_max ? d.stock_max : 0;
        var discount = d.discount;
        this.pvp_t = d.pvp_t ? d.pvp_t : 0;
        if(d.id){
            this.id = d.id;
        }else{
            this.id = undefined;
        }
        if (typeof discount == "number") {
            this.discount = discount;
        }
        else {
            this.discount = null;
        }
        this.tax = d.tax;
        this.initialValue = 0;
        this.reserve = d.reserve;
    }

    setQuantity(q: number) {
        this.quantity = q;
    }

    initQuanitity(q: number) {
        this.quantity = q;
        this.setUpInitialValue();
    }

    setLineId(id: number) {
        this.line_id = id;
    }

    getTotal(mainPrice: mainPrice, extradfields = false, applyDiscount = true): number {
        if (this.quantity) {


            if (applyDiscount) {
                let discount = this.getDiscountedTotal(mainPrice);
                if (discount != undefined) { return (this.quantity * discount); }
            }

            switch (mainPrice) {
                case "price":
                    return this.quantity * this.price;
                case "pvp_t":
                    return this.quantity * this.pvp_t;
                case "buy_price":
                    if (this.isTime) { return this.quantity * this.price; }
                    return this.quantity * this.buy_price;
                default:
                    return 0;
            }
        }
        return 0;
    }

    getTotalIVA() {
        if (this.quantity && this.tax) {
            let total = this.getTotal("price");
            return total * (this.tax / 100);
        }
        return 0;
    }

    private getDiscountedTotal(mainPrice: mainPrice): undefined | number {
        if (this.discount == 0 || this.discount == null || this.discount == undefined) { return undefined }
        return Number(this.getDiscounted(mainPrice, this.discount));
    }

    /**
     * Get the discounted value on
     * @param val amount of discount
     * @param mutiply quantity of the product
     * @returns 
     */
    getDiscounted(mainPrice: mainPrice, val: number, mutiply?: number) {
        let mainPriceValue = mainPrice == "price" || this.isTime ? this.price : mainPrice == "pvp_t" ? this.pvp_t : this.buy_price;
        if (val >= 0 && val <= 100) {
            const discountPercentage = val / 100;
            const discountedPrice = mainPriceValue * (1 - discountPercentage);
            if (mutiply !== undefined) {
                const totalPrice = discountedPrice * mutiply;
                return totalPrice.castDecimals(2);
            } else {
                return discountedPrice.castDecimals(2);
            }
        }
        return undefined;
    }

    getInvoiceCallObject(modifyStock: boolean): invoiceCallProduct {
        throw Error("Implemented on childs")
    }

    setUpInitialValue(): number {
        this.initialValue = this.quantity;
        return this.initialValue;
    }

    getInitialValue(): number {
        return this.initialValue;
    }

    get icon(): string {
        throw Error("Implemented on childs")
    }


    get isTime(): boolean {
        return false;
    }

    get isComment(): boolean {
        return false;
    }

    /**
     * Determines the color (css class) of the stock text. It is verified that stock control is activated.
     * @param stock Current stock of the product
     * @returns CSS class
     */
    getStockClassByStock(stock: number | undefined, hasRecambios: boolean) {
        /** Custom product or custom time */
        if (this.custom || stock == undefined) { return "" }
        if (!this.control_stock && hasRecambios) { return "c_t2" }
        if (stock == 0) { return "c_o fw500" }
        else if (stock > 0) { return "c_t2 fw500" }
        else { return "c_r fw500" }
    }

    /**
     * Create a string based on the product stock. 
     * It also takes into account whether the product has stock control activated.
     * @param stock Current stock of the product
     * @returns "X unidades || X unidad || Sin control de stock"
    */
    stockText(stock: number, hasRecambios: boolean): string | undefined {
        if (this.custom) { return undefined }
        if (hasRecambios && !this.control_stock) { return "Sin control stock" }
        /** The stock cannot be less than 0. Never show stock < 0 */
        let fakeStock = stock < 0 ? 0 : stock;
        return this.unitOrUnitsText(fakeStock);
    }

    unitOrUnitsText(stock: number) {
        return stock + ' ' + (stock == 1 ? 'ud.' : 'uds.');
    }

    reachedStockMin(stock: number) {
        if (this.stock_min == undefined || this.control_stock == undefined || this.control_stock == false) { return false; }
        return stock <= this.stock_min;
    }

    copyCoreAttributes(p: M_BaseProduct) {
        throw Error("Implemented on childs")
    }

    instanceofProduct(): this is M_Product {
        throw Error("Implemented on childs")
    }

    instanceofCustom(): this is M_CustomProduct {
        throw Error("Implemented on childs")
    }
}