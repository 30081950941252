import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { M_Serie } from 'src/app/models/M_Serie';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SerieFormComponent } from 'src/app/serie-form/serie-form.component';
import { ViewPath } from 'src/app/app-routing.module';
import { SnackService } from 'src/app/services/snack.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-serie-details',
  templateUrl: './serie-details.component.html',
  styleUrls: ['./serie-details.component.css']
})
export class SerieDetailsComponent  implements OnInit{
  public serie?: M_Serie;
  public isDetails: boolean = true;
  public isEdit = false;
  public loaded: boolean = false;
  v = ViewPath;
  public id_serie: number | undefined;
  @ViewChild(SerieFormComponent, { static: true }) serieForm?: SerieFormComponent;
  constructor(private apiS: ApiService, route: ActivatedRoute,  private routerS: RouterService, public snackS: SnackService,
    @Optional() public dialogRef: MatDialogRef<SerieDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Serie | undefined
  ) {}
  ngOnInit(): void {
    if(this.isOnDialog && this.data != undefined){
      console.log(this.data);
      this.serie = new M_Serie(this.data);
      console.log(this.serieForm?.form.value,this.serie)
      setTimeout(() => {
        if (this.serie?.brand_id) {
          this.serieForm?.brandsModelSerie.brandSearcherComponent?.select(this.serie.brand_id);
        }},0);
      this.loaded = true;
      this.serieForm?.form.patchValue(this.serie);
      if(this.serie.accounting != undefined){
        this.serieForm?.form.patchValue({accounting_id:this.serie?.accounting?.id});
      }
    }
  }
  get getSerie() {
    return this.serie;
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  create() {
    console.log(this.serie);
    console.log(this.serieForm?.form.valid);
      if(this.serieForm?.form.valid){
          this.serie = { ...this.serie, ...this.serieForm.form.value };
          if (this.serie) {
              this.apiS.createSerie(this.serie).then(res => {
              if (res) {
                this.routerS.goTo(this.v.series);
                this.snackS.show('Cambios guardados con éxito');
                this.dialogRef.close(res);
              }
            });
          }
      }
  }
}
