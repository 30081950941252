<app-page-structure [masterClass]="'invoice'" pageTitle="Ventas" [autocompleteID]="'bills'"
    searchLabel="Buscar facturas" listTitleText="Listado de facturas" noDataCreateNew="Crear una nueva factura"
    [data]="[]"
    [displayedHeader]=" ['Número',              'Fecha facturación',   'Cliente',     'Total',          'Tipo',   'Estado' ]"
    [displayedColumns]="['company_scope_id',   'created_at',    'client',      'total',          'invoice_type_or_type',   'state']"
    [preIcon]="         [invoiceIcon, undefined]"
    [specialText]="     [ undefined,            undefined,    getClientName,  getTotal,         undefined,          undefined,   undefined]"
    [specialClass]="    [ undefined,            undefined,      undefined,      undefined,       undefined,          undefined,   undefined]"
    [cellZize]="        ['big',    undefined,   undefined,   'small',     undefined, undefined]"
    [dateFormat]="      [undefined, 'show-hour']" [rightMenu]="rightMenu" [filters]="filtersToShow" [filter]="f"
    [exportExcel]=" {endpoint : e.exportInvoices , singular : 'factura', plural : 'facturas'}"
    (onSelect)="this.resetPaymentForm()" (onclickRow)="paramsS.go(v.invoiceDetails, $event.id)" [createButton]="
        {
            text : 'Crear factura' , 
            icon : 'receipt_long', 
            view : v.createInvoice, 
            tooltip : isDraft ? 'Borrador en curso' : '',
            loading : isCircleLoading,
            cssClass : 'onboarding-create-invoice ' + (isDraft ? 'draft' : '')
        }" [quickFilter]="quickFilter">
</app-page-structure>


<ng-template #rightMenu>
    <div *ngIf="ps">
        <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
        <button *ngIf="true && ps?.loaded_" mat-menu-item [disabled]="ps.disabledExcelDownload()"
            (click)="donwloadInvoices(ps.dataOnScreen)">
            <mat-icon>download</mat-icon>
            Descargar PDF ({{ps.dataOnScreen.length}})
        </button>
        <app-skeleton *ngIf="!ps.loaded_" [width]="176" [height]="36"></app-skeleton>
    </div>
</ng-template>