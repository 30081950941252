import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { DayFilter, Filter, FilterOption, TagFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { BillPageFiltesrEnum } from '../invoices/invoices.component';
import { invoice_states } from 'src/app/custom-classes/invoice_states';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.css']
})
export class PurchasesComponent implements OnInit {
  

  v = ViewPath;
  f = filter;
  loading = false;

  filtersToShow: Filter[] = [
    new DayFilter("Fecha de creación").setId(BillPageFiltesrEnum.INVOICE_CREATED_AT),
    new TagFilter("Estado de la factura", invoice_states, new FilterOption("Pendiente"), new FilterOption("Parcial"), new FilterOption("Pagada")).setId(BillPageFiltesrEnum.INVOICE_STATE),
  ]
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Purchase>;
  constructor(public subS: SubscriptionService, public sessionS: SessionService, public params: ParamsService,
    public apiS: ApiService) {
  }

  ngOnInit(): void {
    this.apiS.getPurchases().then(res => {
      this.loading = true;
      this.ps.initTable(res);
    });

  }

  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == BillPageFiltesrEnum.INVOICE_STATE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  purchaseIcon(p: M_Purchase) { return p.icon; }
  contact(purchase: M_Purchase) { return purchase.contact?.name }
  typeName(purchase: M_Purchase) { return purchase.typeName }
  typedate(purchase: M_Purchase) { return purchase.date ? purchase.date : purchase.created_at; }
}
