import { BookMarkEnum, getBookMarkColorStyle } from "../enums/BookMarkEnum";
import { dispForClient } from "../types/dispForClient";
import { M_Contact } from "./M_Contact";
import { M_StoreHouse } from "./M_StoreHouse";

export class M_Location {
    id: number;
    company_id: number;
    name: string;
    deleted: boolean;
    disp: number
    physical: number
    reserved: string;
    engaged: string
    fault: number
    default: boolean;

    /** Store house attributes */
    store_id: number;
    store_name: string;
    bookmark: BookMarkEnum

    last_in: Date | undefined
    last_out: Date | undefined

    /** Reservations */
    disp_for_client: dispForClient[] = [];

    constructor(d: any) {
        this.id = d.id ? d.id : d.location;
        this.company_id = d.company_id;
        let disp = d.disp;
        this.disp = typeof disp == "string" ? disp.getNumber() : disp ? disp : 0;
        this.name = d.name ? d.name : d.loc_name ? d.loc_name : "";
        this.deleted = d.deleted;
        this.reserved = d.reserved;
        let fault = d.fault
        this.fault = typeof fault == "string" ? fault.getNumber() : fault;
        let physical = d.physical;
        this.physical = typeof physical == "string" ? physical.getNumber() : physical;
        this.engaged = d.engaged;
        this.last_in = d.last_in ? new Date(d.last_in) : undefined;
        this.last_out = d.last_out ? new Date(d.last_out) : undefined;
        this.default = d.default;

        if (d.store) {
            let storehouse = new M_StoreHouse(d.store);
            this.bookmark = storehouse.bookmark;
            this.store_id = storehouse.id
            this.store_name = storehouse.name;

        } else {
            this.bookmark = d.bookmark ? d.bookmark : BookMarkEnum.PRIMARY;
            this.store_id = d.store_id;
            this.store_name = d.store_name;
        }

        let disp_for_client = d.disp_for_client;
        if (disp_for_client && Array.isArray(disp_for_client)) {
            this.disp_for_client = disp_for_client;
        }
    }

    get bookmarkColorStyle() {
        return getBookMarkColorStyle(this.bookmark);
    }

    getClientReserved(c: M_Contact | undefined): number {
        if (!c) { return 0 }
        let filtered =  this.disp_for_client.filter(dfc => dfc.client_id == c.client_id);
        let total = filtered.reduce((sum, dfc) => sum + dfc.disp, 0);
        return total ? total : 0;
    }
}