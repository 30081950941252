import { Component, ComponentRef, DoCheck, HostListener, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { MASTER_PRODUCT, MASTER_PRODUCT_PROVIDERS } from 'src/app/constants/masters';
import { M_CustomProduct } from 'src/app/models/Products/M_CustomProduct';
import { M_Product } from 'src/app/models/Products/M_Product';
import { CreateEditAlbaranComponent } from 'src/app/views/create-edit-albaran/create-edit-albaran.component';
import { CreateInvoiceComponent } from 'src/app/views/create-invoice/create-invoice.component';
import { CreateOrderComponent } from 'src/app/views/create-order/create-order.component';
import { DragGroupComponent } from '../../drag/drag-group/drag-group.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyService } from 'src/app/services/EinaMainData/company.service';
import { getStock } from 'src/app/services/stock-calculator.service';
import { ICustomProductData } from 'src/app/interfaces/ICustomProductData';
import { CreateCustomProductComponent } from '../create-custom-product/create-custom-product.component';
import { ProductSearcherComponent } from './product-searcher/product-searcher.component';
import { AddTimeComponent, IAddTime } from '../../add-time/add-time.component';
import { getRandomNoProductMessage, PS_HIGHLIGHT } from 'src/app/constants/constants';
import { AbonoInvoiceComponent } from 'src/app/views/abono-invoice/abono-invoice.component';
import { EditOrderComponent } from 'src/app/views/edit-order/edit-order.component';
import { ManualFaultComponent } from '../../manual-fault/manual-fault.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/services/Api/api.service'; import { ActivatedRoute } from '@angular/router';
import { M_Fault } from 'src/app/models/M_Fault';
import { ViewPath } from 'src/app/app-routing.module';
import { RemoveReservationComponent } from '../../remove-reservation/remove-reservation.component';
import { StorehouseEntrySectionComponent } from 'src/app/views/create-storehouse-entry/storehouse-entry-section/storehouse-entry-section.component';
import { MatMenu } from '@angular/material/menu';
import { MovTypeEnum } from 'src/app/enums/MovTypeEnum';
import { GoFaultService } from 'src/app/services/go-fault.service';
import { mainPrice } from 'src/app/models/Products/M_BaseProduct';
import { EnrtryTypeEnum } from 'src/app/enums/EnrtryTypeEnum';
import { lineActions } from './product-line-actions/product-line-actions.component';
import { AddCommentComponent } from '../../add-comment/add-comment.component';
import { EntryToEnum } from 'src/app/enums/ContactEnum copy';
import { SellVehicleComponent } from 'src/app/views/sell-vehicle/sell-vehicle.component';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { toMoney } from 'src/app/utils/FunctionUtils';
import { ClassSearcherComponent } from '../../class-searcher/class-searcher.component';
import { endpoints } from 'src/app/constants/Endpoints';
import { TarifasComponent } from 'src/app/views/tarifas/tarifas.component';
import { CreateEditComercialBudgetComponent } from 'src/app/views/create-edit-comercial-budget/create-edit-comercial-budget.component';

/** The type of products that can be added to the table */
type lineTypes = "product" | "custom" | "time" | "comment";


/** Extended info configuration. It is the 3 vertical dots column */
type extendedInfoConfig = {
  /** Show th column? */
  showColumn: boolean,
  /** What actions can the user do? */
  actions?: lineActions[]
}

@Component({
  selector: 'app-product-line-table',
  templateUrl: './product-line-table.component.html',
  styleUrls: ['./product-line-table.component.css']
})
export class ProductLineTableComponent implements DoCheck {

  MASTER_PRODUCT = MASTER_PRODUCT;
  productOnlyProvider = MASTER_PRODUCT_PROVIDERS;
  e = endpoints;
  v = ViewPath;
  FMT = MovTypeEnum;

  /** Container to add new {@link ProductSearcherComponent} */
  @ViewChild('searchProductContainer', { read: ViewContainerRef }) searchProductContainer!: ViewContainerRef;
  /** Array of he current {@link ProductSearcherComponent} on this component */
  searchers: ComponentRef<ProductSearcherComponent>[] = [];

  /** Product or custom product of the component */
  @Input({ required: true }) products: (M_Product | M_CustomProduct)[] = [];
  /** Array of availabe line types (""product" | "custom" | "time"") */
  @Input({ required: true }) lineTypes: lineTypes[] = [];
  /** Can the user modify this table? */
  @Input() canModifyTable: boolean = true;
  /** Cna the user click on the product title? */
  @Input() clickableTitle: boolean = true;
  /** Only show products that have providers  */
  @Input() onlyProviders: boolean = false;
  /** Show products stock. True by default  */
  @Input() showProductsStock: boolean = true;
  /** Can the user delete the las product of the table?  */
  @Input() canDeleteLastProduct: boolean = true;
  /** Show a button preffix on PVP input to determinate te PVP based on the total. By default false. */
  @Input() costbyTotalPreffix: boolean = false;
  /** Show the location button but the user canno't change it. */
  @Input() locationOnlyInformative: boolean = false;


  /** Table pagination */
  @Input() itemsPerPage: number | undefined = undefined;
  paginationArray: number[] = [];
  currentPage: number = 1;
  totalPages: number = 1;


  /** TABLE COLUMNS */
  @Input() hideHeaderIfNoProducts: boolean = false;
  /** Add reserves icon to the product line */
  @Input() showSave: boolean = true;
  /** Only showed on storehouse entry creation. Show if the product is new or the product has a price change */
  @Input() showNewOrPriceChange: boolean = false;
  /** Add location icon to the product line. By default true when recambios module is activated */
  @Input() showLocation: boolean = false;
  /** Show product quantity. By default true. */
  @Input() showQuantity: boolean = true;
  /** Show product total. By default false. */
  @Input() showTotal: boolean = true;
  /** Show product cost. By default false. */
  @Input() showCost: boolean = false;
  /** Show enter by cost checkbox. By default false. */
  @Input() showEnterByCost: boolean = false;
  /** Show product PVP. By default true. */
  @Input() showPVP: boolean = true;
  /** Show product PVP (tarifa). By default false. */
  @Input() showPVPtarifa: boolean = false;
  /** Show discount on the product line. By default true. */
  @Input() showDiscount: boolean = true;
  /** Add reserves icon to the product line */
  @Input() showReserves: boolean = false;
  /** Show the drag handler */
  @Input() showDrag: boolean = false;
  /** Show the drag handler */
  @Input() showProviders: boolean = false;
  /** Show the drag handler */
  @Input() showPrio: boolean = false
  /** Show product destination. By default false */
  @Input() showDest: boolean = false;
  /** Show product leftovers destination. By default false */
  @Input() showEntryTo: boolean = false;
  /** Show product SIGAUS/SIGNUS */
  @Input() sigausSignus: boolean = true;
  ET = EntryToEnum;
  /** Configuration of line extended info */
  @Input() extendedInfoConfig?: extendedInfoConfig;


  /** Component parnet (@Optional injection) */
  parent: CreateInvoiceComponent | DragGroupComponent | CreateOrderComponent | CreateEditAlbaranComponent | EditOrderComponent | StorehouseEntrySectionComponent;
  /** Random message of 'Table with no products' */
  noProductsMsg = getRandomNoProductMessage();
  /** Product id to highlight */
  private highlightProdId: number | undefined = undefined;
  /** The screen already scrolled to the 'highlightProdId' product? */
  private highlightscrolled: boolean = false;


  /** CTRL + SPACE shortcut listener */
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === ' ') {
      if (!this.dragGroup) {
        if (this.canModifyTable) {
          if (this.searchers.length) {
            this.searchers[0].instance.focus();
          }
          else {
            this.appendProductSearcher();
          }
        }
      }
      else {
        let result: DragGroupComponent | undefined = undefined
        let openGroups = this.dragGroup.dg.groupsComponents?.filter(g => g.group.state.open);
        if (openGroups) {

          // If there is only on opened group...
          if (openGroups.length == 1) {
            result = openGroups[0];
          }

          if (!result) {
            // Then find the first group with no tasks
            result = openGroups.find(g => g.group.products?.length == 0);
          }

          if (!result) {
            // Add a new product on the frist open group
            result = openGroups.find(g => g.group.state.open);
          }

        }

        if (result && result.group.id == this.dragGroup.group.id) {
          this.appendProductSearcher();
        }
      }
    }
  }

  constructor(public routerS: RouterService, public companyS: CompanyService, private dialog: MatDialog, public responsiveS: ResponsiveService, private apiS: ApiService, private activeRoute: ActivatedRoute, private goFaultS: GoFaultService,
    /** Inject posible parents */
    @Optional() private createInvoice: CreateInvoiceComponent, @Optional() public dragGroup: DragGroupComponent, @Optional() private createOrder: CreateOrderComponent,
    @Optional() private editOrder: EditOrderComponent, @Optional() public createEditAlbaran: CreateEditAlbaranComponent,
    @Optional() public abono: AbonoInvoiceComponent, @Optional() public storeHouseEntrySection: StorehouseEntrySectionComponent,
    @Optional() public sellvehicle: SellVehicleComponent, @Optional() public createBudgetComercial: CreateEditComercialBudgetComponent,
    @Optional() public importTarifa: TarifasComponent) {
    this.parent = this.checkAndSetParent();
    this.showLocation = this.companyS.recambiosModule;

    console.log("📦 Product line table")

    /** Check the query parameters in case we have to highlight a specific product  */
    this.activeRoute.queryParams.subscribe(params => {
      let prodFaultId = params['highlight_prod_id'];
      if (prodFaultId) { this.highlightProdId = prodFaultId; }
    })

  }
  ngDoCheck(): void {
    this.updatePagination();
  }

  ngOnInit(): void {
    if (this.onlyProviders) {
      this.MASTER_PRODUCT = this.productOnlyProvider
    }
  }

  checkAndSetParent() {
    if (this.createInvoice || this.dragGroup || this.createOrder || this.createEditAlbaran || this.abono || this.editOrder || this.storeHouseEntrySection || this.sellvehicle || this.createBudgetComercial || this.importTarifa) {
      return this.createInvoice || this.dragGroup || this.createOrder || this.createEditAlbaran || this.abono || this.editOrder || this.storeHouseEntrySection || this.sellvehicle || this.createBudgetComercial || this.importTarifa;
    } else {
      throw new Error("ProductLineComponent parent not implement 'I_ProductLineComponent' or is not one of the optional constructor parents");
    }
  }


  isHighlighted(p: M_Product | M_CustomProduct, productRef: HTMLTableRowElement) {
    if (p.instanceofCustom() || !this.highlightProdId || this.highlightscrolled) { return; }
    let ishighlight = p.product_id == this.highlightProdId;
    if (!this.highlightscrolled && ishighlight) {

      this.highlightscrolled = true;

      setTimeout(() => {
        productRef.classList.add('highlight-product');
        productRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);

      setTimeout(() => {
        productRef.classList.remove('highlight-product');
      }, 3000); // --> make sure to match the 'highlight-product' animation time

    }
  }

  getHintClass(p: M_Product | M_CustomProduct) {
    if (p instanceof M_Product) {
      let totalStock = this.totalStock(p);
      return p?.getStockClassByStock(totalStock, this.companyS.recambiosModule);
    }
    return "";
  }

  getStockText(p: M_Product | M_CustomProduct) {
    if (!this.showProductsStock || p instanceof M_CustomProduct) { return undefined; }
    return p.stockText(getStock(p, this.parent, this.parent.getClient(), this.companyS.recambiosModule), this.companyS.recambiosModule);
  }

  getStockMin(p: M_Product | M_CustomProduct) {
    if (!this.showProductsStock || p instanceof M_CustomProduct || !this.companyS.recambiosModule) { return false; }
    return p.reachedStockMin(getStock(p, this.parent, this.parent.getClient(), this.companyS.recambiosModule));
  }

  totalStock(p: M_Product | M_CustomProduct) {
    if (p instanceof M_Product) {
      return getStock(p, this.parent, this.parent.getClient(), this.companyS.recambiosModule);
    }
    return undefined;
  }
  goto(fault: M_Fault) {
    if (fault.order) {
      this.routerS.goWithQueryParams(this.v.orders, { [PS_HIGHLIGHT]: fault.order.id });
    }
  }

  goByFault(f: M_Fault) {
    this.goFaultS.go(f);
  }

  isDiscountActive(p: M_Product | M_CustomProduct): boolean {
    return this.showDiscount && p.discount != null && p.discount != 0 && p.discount > 0;
  }

  isDiscountActiveFor(p: M_Product | M_CustomProduct, mainPrice: mainPrice): boolean {
    return this.isDiscountActive(p) && this.getMainPrice(p) == mainPrice;
  }

  getDiscountHint(p: M_Product | M_CustomProduct) {
    var value = 0;
    if (p && p.discount) {
      value = Number(p.getDiscounted(this.getMainPrice(p), p.discount));
    }
    return value;
  }

  appendProductSearcher() {
    let ref = this.searchProductContainer.createComponent(ProductSearcherComponent);
    ref.instance.ref = ref;

    ref.instance.onSelectProduct.subscribe(val => {

      this.highlightProdId = undefined; //Don't highlight a product if it has just been added

      val.initQuanitity(0);
      val.setQuantity(1);
      val.line_hasChanges = true;
      val.discount = this.parent.getClientDiscount(val);
      this.parent.addProduct(val);
      this.removeProductSearcher(ref);
    });

    ref.instance.onDestroy.subscribe(v => {
      this.removeProductSearcher(ref);
    })

    this.searchers.push(ref);
  }

  private removeProductSearcher(searcher: ComponentRef<ProductSearcherComponent>) {
    this.searchers.removeElement(searcher);
    searcher.instance.destroy();
  }


  getMinValue(product: M_Product | M_CustomProduct) {
    const MIN_VALUE = 1;
    if (product instanceof M_CustomProduct) { return MIN_VALUE; }
    if (this.createOrder) { return product.fault_quantity ? product.fault_quantity : MIN_VALUE; }
    if (this.editOrder) { return product.fault?.quantity ? product.fault.quantity : MIN_VALUE; }
    return MIN_VALUE;
  }

  getMaxValue(product: M_Product | M_CustomProduct) {
    if (product instanceof M_CustomProduct) { return product.maxValue; }
    if (this.storeHouseEntrySection) {
      if (product.pend_quant) { return product.pend_quant }
    }
    return product.maxValue;
  }

  appendTime() {
    let dRef = this.dialog.open<AddTimeComponent, IAddTime>(AddTimeComponent, {
      data: {
        ct: undefined,
        action: this.dragGroup ? this.dragGroup.dg.action : undefined,
        showPriceHour: true,
        priceHour: this.dragGroup ? this.dragGroup.dg.action?.price_hour : undefined,
      }
    });
    dRef.afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        res.line_hasChanges = true;
        res.discount = this.parent.getClientDiscount(res);
        res.tax = 21;
        this.parent.addTime(res);
      }
    })
  }

  appendComment() {
    this.dialog.open<AddCommentComponent, M_CustomProduct | undefined>(AddCommentComponent,
      {
        width: "500px",
        data: undefined,
        autoFocus: false
      }).afterClosed().subscribe(res => {
        if (res instanceof M_CustomProduct) {
          res.line_hasChanges = true;
          this.parent.addComment(res);
        }
      });
  }

  locationDisabled(p: M_Product | M_CustomProduct) {
    return !this.canModifyTable ||
      (p.instanceofProduct() && p.fault_id != undefined) ||
      this.isLineBlocked(p);
  }

  isPVPDisabled(p: M_Product | M_CustomProduct) {
    return !this.canModifyTable ||
      this.isLineBlocked(p)
  }

  isPVPTarifaDisabled(p: M_Product | M_CustomProduct) {
    return !this.canModifyTable ||
      this.PVPtAt("cost", p)
  }

  isCostDisabled(p: M_Product | M_CustomProduct) {
    return !this.canModifyTable ||
      this.isLineBlocked(p) ||
      this.PVPtAt("pvp_t", p)
  }

  isDiscountDisabled(p: M_Product | M_CustomProduct) {
    return !this.canModifyTable || this.isInterno || this.isLineBlocked(p) || this.PVPtAt("cost", p)
  }

  /** This function is called sometimes only for refresh the buy_price on screen */
  PVPtAt(at: "cost" | "pvp_t", p: M_Product | M_CustomProduct): boolean {
    if (this.parent instanceof StorehouseEntrySectionComponent && this.parent.entranceType == EnrtryTypeEnum.PMP) {
      let atCost = p.enter_by_cost && at == "cost";
      let atPVPt = !p.enter_by_cost && at == "pvp_t"
      if (atCost) { p.discount = null; } // well...
      if (atPVPt) {
        // p.buy_price = this.getDiscountHint(p) || p.pvp_t; // what is this?
      }
      return atCost || atPVPt;
    }
    return false;
  }

  isLiquid(p: M_Product | M_CustomProduct) {
    return p instanceof M_Product && p.isLiquid
  }

  isWheel(p: M_Product | M_CustomProduct) {
    return p instanceof M_Product && p.isWheel
  }

  showHintTotal(p: M_Product | M_CustomProduct) {
    return this.sigausSignus && !this.isInterno && (this.isLiquid(p) || this.isWheel(p));
  }

  productExtraFieldName(p: M_Product | M_CustomProduct) {
    return this.isLiquid(p) ? "SIGAUS" : this.isWheel(p) ? "SIGNUS" : undefined;
  }

  getTotalExtraField(p: M_Product | M_CustomProduct) {
    if (!this.showHintTotal(p)) { return undefined };
    if (p instanceof M_Product) {
      if (p.isLiquid || p.isWheel) {
        return p.getExtraFieldTotalPrice;
      }
    }
    return undefined;
  }

  max100Disc(e: HTMLInputElement, p: M_Product | M_CustomProduct) {
    if (p.discount && p.discount > 100) {
      p.discount = 100;
      e.value = "100";
    }
    p.line_hasChanges = true;
  }

  preventMinus(event: KeyboardEvent): void {
    if (event.key === '-') {
      event.preventDefault();
    }
  }

  /** Check if the product has a fault and it's requested.
   *  Also check if the product has a reservation and it's requested.
  */
  private isRequestedOrReservedWithFault(product: M_Product | M_CustomProduct) {
    return this.isRequested(product) || this.isReservedWithFaultRequestedNotRecived(product) || this.isBudgetReservedRequestedAndRecived(product);
  }

  isRequested(product: M_Product | M_CustomProduct) {
    if (product instanceof M_CustomProduct) { return false; }
    return product.isRequested;
  }

  isReservedWithFaultRequestedNotRecived(product: M_Product | M_CustomProduct) {
    if (product instanceof M_CustomProduct || product.reservation == undefined) { return false; }
    return product.reservation.faultRequested && !product.reservation.faultRequestedAndRecived;
  }

  /** Only on budgets. Check if the product is reserved, requested, and recived */
  isBudgetReservedRequestedAndRecived(product: M_Product | M_CustomProduct) {
    if (product instanceof M_CustomProduct || product.reservation == undefined || !this.isBudget) { return false; }
    return product.reservation.faultRequestedAndRecived;
  }

  /** Check if it's necessary to disable the line.*/
  isLineBlocked(product: M_Product | M_CustomProduct) {
    return this.isRequestedOrReservedWithFault(product) && this.parent.blocksLine;
  }


  focusMenuInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    })
  }

  setCostByTotal(product: M_Product | M_CustomProduct, total: number, menu: MatMenu) {
    if (total) {
      product.buy_price = this.getCostByTotal(product, total);
    }
    menu.closed.emit('click');
  }

  getCostByTotal(product: M_Product | M_CustomProduct, total: number) {
    if (total) {
      return (total / product.quantity).castDecimals(2);
    }
    else {
      return product.buy_price;
    }
  }

  newCustomProduct(inputValue: string = "") {
    let dRef = this.dialog.open<CreateCustomProductComponent, ICustomProductData>
      (CreateCustomProductComponent,
        {
          autoFocus: false,
          data: {
            inputValue: inputValue,
            interno: this.isInterno,
            product: undefined,
          }
        });

    dRef.afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {
        if (!res.quantity) {
          res.initQuanitity(0);
          res.setQuantity(1);
        }
        res.discount = this.parent.getClientDiscount(res);
        res.line_hasChanges = true;
        this.parent.addProduct(res);
      }
    })
  }

  manualFault(p: M_Product | M_CustomProduct) {
    if (p.instanceofCustom()) { return; }
    this.dialog.open<ManualFaultComponent, M_Product>(ManualFaultComponent, { data: p })
      .afterClosed().subscribe(res => {
      })
  }
  removeReservation(p: M_Product | M_CustomProduct) {
    if (p.instanceofCustom()) { return; }
    this.dialog.open<RemoveReservationComponent, M_Product>(RemoveReservationComponent, { data: p })
      .afterClosed().subscribe(res => {
      })
  }

  /** According to the columns that are displayed on the screen, 
   * the total of the line is calculated according to a different attribute (price, price (tarifa), buy_price). 
   * 
   * The discount also applies to the calculated attribute.
   * */
  getMainPrice(p: M_Product | M_CustomProduct): mainPrice {
    if ((this.showCost && p.enter_by_cost) || (this.showCost && !this.showPVP && !this.showPVPtarifa)) { return "buy_price"; }
    if (this.isInterno) { return p.isTime ? "price" : "buy_price"; }
    if (this.showPVPtarifa) { return "pvp_t"; }
    return "price";
  }

  totalCurrency(p: M_Product | M_CustomProduct) {
    return toMoney(Number(this.getTotal(p, this.getMainPrice(p), this.showHintTotal(p))));
  }

  getTotal(p: M_Product | M_CustomProduct, mainPrice: mainPrice, extrafields = false) {
    if (p) { return p.getTotal(mainPrice, extrafields, this.isDiscountActive(p)); }
    return 0;
  }

  hasFault(p: M_Product | M_CustomProduct, extrafield = false) {
    if (p instanceof M_Product) {
      return p.fault != undefined;
    }
    return false;
  }
  getTableProducts(): M_Product[] {
    let tableProds: M_Product[] = [];
    this.products.forEach(p => {
      if (p.instanceofProduct()) {
        tableProds.push(p)
      }
    })
    return tableProds;
  }

  getAllProducts() {
    if (this.dragGroup) { return this.dragGroup.getAllProducts() }   /** OR override */
    return this.getTableProducts();
  }

  getOtherOnView(p: M_Product): M_Product[] {
    if (this.dragGroup) { return this.dragGroup.getOtherOnView(p) }   /** OR override */
    return [] /** It is assumed that there cannot be more than one product in the same table. */
  }

  getSearchers() {
    let csComponents: ClassSearcherComponent<M_Product>[] = [];
    this.searchers.forEach(s => {
      if (s.instance.classSearcher) {
        csComponents.push(s.instance.classSearcher);
      }
    })
    return csComponents;
  }

  getAllSearchersOf(p: M_Product): M_Product[] {

    if (this.dragGroup) { return this.dragGroup.getClassSearcherOf(p) }   /** OR override */

    let classSearcherP: M_Product[] = []
    this.getSearchers().forEach(searcher => {
      const classSearcherProduct = searcher.allData.find(product => product.product_id == p.product_id);
      if (classSearcherProduct) {
        classSearcherP.push(classSearcherProduct);
      }
    })
    return classSearcherP;
  }

  get isInterno() {
    return this.parent instanceof DragGroupComponent && this.parent.group.type.interno;
  }

  get hasChanges() {
    return this.products.some(p => p.line_hasChanges);
  }

  clearUnsaved() {
    this.products.forEach(p => p.line_hasChanges = false)
  }

  /** Sync the other products on the current view */
  onEditProduct(product: M_Product) {

    let invoicedProducts: M_Product[] = []; // Already invoiced products (or and budget)
    let otherProducts: M_Product[] = []; //The rest of products
    otherProducts.push(...this.getAllSearchersOf(product));

    /** OR and Budget override */
    if (this.dragGroup && this.dragGroup.dg) {
      this.dragGroup.dg.groupsComponents?.forEach(g => {
        g.group.products.forEach(p => {
          if (p instanceof M_Product && p.product_id == product.product_id) {
            if (g.isGroupInvoiced()) { invoicedProducts.push(p); }
            else { otherProducts.push(p); }
          }
        })
      })
    }
    else {
      this.parent.productLineTable.products.forEach(p => {
        if (p instanceof M_Product && p.product_id == product.product_id) {
          otherProducts.push(p);
        }
      })
    }

    /** For the invoiced products, only can edit the stock. The rest of the attributes need to be the same */
    invoicedProducts.forEach(p => { p.stock = product.stock });
    /** The other products (editable products) need to change the core attributes after the edition */
    otherProducts.forEach(p => { p.copyCoreAttributes(product) });
  }

  canDeleteProduct(p: M_Product | M_CustomProduct) {
    return !this.isLineBlocked(p) && (this.canDeleteLastProduct || (!this.canDeleteLastProduct && this.products.length > 1));
  }

  destroy(p: M_Product | M_CustomProduct) {
    this.parent.removeProduct(p);
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  canEnterList(drag: CdkDrag<M_Product | M_CustomProduct>, drop: CdkDropList<(M_Product | M_CustomProduct)[]>) {
    let p: (M_Product | M_CustomProduct) = drag.data;
    /** If the drag item is on a different drop list */
    if (drag.dropContainer.id != drop.id && p instanceof M_Product) {
      let hasSameProduct = drop.data.find(prod => prod instanceof M_Product && p instanceof M_Product && prod.product_id == p.product_id) != undefined;
      return !hasSameProduct && !drag.disabled;
    }
    return !drop.disabled;
  }

  drop(event: CdkDragDrop<(M_Product | M_CustomProduct)[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      if (this.dragGroup) {
        let task = event.container.data[event.currentIndex];
        if (task.task_id) {
          this.apiS.action.sortTask(this.dragGroup.group.products.filter(p => p.task_id != undefined).map(p => p.task_id!)).then(res => { })
        }
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

      if (this.dragGroup) {
        let task = event.container.data[event.currentIndex];
        task.group_id = this.dragGroup.group.id;
        if (task.task_id) { this.apiS.action.changeTask(task.task_id, task.group_id).then(res => { }) }
      }
    }
  }

  /** Pagination */
  updatePagination() {
    if (!this.itemsPerPage) { return; }
    this.totalPages = Math.ceil(this.products.length / this.itemsPerPage);
    this.generatePaginationArray();
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
  }

  generatePaginationArray() {
    this.paginationArray = Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }


  get isSomeProductRequested(): boolean {
    return this.products.some(p => this.isRequested(p))
  }

  get showLineActions() {
    return this.extendedInfoConfig && this.extendedInfoConfig.showColumn && this.canModifyTable && this.companyS.recambiosModule
  }

  get isInvoice() {
    return this.parent instanceof CreateInvoiceComponent
  }

  get isBudget() {
    return this.parent instanceof DragGroupComponent && this.parent.dg.action?.isBudget();
  }

  get isOr() {
    return this.parent instanceof DragGroupComponent && this.parent.dg.action?.isOr();
  }

  get isAlbaran() {
    return this.parent instanceof CreateEditAlbaranComponent;
  }

  get movTypeByParent(): MovTypeEnum | undefined {
    if (this.isOr) { return MovTypeEnum.OR }
    if (this.isBudget) { return MovTypeEnum.RP }
    if (this.isInvoice) { return MovTypeEnum.FR }
    if (this.isAlbaran) { return MovTypeEnum.AC }
    return undefined;
  }

}
