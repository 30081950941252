import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { ParamsService } from 'src/app/services/params.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { DialogCheckoutControlComponent } from './dialog-checkout-control/dialog-checkout-control.component';
import { InvoicePayementFormComponent } from 'src/app/components/invoice-payement-form/invoice-payement-form.component';
import { TypePaymentService } from 'src/app/services/type-payment-service';
import { FiltersComponent } from 'src/app/components/filters/filters.component';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { SliderFilter, FilterOption, Filter, DayFilter, TagFilter } from 'src/app/custom-classes/Filter';
import { invoice_states_payed, invoice_states_partial, invoice_states_pending, invoice_states } from 'src/app/custom-classes/invoice_states';
import { downloadBlob, toMoney } from 'src/app/utils/FunctionUtils';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { RouterService } from 'src/app/services/router.service';
import { INVOICE_TYPE_FILTER, OR_TYPE_FILTER, TYPE_PAYMENT_FILTER } from 'src/app/constants/SharedFilters';
import { endpoints } from 'src/app/constants/Endpoints';

export enum BillPageFiltesrEnum {
  INVOICE_TYPE = 0,
  OR_TYPE = 1,
  INVOICE_TYPE_PAYMENT = 2,
  INVOICE_STATE = 3,
  INVOICE_CREATED_AT = 4,
  INVOICE_PAYED_AT = 5,
}

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Invoice>;
  @ViewChild(FiltersComponent) filters?: FiltersComponent;
  @ViewChildren(InvoicePayementFormComponent) formChildren?: QueryList<InvoicePayementFormComponent>;

  v = ViewPath;
  e = endpoints;
  f = filter;
  checkbox: boolean = false;
  invoicedToday = 0;
  buttonDisabled: boolean = false;
  bills: M_Invoice[] = [];
  total: number | undefined;
  importValue: string = '';
  hasDraft: boolean = false;
  isCircleLoading = true;
  isDraft: boolean = false;
  maxInvoicedValue = 100;
  showForm: boolean = false;
  sliderFilter = new SliderFilter("Total de la factura", this.maxInvoicedValue);
  payment_name: FilterOption[] = [];
  invoice_state_payed = invoice_states_payed;
  invoice_state_partial = invoice_states_partial;
  invoice_state_pending = invoice_states_pending;

  filtersToShow: Filter[] = [
    new DayFilter("Fecha de creación").setId(BillPageFiltesrEnum.INVOICE_CREATED_AT),
    new DayFilter("Fecha de cobro").setId(BillPageFiltesrEnum.INVOICE_PAYED_AT),
    this.sliderFilter,
    new TagFilter("Estado de la factura", invoice_states, new FilterOption("Pendiente"), new FilterOption("Parcial"), new FilterOption("Cobrada")).setId(BillPageFiltesrEnum.INVOICE_STATE),
    INVOICE_TYPE_FILTER.setId(BillPageFiltesrEnum.INVOICE_TYPE),
    OR_TYPE_FILTER().setId(BillPageFiltesrEnum.OR_TYPE),
    TYPE_PAYMENT_FILTER().setId(BillPageFiltesrEnum.INVOICE_TYPE_PAYMENT)
  ]
  constructor(public apiS: ApiService, public paramsS: ParamsService, private d: MatDialog, private chdRef: ChangeDetectorRef,
    private routerS: RouterService, public subS: SubscriptionService,
    private confirmDialog: ConfirmDialogService, private typePaymentS: TypePaymentService) {
  }

  ngOnInit(): void {
    let a = this.apiS.bills();
    let b = this.apiS.hasDraft();
    let c = this.typePaymentS.getTypePayments();
    forkJoin([a, b, c]).subscribe(response => {
      this.bills = response[0];
      this.calculateMaxAndInvoicedToday(this.bills);
      this.ps.initTable(this.bills);
      this.isDraft = response[1] != true;
      this.isCircleLoading = false;
      this.chdRef.detectChanges();
    })
  }

  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == BillPageFiltesrEnum.INVOICE_STATE);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  async exportInvoices(invoices: any) {
    this.apiS.genericExport("FACTURAS", endpoints.exportInvoices, invoices);
  }

  visibilty() {
    this.showForm = !this.showForm;
  }

  calculateMaxAndInvoicedToday(invoices: M_Invoice[]) {
    var today = new Date();
    invoices.forEach(i => {

      if (i.total > this.maxInvoicedValue) {
        this.maxInvoicedValue = i.total;
      }

      if (i.created_at?.isEquals(today)) {
        this.invoicedToday += i.total;
      }
    })
    this.sliderFilter.changeMax(this.maxInvoicedValue);
  }
  resetPaymentForm() {
    this.formChildren?.forEach(e => {
      e.resetForm();
    });
  }

  invoiceIcon(b: M_Invoice) {
    return b.icon;
  }

  payedInvoice() {
    this.apiS.postPayedInvoice(this.ps.selected!.id!).then(res => {
      this.ps.selected!.state = invoice_states_pending;
    });
  }

  donwloadInvoices(invoices: any[]) {
    var dialogdata = {
      title: invoices.length == 1 ?
        "Descargar factura" :
        "Descargar facturas",
      body: invoices.length == 1 ?
        "¿Está seguro de que quiere descargar la factura?" :
        "¿Está seguro de que quiere descargar las " + invoices.length + " facturas?"
    }

    this.confirmDialog.show(dialogdata).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.downloadInvoices(invoices).then(res => {
          if (res) {
            var downloadName = "FACTURAS - " + new Date().dayMonthYearFormat();
            downloadBlob(res, downloadName);
          }
        });
      }
    })
  }

  getClientName(bill: M_Invoice) {
    return bill.client?.getName();
  }

  getClientId(bill: M_Invoice) {
    return bill.client?.company_scope_id;
  }

  getTotal(bill: M_Invoice) {
    let finalValue = bill.isAbono ? -bill.total : bill.total;
    return toMoney(finalValue);
  }

  getType(bill: M_Invoice) {
    var type = bill.type_invoice?.name;
    var tOr = bill.type_or?.name
    return type + (tOr ? (' (' + tOr + ")") : '')
  }

  getStatustName(bill: M_Invoice) {
    return bill.state.isPayed ? "Pagada" : "Pendiente"
  }

  makeAbonoInvoice(b: M_Invoice) {
    this.routerS.goWithQueryParams(ViewPath.abono, { invoice: b.id });
  }

  setPayedStauts(b: M_Invoice) {
    this.confirmDialog.show(
      {
        title: b.isAbono ? "Marcar abono como pagado" : "Marcar factura como cobrada",
        body: b.isAbono ? "¿Seguro que quieres marcar el abono como pagado?" : " ¿Seguro que quieres marcar la factura como cobrada?"
      })
      .afterClosed().subscribe(res => {
        if (res) {
          this.apiS.setPayedStatus(b.id!).then(_res => {
            b.state = invoice_states_payed;
            this.chdRef.detectChanges();
          })
        }
      })
  }

  stateChange(event: MatSelectChange) {
    this.apiS.changeStateInvoice(this.ps.selected!, event.value).then(res => {
      this.ps.selected!.state = event.value;
    });
  }

  isSelected() {
    return this.ps.selected!.state;
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    const inputValue = event.target.value + String.fromCharCode(event.charCode);

    // Si el valor no cumple con la expresión regular o está vacío, deshabilita el botón
    if (!reg.test(inputValue) || inputValue.trim() === '') {
      event.preventDefault();

    }
  }

  openDialog() {
    this.d.open(DialogCheckoutControlComponent, { data: { invoices: this.ps.data, type_payments: this.typePaymentS.typePaymentArray } }).afterClosed().subscribe(res => {

    })
  }

}

