import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reserve-budget',
  templateUrl: './reserve-budget.component.html',
  styleUrls: ['./reserve-budget.component.css']
})
export class ReserveBudgetComponent {
  @Input() reserve ?: boolean = false
  label_name : string | undefined ;
  constructor(){}
  ngOnInit(): void {
    if (this.reserve) {
        this.label_name = "RESERVADO";
      }
      else{
        this.label_name = "DISPONIBLE";
    }
  }
}
