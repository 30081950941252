import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from '../../models/M_Contact';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { validadorPorcentaje } from '../../validators/percentage';
import { Country } from '@angular-material-extensions/select-country';
import { COUNTRY_ESP } from '../../constants/constants';
import { M_AdminCenters } from '../../models/M_AdminCenters';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ContactEnum } from '../../enums/ContactEnum';
import { ModulesEnum } from '../../enums/ModulesEnum';
import { M_ShippingAddress } from '../../models/M_Address';
import { ExtraAddressService } from '../../services/extra-address.service';
import { DialogConfirmPlateComponent } from '../vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { MatDialog } from '@angular/material/dialog';
import { R_Zip } from '../../models/R_Zip';
import { MatSelectChange } from '@angular/material/select';
import { CompanyService } from '../../services/EinaMainData/company.service';
import { ClientTypeEnum } from '../../enums/ClientTypeEnum';
import { dniNifAsync } from '../../validators/dniNifAsyncValidator';
import { cifValidator } from 'src/app/validators/cif-validator';
import { softNifValidator } from 'src/app/validators/nif-validator';
import { DniInputComponent } from '../inputs/dni-input.component';
import { RouterService } from 'src/app/services/router.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { ClientPaymentMethodEnum } from 'src/app/enums/ClientPaymentMethodEnum';
import { swiftValidator } from 'src/app/validators/swift-bic-validator';
@Component({
  selector: 'app-clientform',
  templateUrl: './clientform.component.html',
  styleUrls: ['./clientform.component.css']
})
export class ClientformComponent {

  me = ModulesEnum;
  cte = ClientTypeEnum;
  contactEnum = ContactEnum
  validators = Validators;
  public form: UntypedFormGroup;
  lastSurname = "";
  lastDob: Date | undefined;

  /*Input y Outputs*/
  @Input() edit = false;
  @Input() onlyRequired = false;
  @Input() requiredFieldsTitle?: string
  @Input() clientType?: number;
  /** Force to create on type of contact */
  @Input() forceType: ContactEnum | undefined;
  @Input() canSelectClient = false;
  @Input() isOnDialog = false;
  @ViewChild(DniInputComponent) dniComponent!: DniInputComponent;
  @ViewChild("eInvoice") eInvoiceToggle!: MatSlideToggle;
  @Output() onClientExists: EventEmitter<M_Contact> = new EventEmitter();

  COUNTRY_ESP = COUNTRY_ESP;
  CPM = ClientPaymentMethodEnum;
  v = ViewPath;
  today = new Date()
  adminCenterError: boolean = false
  displayedColumns: string[] = ['post', 'code', 'name', 'deleted'];
  isEditing: boolean = false;
  Iscreate: boolean = false;
  initialInputValue: string = "";
  loadingZip: boolean = false;
  personalizadoSelected = false;
  arrayZips: R_Zip[] | undefined = [];
  finalsearh = "";
  selectedPayment: any;
  currentContact: M_Contact | undefined;

  constructor(private formBuilder: UntypedFormBuilder, private existsS: AlreadyExistsService, private apiS: ApiService, private routerS: RouterService, public responsiveS: ResponsiveService, private confirmD: ConfirmDialogService, private extraAddressS: ExtraAddressService,
    public chdRef: ChangeDetectorRef, private d: MatDialog, public companyS: CompanyService) {
    this.form = this.formBuilder.group({
      nif: ['', {
        validators: [Validators.required],
        asyncValidators: [dniNifAsync(this.apiS, this.existsS, () => this.getCurrentContact(), () => this.dialogBehavior(), this.onClientExists)],
      }],
      name: ['', [Validators.required]],
      type: [ContactEnum.PERSON, [Validators.required]],
      c_client: [true, [Validators.required]],
      surname: ['', [Validators.required]],
      country: [COUNTRY_ESP, [Validators.required]],
      tax_free: [false, [Validators.required]],
      dob: [null, []],
      address: ['', []],
      address2: ['', []],
      zip: ['', []],
      city: ['', []],
      province: ['', []],
      extra_adress: this.formBuilder.array([]),
      phone: ['', []],
      phone2: ['', []],
      email: ['', []],
      countable_num: ['', []],
      observations: ['', []],
      email_always: [false, []],
      profile: [false, []],
      marketing: [false, []],
      newsletter: [false, []],
      discount: ['', [validadorPorcentaje()]],
      discount_cat: [0, []],
      company_id: ["", []],
      client_id: ["", []],
      
      account_num: [''],
      payment_method: [null],
      payment_term_1: [null],
      payment_term_2: [null],
      payment_term_3: [null],
      payment_term_4: [null],
      payment_term_5: [null],
      payment_day_1: [null, [Validators.min(1), Validators.max(31)]],
      payment_day_2: [null, [Validators.min(1), Validators.max(31)]],
      payment_day_3: [null, [Validators.min(1), Validators.max(31)]],
      iban_prefix: [''],
      swift: ['', swiftValidator()],
      iban: ['', Validators.minLength(34)],

      cli_is_invoice_e: [false, [Validators.required]],
      clients_admincenters: this.formBuilder.array([])
    });
    this.form.get('zip')?.valueChanges.subscribe(v => {
      this.onZipChange(v);
      this.chdRef.detectChanges();
    })
    this.onContactTypeChange();
    this.onChangeEInvoiceToggle();

  }

  ngAfterViewInit() {
    this.onContactTypeChange();
    if (this.forceType != undefined) {
      this.form.patchValue({ type: this.forceType });
    }
  }

  get extraAddressSubForm() {
    return this.form.get('extra_adress') as FormArray<FormGroup>;
  }

  get adminCenterSubForm() {
    return this.form.get('clients_admincenters') as FormArray<FormGroup>;
  }

  getExtraAdress(i: number) {
    return this.extraAddressSubForm.controls[i];
  }

  getClientAdminCenter(i: number) {
    return this.adminCenterSubForm.controls[i];
  }

  addAdminCenter(admincenter?: M_AdminCenters) {
    let fg = new FormGroup({
      'id': new FormControl(admincenter?.id),
      'client_id': new FormControl(admincenter?.client_id),
      'company_id': new FormControl(admincenter?.company_id),
      'post': new FormControl(admincenter?.post, this.eInvoiceToggle.checked ? Validators.required : []),
      'code': new FormControl(admincenter?.code, this.eInvoiceToggle.checked ? Validators.required : []),
      'name': new FormControl(admincenter?.name, this.eInvoiceToggle.checked ? Validators.required : []),
      'deleted': new FormControl(admincenter ? admincenter.deleted : false),
      'status': new FormControl(false),
    });
    this.adminCenterSubForm.push(fg)
  }

  addExtraAdress(address?: M_ShippingAddress) {
    let fg = this.extraAddressS.generateAddresFormFroup(address);
    this.extraAddressSubForm.push(fg)
  }

  onChangeEInvoiceToggle() {
    this.form.get('cli_is_invoice_e')!.valueChanges.subscribe(val => {
      this.refreshAdminCentersValidators();
    })
  }

  sellsInfoDialog() {
    this.confirmD.show({
      title: '¿Ventas?',
      body: ["Si activas las ventas de un contacto, le podrás realizar facturas, OR, presupuestos...",
        "Es decir, que la aplicación lo considerará un cliente.",
        "¡Es como agregar un turbo a tu negocio!"],
      confirmTxt: "¡Aceleremos!",
      showCancel: false,
      type: "info"
    })
  }

  refreshAdminCentersValidators() {
    this.adminCenterSubForm.controls.forEach(ac => {
      let controlsArray = [ac.get('post')!, ac.get('code')!, ac.get('name')!];
      controlsArray.forEach(c => {
        if (this.eInvoiceToggle.checked) { c.addValidators(Validators.required) }
        else { c.removeValidators(Validators.required) }
        c.updateValueAndValidity();
      });
    })
  }

  getCurrentContact() {
    return this.currentContact;
  }

  dialogBehavior() {
    return { canSelectClient: this.canSelectClient }
  }

  onSelectExistingClient(c: M_Contact) {
    this.onClientExists.emit(c);
  }

  removeExtraAdress(id: number) {
    this.extraAddressSubForm.removeAt(id);
  }

  removeAdminCenter(id: number) {
    const index = this.adminCenterSubForm.controls.findIndex(control => control.get('id')?.value === id);
    this.adminCenterSubForm.removeAt(index);
  }

  openAdminCenterInfo() {
    this.confirmD.show({
      title: "Centros administrativos",
      body: "Son códigos necesarios para emitir facturas electrónicas a entidades públicas. Estos códigos identifican los departamentos destinatarios de las facturas. Se pueden obtener consultando a las administraciones o en sus páginas web. Los datos se pueden registrar en la ficha de datos de los clientes y se trasladan automáticamente a las facturas, aunque se pueden seleccionar diferentes códigos para cada factura si es necesario.",
      showCancel: false,
      type: "info",
      confirmTxt: "Ok"
    })
  }
  onZipChange(searched: string) {

    this.finalsearh = searched;
    let zipControl = this.form.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {

        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length === 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          let selectedZip = this.arrayZips?.find(zip => zip.id == this.form.get('city')?.value);
          if (selectedZip) {
            this.markZipAsValid(zipControl, selectedZip)
          }
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }
  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    this.form.patchValue({ 'city': model.id })
    this.form.patchValue({ 'province': model.provincia })
    this.form.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }
  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.form.patchValue({ 'city': undefined })
    this.form.patchValue({ 'province': undefined })
    this.form.patchValue({ 'ccaaObject': undefined })
    this.form.patchValue({ 'ccaa': undefined })
  }
  enableEditing() {
    this.isEditing = true;
    this.form.get('nif')?.enable();
    this.initialInputValue = this.form.get('nif')?.value || ''; // Guardar el valor inicial
    if (this.edit == true) {
      this.d.open(DialogConfirmPlateComponent, {
        data: {
          title: "¡Atención Edición de NIF!",
          message: "¿Estas seguro que quieres editar el NIF?",
        }
      }).afterClosed().subscribe(res => {
        if (res != true) {
          this.form.get('nif')?.disable();

        } else {
          this.edit = true;
        }
      }
      );
    }
  }
  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;

    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );

    let zipControl = this.form.get('zip')!;

    if (selectedValue) {
      this.markZipAsValid(zipControl, this.selectedPayment);
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    } else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }
    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }
    this.form.get('city')?.updateValueAndValidity();
  }

  get getIdentifierPlaceholder() {
    if (this.isSpanish) {
      return this.isCompany || this.isProvider ? "Introduce el CIF / NIF" : "Introduce el DNI / NIF"
    }
    else {
      return "Identificador"
    }
  }

  ngOnChanges() {
    if (this.edit) {
      this.form.get('nif')?.disable();
    }
  }

  onCountrySelected(c: Country) {
    this.form.get('tax_free')?.setValue(!this.isSpanish);
    this.adaptValidators();
  }

  onContactTypeChange() {
    this.form.get('type')!.valueChanges.subscribe((val: ContactEnum) => {

      let surname = this.form.get('surname')!;
      let dob = this.form.get('dob')!;

      if (val == ContactEnum.COMPANY || val == ContactEnum.PROVIDER) {
        this.lastSurname = surname.value;
        surname.reset();
        surname.disable();
        if (dob.value) {
          this.lastDob = new Date(dob.value);
        }
        dob.reset();
        dob.disable();
      }
      else {
        surname.enable();
        surname.patchValue(this.lastSurname);
        dob.enable();
        dob.patchValue(this.lastDob?.datePickerFormat());
      }
      if (!this.edit) {
        this.changeIsClient(val != ContactEnum.PROVIDER);
      }
    })

    this.adaptValidators();
  }

  changeIsClient(active: boolean) {
    this.form.get('c_client')?.patchValue(active);
  }

  adaptValidators() {
    let dniInput = this.form.get('nif')!;
    dniInput?.clearValidators();

    if (this.isSpanish) {
      if (this.isCompany) {
        dniInput.addValidators([this.nifOrCifValidator(), Validators.required])
      }
      else {
        dniInput.addValidators([softNifValidator(), Validators.required])
      }
    }
    else {
      dniInput.addValidators([Validators.required]);
    }

    dniInput.updateValueAndValidity();
  }

  nifOrCifValidator(): ValidatorFn {
    return softNifValidator() || cifValidator();
  }

  get isClient() {
    return this.form.get('c_client')!.value == true;
  }

  get isPerson(): boolean {
    return this.form.get('type')!.value == ContactEnum.PERSON;
  }

  get isCompany(): boolean {
    return this.form.get('type')!.value == ContactEnum.COMPANY;
  }

  get isProvider(): boolean {
    return this.form.get('type')!.value == ContactEnum.PROVIDER;
  }

  get isSpanish() {
    return this.form.get('country')?.value?.numericCode == COUNTRY_ESP.numericCode;
  }

  get someBillingAdressIsFilled() {
    return !this.isEmpty('address') || !this.isEmpty('address2') || !this.isEmpty('zip') || !this.isEmpty('city') || !this.isEmpty('province');
  }

  isEmpty(formControlName: string) {
    let formValue = this.form.get(formControlName)?.value;
    return formValue == undefined || formValue == null || formValue == '';
  }

  goDiscountGroups() {
    this.routerS.goTo(ViewPath.discountGroups);
  }

  isD(contactType: ContactEnum) {
    return this.forceType != undefined && this.forceType != contactType;
  }
}
