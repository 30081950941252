<form [formGroup]="form" eForm>
    <app-card-subtitle>{{requiredFieldsTitle ? requiredFieldsTitle : 'Datos básicos'}}</app-card-subtitle>
    <div class="onboarding-vehicle-required">
        <div eForm-wrapper>


            <div eForm-section>
                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type" [(ngModel)]="selectedType" (selectionChange)="onTypeChange($event)">
                        <mat-option [value]="1">Moto</mat-option>
                        <mat-option [value]="2">Coche</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Matricula</mat-label>
                    <app-async-validator-spinner matSuffix
                        [control]="form.get('license')"></app-async-validator-spinner>
                    <input matInput placeholder="Matricula" [disabled]="licenseBlocked" formControlName="license" />
                    <button *ngIf="licenseBlocked" mat-icon-button matPrefix type="button"
                        (click)="enablePlateEdition()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                        La matrícula ya existe.
                    </mat-error>
                </mat-form-field>
            </div>

            <div eForm-section>
                <app-brands-models-series #brandsModelSerie [formVnVo]="false" [type]="selectedType" [filterAuto]="true" [notShowSerie]="!companyS.vnvoModule"
                    [notRequiredSerie]="true" [form]="form">
                    <mat-form-field appearance="outline" *ngIf="companyS.vnvoModule">
                        <mat-label>Versión</mat-label>
                        <input matInput formControlName="version" placeholder="Código versión">
                    </mat-form-field>
                </app-brands-models-series>
            </div>
        </div>

        <div [ngTemplateOutlet]="imageSerieSelector" *ngIf="!companyS.vnvoModule"></div>

        <!-- Owner -->
        <div [dn_i]="vehicle != undefined">
            <app-card-subtitle>Propietario del vehículo</app-card-subtitle>
            <div eForm-section eForm-wrapper class="onboarding-vehicle-owner">
                <app-class-searcher #clientSearcher [masterClass]="M_CLIENT" [createData]="{
                    text : 'Crear cliente',
                    by : 'dialog'
                }">
                </app-class-searcher>
            </div>
        </div>

    </div>

    <p></p>

    <mat-tab-group color="primary" mat-stretch-tabs="true" class="vehicle-tabs" [ngClass]="{'dn' : onlyRequired}">

        <!-- FICHA -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>swap_driving_apps_wheel</mat-icon>
                Ficha
            </ng-template>

            <app-card-subtitle>Ficha</app-card-subtitle>
            <div eForm-wrapper>
                <div eForm-section>
                    <app-chassis-input [form]="form" [currentVehicle]="vehicle" [required]="false"></app-chassis-input>
                    <mat-form-field appearance="outline">
                        <mat-label>Kilómetros</mat-label>
                        <input type="number" matInput formControlName="km">
                    </mat-form-field>
                </div>

                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Matriculación</mat-label>
                        <input placeholder="dd/mm/aaaa" #pickerInput matInput [matDatepicker]="picker" [max]="minDate"
                            (click)="picker.open()" formControlName="license_date">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Última ITV</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_1" [max]="minDate"
                            (click)="picker_1.open()" formControlName="last_itv">
                        <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                        <mat-datepicker #picker_1 [min]="minDate"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Próxima ITV</mat-label>
                        <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_2" [min]="minDate"
                            (click)="picker_2.open()" formControlName="next_itv">
                        <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                        <mat-datepicker #picker_2></mat-datepicker>
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Fin de garantía</mat-label>
                        <input matInput formControlName="end_warranty" [matDatepicker]="pickerEndWarranty">
                        <mat-datepicker-toggle matSuffix [for]="pickerEndWarranty"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEndWarranty></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Número de garantía</mat-label>
                        <input matInput formControlName="number_warranty" placeholder="Número de garantía">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <mat-form-field appearance="outline" class="w100">
                        <mat-label>Observaciones</mat-label>
                        <textarea matInput formControlName="observations" cdkTextareaAutosize cdkAutosizeMinRows="2"
                            cdkAutosizeMaxRows="5" [placeholder]="'Observaciones'">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <!-- Motor -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>construction</mat-icon>
                Motor
            </ng-template>
            <app-card-subtitle>Motor</app-card-subtitle>
            <div eForm-wrapper>


                <div eForm-section>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de motor</mat-label>
                        <mat-select formControlName="engine_type" #et>
                            <mat-option [value]="0">Gasolina</mat-option>
                            <mat-option [value]="1">Eléctrico</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Nº serie batería </mat-label>
                        <input matInput placeholder="Nº serie batería" formControlName="battery_1_no">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Nº serie batería extra</mat-label>
                        <input matInput placeholder="Nº serie batería extra" formControlName="battery_2_no">
                    </mat-form-field>
                </div>
                <div eForm-section>
                    <div eForm-section>
                        <mat-form-field appearance="outline">
                            <mat-label>Cilindrada</mat-label>
                            <input type="number" matInput placeholder="Cilindrada" formControlName="cc">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Tipo de cambio</mat-label>
                        <input matInput formControlName="gears_type" placeholder="Tipo de cambio">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <!-- SELLS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>sell</mat-icon>
                Ventas
            </ng-template>

            <app-card-subtitle>Ventas</app-card-subtitle>
            <div eForm-section eForm-wrapper>
                <app-class-searcher #csearcher [masterClass]="client" class="w100" [width100]="true" [form_]="form"
                    [formCname]="'clientInvoice_id'" searchPlaceHolder="Cliente a facturar por defecto"
                    [extendedInfo]="false" [required]="false" [createData]="{
                        text : 'Nuevo cliente / empresa',
                        by : v.createContact,
                    }">
                </app-class-searcher>
            </div>
        </mat-tab>

    </mat-tab-group>
</form>


<!-- NO VNVO MODULE SERIE SEELCTOR -->
<ng-template #imageSerieSelector>
    <div class="df fww jcc">
        <!-- CAR TYPE -->
        <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
            *ngIf="showMe(VT.car)">
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m1.png" text="Microcoche"
                [formValue]="0"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m2.png" text="Urbano"
                [formValue]="1"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m3.png" text="Sedan"
                [formValue]="2"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m4.png" text="Descapotable"
                [formValue]="3"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m5.png" text="Coupé"
                [formValue]="4"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m6.png" text="Deportivo"
                [formValue]="5"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m7.png" text="Monovolúmen"
                [formValue]="6"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m8.png" text="Todo Terreno"
                [formValue]="7"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m9.png" text="SUV"
                [formValue]="8"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m10.png" text="Camioneta"
                [formValue]="9"></app-img-toggle-item>
        </app-image-toggle>

        <!-- MOTO TYPE -->
        <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
            *ngIf="showMe(VT.bike)">
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m1.png" text="Sport"
                [formValue]="0"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m2.png" text="Touring"
                [formValue]="1"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m3.png" text="Trail"
                [formValue]="2"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m4.png" text="Scooter"
                [formValue]="3"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m5.png" text="Offroad"
                [formValue]="4"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m6.png" text="Custom"
                [formValue]="5"></app-img-toggle-item>
            <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m7.png" text="Naked"
                [formValue]="6"></app-img-toggle-item>
        </app-image-toggle>
    </div>
</ng-template>