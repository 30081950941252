<app-page-structure [pageTitle]="'Grupos de descuento'" 
    [goBackCustom]="{
        view:v.products
    }"
    [listTitleText]="'Grupos de descuento'"
    [displayedHeader]=" ['Identificador',   'Nombre',       'Número de líneas']"
    [displayedColumns]="['id',              'name',         'lines_count']"
    [cellZize]="        ['small',           'big',          undefined]"
    [specialText]="     [undefined,         undefined,      undefined   ]"
    [specialClass]="    [undefined,         undefined,      undefined]"
    [preIcon]="         [undefined,         undefined,      undefined]"
    [createButton]="{
        icon: 'heap_snapshot_multiple',
        text: 'Crear grupo de descuento',
        view: v.createDiscountGroup,
    }"
    (onclickRow)="paramsS.go(v.createDiscountGroup, $event.id)">
</app-page-structure>