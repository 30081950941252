import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/Api/api.service';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_Product_Move_Recambios } from 'src/app/models/M_Product_Move_Recambios';
import { M_User } from 'src/app/models/M_User';
import { PorducMoveFactorEnum } from 'src/app/enums/PorducMoveFactorEnum';

@Component({
  selector: 'app-moves-detail-dialog',
  templateUrl: './moves-detail-dialog.component.html',
  styleUrls: ['./moves-detail-dialog.component.css']
})
export class MovesDetailDialogComponent {
  user: M_User | undefined
  provider: M_Contact | undefined
  loaded: boolean = false
  moveEnum = PorducMoveFactorEnum;

  constructor(public dialogRef: MatDialogRef<MovesDetailDialogComponent>, @Inject(MAT_DIALOG_DATA) public pm: M_Product_Move_Recambios, public apiS: ApiService) {
    Promise.all([this.loadUserData(), this.loadProviderData()]).then(() => {
      this.loaded = true;
    })
  }

  async loadUserData() {
    if (this.pm.user) {
      try {
        const res = await this.apiS.getUserById(this.pm.user);
        this.user = res;
      } catch (error) {
        console.error("Error loading user data:", error);
      }
    }
  }

  loadProviderData() {
    try {
      if (this.pm.user) {
        this.apiS.getClientById(this.pm.provider_id!).then(res => {
          this.provider = res;
        });
      }
    } catch (error) {}
  }
}
