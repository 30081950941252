
import { AfterViewInit, Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Contact } from 'src/app/models/M_Contact';
import { M_ProductProvider } from 'src/app/models/M_ProductProvider';
import { ParamsService } from 'src/app/services/params.service';
import { MatPaginator } from '@angular/material/paginator';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-providers-details-product-table',
  templateUrl: './providers-details-product-table.component.html',
  styleUrls: ['./providers-details-product-table.component.css']
})

export class ProvidersDetailsProductTableComponent implements AfterViewInit {

  v = ViewPath;
  @Input() client!: M_Contact;
  displayedColumns = ['reference', 'name', 'buy_price', 'stock'];
  dataSource: MatTableDataSource<M_ProductProvider> = new MatTableDataSource<M_ProductProvider>([]);
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  
  constructor(public paramS: ParamsService, public responsiveS : ResponsiveService, public routerS: RouterService) { }

  ngAfterViewInit() {    
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.client.products_providers;
    this.updateDisplayedColumns();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
     this.updateDisplayedColumns();
  }

  updateDisplayedColumns(): void {
    if (this.responsiveS.w < 500) {
      this.displayedColumns = ['name', 'buy_price'];
    } else    if (this.responsiveS.w < 750) {
      this.displayedColumns = ['name', 'reference', 'stock'];
    } else {
      this.displayedColumns = ['name', 'reference', 'buy_price', 'stock'];
    }
  }

  get displayedColumnsByScreenSize(){
    return this.responsiveS.w > 500 ? this.displayedColumns : this.displayedColumns.slice(0,this.displayedColumns.length-1);
  }

  goProduct(row: M_ProductProvider) {
    if (row !== undefined) {
      this.paramS.go(ViewPath.productDetails, row.product?.product_id!);
    }
  }

}
