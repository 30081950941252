import { Component, Input, TemplateRef } from '@angular/core';
import { getRandomNoDataMessageMessage } from 'src/app/constants/constants';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { getAccentColor } from 'src/app/utils/FunctionUtils';

/** The core card component */

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {

  /** Card padding ? */
  @Input() nopadding: boolean = false;
  /** If undefined or true the card is loaded, else card shows loading skeleton */
  @Input() contentLoaded?: boolean;
  /** Show a padlock on top of the card. If locked, the user canno't interact with the card. See also the 'lockedText' property*/
  @Input() locked: string | boolean | undefined
  /** Lock the card if true. Put the locked text as 'Se requiere el plan PRO'? */
  @Input() userNeedSubscription = false;
  /** Show a lord-icon with a title of 'Sin datos' */
  @Input() noData?: boolean;
  /** Remove all the card styles and classes */
  @Input() noStyle = false;
  /** Set the component on the top right of the card. Typically used the "more_vert" icon */
  @Input() cornerMatMenu?: TemplateRef<any>;
  /** Not implemented yet */
  @Input() darkmode = false;
  /** Custom Skeleton loader  */
  @Input() customSkeleton?: TemplateRef<any>;

  noDataMsg = getRandomNoDataMessageMessage()
  accent = getAccentColor;

  constructor(public subS: SubscriptionService) { }

  showNoData() {
    return (this.contentLoaded == undefined || this.contentLoaded == true) && this.noData
  }

  get isSubscriptionLocked() {
    return this.userNeedSubscription && this.subS.isBasic;
  }

  get lockedText() {
    if (this.isSubscriptionLocked) {
      return this.subS.proPlanRequiredTxt;
    }
    else if (typeof this.locked == "string") {
      return this.locked;
    }
    return undefined;
  }

  lockedAction() {
    if (this.isSubscriptionLocked) {
      this.subS.openTryProDialog();
    }
  }
}
