import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SessionService } from "../services/session.service";
import { projectConfiguration } from "../app.module";

@Injectable({
  providedIn: 'root'
})
export class AuthSharedAccesGuard {

  constructor(private sessionS: SessionService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.sessionS.hasSession(route)) {
      this.router.navigate([projectConfiguration.multiUserLanding.path]);
      return false;
    }
    return true;
  }
}
