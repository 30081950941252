<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" color="primary" [selectedIndex]="selectedTab"
    (selectedTabChange)="tabChanged($event)" class="settings-tabs">

    <!-- GENERAL -->
    <mat-tab [labelClass]="'onboarding-company-genera-tab'">
        <ng-template mat-tab-label>
            Empresa
        </ng-template>
        <app-company-tab></app-company-tab>
    </mat-tab>

    <!-- CENTERS -->
    <mat-tab [labelClass]="'onboarding-company-centers-tab'">
        <ng-template mat-tab-label>
            Centros
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-centers></app-centers>
        </div>
    </mat-tab>

    <!-- TEAM -->
    <mat-tab [labelClass]="'onboarding-company-users-tab'">
        <ng-template mat-tab-label>
            Equipo
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-team></app-team>
        </div>
    </mat-tab>

    <!-- SUBSCRIPTION -->
    <mat-tab [labelClass]="'onboarding-company-subscription-tab'">
        <ng-template mat-tab-label>
            Subscirpción
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-subscription></app-subscription>
        </div>
    </mat-tab>

    <!-- MODULES -->
    <mat-tab [labelClass]="'onboarding-company-module-tab'">
        <ng-template mat-tab-label>
            Módulos e integraciones
        </ng-template>
        <div class="max-width df jcc dfc">
            <app-module-selector></app-module-selector>
        </div>
    </mat-tab>

</mat-tab-group>