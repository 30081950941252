import { invoice_states, invoice_states_paid, invoice_states_partial, invoice_states_payed, invoice_states_pending } from "../custom-classes/invoice_states";
import { TypePurchase } from "../enums/TypePurchase";
import { IPageStructureItem } from "../interfaces/IPageStructureItem";
import { match } from "../services/search.service";
import { getArrayOf } from "../utils/FunctionUtils";
import { M_Contact } from "./M_Contact";
import { M_LastMovements } from "./M_LastMovement";
import { M_User } from "./M_User";
import { M_Vehicle } from "./Vehicles/M_Vehicle";

export class M_Purchase implements IPageStructureItem {
  id?: number;
  user?: M_User;
  date?: Date | undefined;
  contact?: M_Contact;
  vehicle?: M_Vehicle;
  pdf_cv?: string;
  pdf_fc?: string;
  tax?: number;
  docum_prov_num: string;
  total?: number;
  type: TypePurchase;
  private _pendingImport: number | undefined;
  buy_num: number;
  num_purchase: string;
  client_id: number | undefined;
  vehicle_id: number | undefined;
  last_movement: M_LastMovements[] = [];
  created_at: Date | undefined;
  state: invoice_states
  constructor(d: any) {
    this.id = d.id;
    if (d.user) {
      this.user = new M_User(d.user);
    }
    this.date = d.date ? new Date(d.date) : undefined;
    this.created_at = d.created_at ? new Date(d.created_at) : undefined;
    this.state = d.state == 'created' ? invoice_states_pending : d.state == 'partial' ? invoice_states_partial : d.state == 'paid' ? invoice_states_paid : invoice_states_payed;
    if (d.client) {
      this.contact = new M_Contact(d.client);
    }
    if (d.urlpdf) {
      this.pdf_cv = d.urlpdf;
    }
    if (d.pdffc) {
      this.pdf_fc = d.pdffc;
    }
    if (d.vehicle) {
      this.vehicle = new M_Vehicle(d.vehicle);
    }
    if (d.vehicle_id) {
      this.vehicle_id = d.vehicle_id;
    }
    if (d.client_id) {
      this.client_id = d.client_id;
    }
    if (d.last_movement) {
      this.last_movement = getArrayOf(M_LastMovements, d.last_movement);
    } else {
      this.last_movement = [];
    }
    this.tax = d.tax;
    this.total = d.total;
    this.docum_prov_num = d.docum_prov_num;
    this.type = d.type;
    this.buy_num = d.buy_num;
    this.num_purchase = d.num_purchase;

  }
  defaultSearchFilter(text: string): boolean {
    return match(text, this.buy_num, this.num_purchase);
  }
  get itemId(): number | undefined {
    return this.id;
  }
  clientName(): string | undefined {
    return this.contact?.getName();
  }
  get pending_import() {
    if (!this.total) { return 0 }
    let totalaux = this.total;
    this.last_movement.forEach((l) => {
      totalaux -= l.import;
    });

    this._pendingImport = Math.abs(totalaux.castDecimals(2));
    return this._pendingImport;
  }
  set SetPending_import(value: number) {
    this._pendingImport = value;
  }
  type_filter() {
    let filter_arrat_type: number[] = [];

    if (this.state.isPending) {
      filter_arrat_type.push(0);
    }
    if (this.state.isPartial) {
      filter_arrat_type.push(1);
    }
    if (this.state.isPayed) {
      filter_arrat_type.push(2);
    }
    return filter_arrat_type;
  }
  get isTotalPay() {
    if (this.state.isPending) {
      return false;
    }
    return this.total == 0;
  }
  get tagLabel() {
    return this.state.isPending ? 'PENDIENTE' : this.state.isPartial ? 'PARCIAL' : 'PAGADA';
  }
  get typeName() {
    let name = ""
    switch (this.type) {
      case 1:
        name = "Almacén"
        break;
      case 2:
        name = "Comercial"
        break;
      default:
        name = "Almacén"
        break;
    }
    return name;
  }
  get icon() {
    if (this.type) {
      return this.type == 2 ? 'sell' : 'archive';
    }
    return 'archive';
  }
  getTaxTotal(total: number, ivaPorcentaje: number) {

    const iva = total * (ivaPorcentaje / 100);
    return iva;
  }

  get taxSum() {
    let iva = 0;
    if (this.total && this.tax) {
      iva = this.total * (this.tax / 100);
    }
    return iva;
  }
}