import { ChangeDetectorRef, Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MovesDetailDialogComponent } from './moves-detail-dialog/moves-detail-dialog.component'
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PorducMoveFactorEnum } from 'src/app/enums/PorducMoveFactorEnum';
import { M_Product_Move_Recambios } from 'src/app/models/M_Product_Move_Recambios';
import { Filter, DayFilter, TagFilter, FilterOption } from 'src/app/custom-classes/Filter';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-produproductmove-recambios-module',
  templateUrl: './produproductmove-recambios-module.component.html',
  styleUrls: ['./produproductmove-recambios-module.component.css']
})
export class ProduproductmoveRecambiosModuleComponent {

  @Input() allStockMoves: M_Product_Move_Recambios[] = [];
  @Input() name: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  moveEnum = PorducMoveFactorEnum;
  displayedColumns: string[] = ['factor', 'transac_name', 'location', 'document', 'quanti_mov'];
  dataFiltrada: MatTableDataSource<M_Product_Move_Recambios> = new MatTableDataSource<M_Product_Move_Recambios>([]);

  filters: Filter[] = [
    new DayFilter("Fecha del movimiento"),
    new TagFilter("Tipo", undefined,
      new FilterOption("Sin movimiento", "equal"),
      new FilterOption("Entrada", "arrow_circle_right"),
      new FilterOption("Salida", "arrow_circle_left"))
  ];

  constructor(public dialog: MatDialog, public responsiveS: ResponsiveService, private cdref: ChangeDetectorRef) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateDisplayedColumns();
  }

  ngAfterViewInit(): void {
    this.discardZeroMovements();
    this.dataFiltrada.data = this.allStockMoves;
    this.dataFiltrada.paginator = this.paginator;
    this.onResize()
    this.cdref.detectChanges();
  }

  /** Discard the movements with 0 quantity*/
  discardZeroMovements() {
    this.allStockMoves.forEach(m => {
      if (m.quanti_mov != undefined && m.quanti_mov == 0) {
        this.allStockMoves.removeElement(m);
      }
    })
  }

  filter(filters: Filter[]) {
    this.dataFiltrada.data = [];
    let activeFilters = filters.filter(f => f.activated) //xd
    if (activeFilters.length) {
      this.allStockMoves.forEach((object: M_Product_Move_Recambios) => {
        let dayFilterOk = true;
        let tagFilterOk = true;

        filters.forEach(f => {
          if (f instanceof DayFilter && dayFilterOk) {
            dayFilterOk = f.checkFilter(object.created_at)
            if (dayFilterOk) {
              this.dataFiltrada.data.push(object);
            }
          }
          if (f instanceof TagFilter) {
            tagFilterOk = f.checkFilter([object.factor == PorducMoveFactorEnum.SALIDA ? 2 : object.factor == PorducMoveFactorEnum.NO_MOVE ? 0 : 1]);
            if (tagFilterOk) {
              this.dataFiltrada.data.push(object);
            }
          }
        });
      })
    }
    else {
      this.dataFiltrada.data = this.allStockMoves;
    }
    this.dataFiltrada.paginator = this.paginator;
  }

  applySearchFilter(searchValue: string) {
    this.dataFiltrada.data = this.allStockMoves.filter((element: M_Product_Move_Recambios) => {
      return element.defaultSearchFilter(searchValue.toString());
    });
  }

  updateDisplayedColumns(): void {
    setTimeout(() => {
      if (this.responsiveS.w < 600) {
        this.displayedColumns = ['factor', 'transac_name', 'quanti_mov'];
      } else if (this.responsiveS.w < 850) {
        this.displayedColumns = ['factor', 'transac_name', 'document', 'quanti_mov'];
      } else {
        this.displayedColumns = ['factor', 'transac_name', 'location', 'document', 'quanti_mov'];
      }
    });
  }

  openDialog(row: M_Product_Move_Recambios): void {
    this.dialog.open(MovesDetailDialogComponent, {
      disableClose: true,
      width: '500px',
      data: row,
      autoFocus : false
    });
  }

}
