<div class="upload-container"
     (click)="openFileDialog()"
     (dragover)="onDragOver($event)"
     (drop)="onDrop($event)"
     [class.dragging]="isDragging">

     <input 
     type="file" 
     [accept]="this.images ? 'image/jpeg, image/png, image/gif, image/bmp, image/tiff' : 'application/pdf'" 
     (change)="onFileChange($event)" 
     #fileInput />
     <div *ngIf="imageUrl" class="image-container">
      <button mat-icon-button class="close-button" (click)="clearImage()"><mat-icon>delete</mat-icon></button>
      <img [src]="imageUrl" alt="Selected Image" class="selected-image" />
    </div>
  
  <ng-container *ngIf="selectedFile && !images; else prompt">
    <p>Archivo seleccionado: {{ selectedFile.name }}</p>
    <button subscribed-user mat-flat-button color="primary" style="margin-top: 10px" (click)="removeFile($event)">Eliminar archivo</button>
  </ng-container>
  
  <ng-template #prompt>
    <p>{{this.images ? "Arrastra y suelta una imagen aquí o haz clic para seleccionar una.":"Arrastra y suelta un archivo PDF aquí o haz clic para seleccionar uno"}}</p>
  </ng-template>

</div>