import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewPath } from 'src/app/app-routing.module';
import { Filter, DayFilter, TagFilter, ClassSearcherFilter } from 'src/app/custom-classes/Filter';
import { filter } from 'src/app/custom-classes/MasterFilter';
import { ApiService } from 'src/app/services/Api/api.service';
import { SubscriptionService } from 'src/app/services/EinaMainData/subscription.service';
import { ParamsService } from 'src/app/services/params.service';
import { SessionService } from 'src/app/services/session.service';
import { PageStructureComponent } from 'src/app/components/page-structure/page-structure.component';
import { M_LastMovements } from 'src/app/models/M_LastMovement';
import { M_Invoice } from 'src/app/models/M_Invoice';
import { MatDialog } from '@angular/material/dialog';
import { DialogCheckoutControlComponent } from '../invoices/dialog-checkout-control/dialog-checkout-control.component';
import { TypePaymentService } from 'src/app/services/type-payment-service';
import { M_Purchase } from 'src/app/models/M_Purchase';
import { Views } from 'src/app/custom-classes/View';
import { TYPE_PAYMENT_FILTER } from 'src/app/constants/SharedFilters';
import { MASTER_CLIENT } from 'src/app/constants/masters';
export enum PageBalanceEnum {
  DATA_MOVEMENT = 1,
  PAYMENT_MOVEMENT = 2,
  CLIENT = 3
}
@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})

export class BalanceComponent implements OnInit {
  v = ViewPath;
  f = filter;
  loading = false;
  client = MASTER_CLIENT;
  ArrayListInvoices: M_Invoice[] = [];
  ArrayListPurchase: M_Purchase[] = [];
  ArrayListLastMovement: M_LastMovements[] = [];
  ArrayMovements: M_LastMovements[] = [];
  filtersToShow: Filter[] = [
    TYPE_PAYMENT_FILTER().setId(PageBalanceEnum.PAYMENT_MOVEMENT),
    new ClassSearcherFilter("Cliente", this.client, "Cliente").setId(PageBalanceEnum.CLIENT),
    new DayFilter("Fecha de creación").setId(PageBalanceEnum.DATA_MOVEMENT)
    
  ]
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_LastMovements>;
  constructor(public subS: SubscriptionService, public sessionS: SessionService, public params: ParamsService,
    public apiS: ApiService, private d: MatDialog, private typePaymentS: TypePaymentService) {
  }

  ngOnInit(): void {
    this.apiS.getBalance().then(res => {
      this.loading = true;
      // FALTA PASAR LOS MOVIMEINTOS UNA VEZ TENGA TODOS LLENAMOS LA TABLA.
      // this.getAllInvoices();
      const { invoices, purchases, advances } = this.separateInvoicesAndPurchases(res);
      this.ArrayListInvoices = invoices;
      this.ArrayListPurchase = purchases;
      this.ArrayListLastMovement = advances;
      const movementsArray = this.getMovements(invoices, purchases, advances);
      movementsArray.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      });
      console.log(movementsArray);
      this.ps.initTable(movementsArray);

    });



  }

  get invoices() {
    return this.ArrayListInvoices;
  }

  get purchases() {
    return this.ArrayListPurchase;
  }

  get quickFilter() {
    let f = this.filtersToShow.find(f => f.id == PageBalanceEnum.PAYMENT_MOVEMENT);
    if (f instanceof TagFilter) { return f };
    return undefined;
  }

  openDialog() {
    this.d.open(DialogCheckoutControlComponent, { data: { invoices: this.ArrayListInvoices, purchases: this.purchases, type_payments: this.typePaymentS.typePaymentArray } }).afterClosed().subscribe(res => {

    })
  }

  balanceIcon(lst: M_LastMovements) { return lst.type_payment.icon; }
  contact(lst: M_LastMovements) { return lst.contact?.name }
  invoice(lst: M_LastMovements) {
    if (lst.invoice instanceof M_Invoice) {
      return lst.invoice?.company_scope_id
    }
    return lst.purchase?.num_purchase;
  }

  typeName(lst: M_LastMovements) { return lst.typeName }

  importNatural(lst: M_LastMovements){return lst.importNatural;}

  getIds(event: any): number {
    let id = 0;
    if (event.invoice_id != undefined) { id = event.invoice_id }
    else if (event.id_buytransac != undefined) { id = event.id_buytransac }
    else if (event.type == 1) { id = event.client_id }
    return id;
  }

  viewClick(event: any): Views {
    let vw: Views = this.v.invoiceDetails;
    if (event.invoice_id != undefined) { vw = this.v.invoiceDetails }
    else if (event.id_buytransac != undefined) { vw = this.v.purchaseDetails }
    else if (event.type == 1) { vw = this.v.contactDetails }
    return vw
  }

  separateInvoicesAndPurchases(data: (M_Invoice | M_Purchase | M_LastMovements)[]): { invoices: M_Invoice[], purchases: M_Purchase[], advances: M_LastMovements[] } {
    const invoices: M_Invoice[] = [];
    const purchases: M_Purchase[] = [];
    const advances: M_LastMovements[] = [];

    data.forEach(item => {
      // Verificamos la propiedad para identificar el tipo
      if ('invoice_id' in item && item.invoice_id != undefined) {
        // Es un M_Invoice
        invoices.push(new M_Invoice(item));
      } else if ('buy_num' in item && !('invoice_id' in item)) {
        // Es un M_Purchase
        purchases.push(new M_Purchase(item));
      }
      else if ('type_payment_id' in item && item.invoice_id == undefined) {
        advances.push(new M_LastMovements(item));
      }
    });

    return { invoices, purchases, advances };
  }
  getMovements(invoices: M_Invoice[], purchases: M_Purchase[], advances: M_LastMovements[]): M_LastMovements[] {
    const movementsArray: M_LastMovements[] = [];

    // Recorriendo el array de invoices
    invoices.forEach(invoice => {
      // Agregar last_movement de la invoice
      if (invoice.last_movement && invoice.last_movement.length > 0) {
        movementsArray.push(...invoice.last_movement);
      }
    });

    // Recorriendo el array de purchases
    purchases.forEach(purchase => {
      // Agregar last_movement de la compra
      if (purchase.last_movement && purchase.last_movement.length > 0) {
        movementsArray.push(...purchase.last_movement);
      }

    });
    advances.forEach(advance => {
      movementsArray.push(advance);
    });
    return movementsArray;
  }


  getAllInvoices() {
    // Verificamos si 'ps.data' existe y es un array
    if (this.ps && Array.isArray(this.ps.data)) {
      // Recorremos 'this.ps.data', extraemos las facturas de cada elemento y las concatenamos
      this.ArrayListInvoices = this.ps.data
        .filter(item => item.invoices && Array.isArray(item.invoices)) // Filtramos los que tengan 'invoices'
        .flatMap(item => item.invoices); // Usamos flatMap para aplanar todos los arrays de invoices
    }
  }

}
