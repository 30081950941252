import { Injector } from '@angular/core';
import 'reflect-metadata';

const DESTROYABLE_SERVICES_KEY = 'destroyable_services';


/** The services using this decorator, need to implment te IDestroyOnLogout interface. */
export function DestroyOnLogOut(): ClassDecorator {
    return (target: any) => {
        const existingServices =
            Reflect.getMetadata(DESTROYABLE_SERVICES_KEY, Reflect) || [];
        Reflect.defineMetadata(
            DESTROYABLE_SERVICES_KEY,
            [...existingServices, target],
            Reflect
        );
    };
}

/** Get all the (@DestroyOnLogOut) services. */
export function getDestroyableServices(): any[] {
    return Reflect.getMetadata(DESTROYABLE_SERVICES_KEY, Reflect) || [];
}

/** Check if all the (@DestroyOnLogOut) implements the IDestroyOnLogout interface.  */
export function allDestroyableOk(injector: Injector) {
    const destroyableServices = getDestroyableServices();
    destroyableServices.forEach(serviceClass => {
        const instance = injector.get(serviceClass);
        if (instance && typeof instance.destroyData != 'function') {
            console.error("La classe", instance, "no implmenta la interficie IDestroyOnLogout")
        }
    });
}
