import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Serie } from 'src/app/models/M_Serie';
import { SerieFormComponent } from 'src/app/serie-form/serie-form.component';
import { ApiService } from 'src/app/services/Api/api.service';
import { RouterService } from 'src/app/services/router.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-create-serie',
  templateUrl: './create-serie.component.html',
  styleUrls: ['./create-serie.component.css']
})
export class CreateSerieComponent implements OnInit {
  v = ViewPath;
  public loaded = false;
  isEdit: boolean = false;
  serie: M_Serie | undefined;
  @ViewChild(SerieFormComponent, { static: true }) serieform!: SerieFormComponent;
  constructor(private apiS: ApiService, route: ActivatedRoute, public routerS: RouterService, public snackS: SnackService,
    @Optional() public dialogRef: MatDialogRef<CreateSerieComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: M_Serie | undefined,
  ) {
    if (this.isOnDialog && this.data != undefined) {
      this.serie = new M_Serie(this.data);

    }
  }
  ngOnInit(): void {
    this.loaded = true;
  }
  get isOnDialog() {
    return Object.keys(this.dialogRef).length != 0;
  }
  create() {
    if (!this.serieform.form.valid) { return; }
    else {

      let serie: M_Serie = new M_Serie(this.serieform.form.value);
      this.apiS.createSerie(serie).then(res => {
        if (res) {
          if (this.isOnDialog) {
            this.dialogRef.close(res);
          } else {
            this.routerS.goTo(this.v.series);
          }
          this.snackS.show('Serie creada');
          this.dialogRef.close(res);
        }
      });
    }
  }
}
