import { BaseModule, ModuleDetails } from "./Factory/BaseModule";
import { M_MTRModule } from "./M_MTRModule";

export class M_FakeModule extends BaseModule {


    constructor(public id_ : number, public name_: string, public title_: string, public desc_: string,) {
        super({ id: id_ });
    }

    get name() { return this.name_ }
    get corename() { return this.name_ }
    get title() { return this.title_; }
    get desc() { return this.desc_; }

    generateFuncionalities() {
        return [
            { icon: "manufacturing", title: "Configuración", tooltip: "Ajustar que quieres exportar" },
            { icon: "file_export", title: "Exportación", tooltip: "Puedes exportar datos en un rango de dos fechas" }
        ];
    }

    generateModuleDetails(): ModuleDetails[] {
        return [
        ]
    }

    override isMTR(): this is M_MTRModule { return false; }

}