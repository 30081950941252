import { Component, ViewChild } from '@angular/core';
import { StoreHouseEntryAddProductComponent } from './storehouse-entry-add-product/storehouse-entry-add-product.component';
import { MatDialog } from '@angular/material/dialog';
import { M_StoreHouseEntry } from 'src/app/models/M_StoreHouseEntry';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/services/Api/api.service';
import { EnrtryTypeEnum, entranceTypeIcon, entranceTypeName } from 'src/app/enums/EnrtryTypeEnum';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MASTER_PROVIDER } from 'src/app/constants/masters';
import { emittedRow } from 'src/app/components/excel-column-matcher/excel-column-matcher.component';
import { exelColumId, storeHouseExpectedColunmns } from 'src/app/components/excel-column-matcher/imports/storehouse-entry';
import { M_Product } from 'src/app/models/Products/M_Product';
import { M_DiscountGroup } from 'src/app/models/M_DiscountGroupLine';
import { FileType } from 'src/app/enums/FileType';
import { isMobile } from 'src/app/utils/Browserutils';
import { SnackService } from 'src/app/services/snack.service';
import { RouterService } from 'src/app/services/router.service';
import { ParameterStateComponent } from 'src/app/components/parameter-state/parameter-state.component';
import { ActivatedRoute } from '@angular/router';
import { ClassSearcherComponent } from 'src/app/components/class-searcher/class-searcher.component';
import { M_Contact } from 'src/app/models/M_Contact';
import { setUpDiscountGroupByString } from 'src/app/utils/FunctionUtils';

@Component({
  selector: 'app-storehouse-entry',
  templateUrl: './create-storehouse-entry.component.html',
  styleUrls: ['./create-storehouse-entry.component.css']
})
export class CreateStorehouseEntryComponent extends ParameterStateComponent {

  @ViewChild('providerSearch', { static: true }) providerSearcher?: ClassSearcherComponent<M_Contact>

  provider = MASTER_PROVIDER;
  entrancesType = EnrtryTypeEnum;
  v = ViewPath;
  ft = FileType;
  loaded = false;
  entry: M_StoreHouseEntry = new M_StoreHouseEntry({});
  mobile = isMobile();
  form: FormGroup;
  expectedColums = storeHouseExpectedColunmns;
  allProducts: M_Product[] = [];
  discountGroups: M_DiscountGroup[] | undefined;

  entryName = entranceTypeName;
  entryIcon = entranceTypeIcon;

  constructor(routerS: RouterService, route: ActivatedRoute, private fb: FormBuilder, private d: MatDialog, private apiS: ApiService, private snackS: SnackService) {
    super(routerS, route, ["client"])
    this.form = this.fb.group({
      provider_id: [],
      entranceType: [this.entrancesType.PMP],
      dnote_prov_num: ['', {
        asyncValidators: [this.checkDuplicatedDocu()],
      }],
      dnote_prov_date: [new Date(), Validators.required]
    });

    this.form.get('provider_id')?.valueChanges.subscribe(v => {
      this.entry.provider_id = v;
    })

    this.form.get('dnote_prov_num')?.valueChanges.subscribe(v => {

    })

    this.apiS.getAllDiscountGroups().then(res => {
      this.discountGroups = res;
    })
  }

  override onParam(param: string, value: string): void {
    if (param == "client") {
      this.providerSearcher?.select(value.getNumber())
    }
  }

  openAddProduct() {
    this.d.open(StoreHouseEntryAddProductComponent).afterClosed().subscribe(res => { })
  }

  onProductSearcherLoaded(products: M_Product[]) {
    this.allProducts = products;
    this.loaded = true
  }

  enter() {
    this.apiS.storeHouseEntry(this.entry).then(res => {
      this.snackS.show("Entrada realizada con éxito");
      this.routerS.goTo(ViewPath.storeHouseEntry);
    })
  }

  get isAtCost() {
    return this.form.get('entranceType')?.value == this.entrancesType.COST;
  }

  onRowEnter(row: emittedRow) {

    if (this.discountGroups) {
      console.log("Ref : ", row[exelColumId.REF]);
      console.log("Name : ", row[exelColumId.NAME]);
      console.log("Cantidad : ", row[exelColumId.QUANTITY]);
      console.log("Coste : ", row[exelColumId.COST]);
      console.log("PVP (tarifa) : ", row[exelColumId.PVP_T]);
      console.log("Discount : ", row[exelColumId.DISCOUNT]);
      console.log("Discount Group : ", row[exelColumId.DISCOUNT_GROUP]);
      console.log("Grupo contable : ", row[exelColumId.ACCOUNTING_GROUP]);

      let p = new M_Product({});
      p.reference = row[exelColumId.REF] as string;
      p.name = row[exelColumId.NAME] as string;
      p.quantity = row[exelColumId.QUANTITY] as number;
      p.buy_price = row[exelColumId.COST] as number;
      p.pvp_t = row[exelColumId.PVP_T] as number;
      p.discount = row[exelColumId.DISCOUNT] as number;

      /** To do : discount groups */
      setUpDiscountGroupByString(this.discountGroups, row[exelColumId.DISCOUNT_GROUP] as string, p);
      p.accounting_group_name = row[exelColumId.DISCOUNT] as string;


      let finded = this.allProducts.find(realProduct => realProduct.reference.toLocaleLowerCase().trim() == p.reference.toLocaleLowerCase().trim());
      if (finded) {
        p.product_id = finded.product_id;
        p.locations = finded.locations;
        if (finded.locations[0]) { p.selected_location = finded.locations[0].id; }
        p.pvp_t_ant = finded.pvp_t;
      }

      this.entry.addProduct(p, "products_file");
    }
  }

  get duplicatedDocu() {
    return this.form.get('dnote_prov_num')?.getError('duplicated');
  }

  checkDuplicatedDocu(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return this.apiS.checkDuplicatedStoreHouseDocu(control.value).then(res => {
        return res ? { error: "duplicated" } : null
      })
    };
  }
}
