import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BrandModelInputComponent } from '../components/brand-model-input/brand-model-input.component';
import { M_Brand } from '../models/M_Brand';
import { RouterService } from '../services/router.service';
import { AccountingGroupSelectorComponent } from '../components/accounting-group-selector/accounting-group-selector.component';
import { M_Serie } from '../models/M_Serie';
import { M_AccountingGroup } from '../models/AccountingGroups/M_AccountingGroup';
import { BrandsModelsSeriesComponent } from '../brands-models-series/brands-models-series.component';

@Component({
  selector: 'app-serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.css']
})
export class SerieFormComponent {
  public form: UntypedFormGroup;
  @Input() isDetails = false;
  @Input() loaded ?: boolean;
  @Input() serie ?: M_Serie;

  @ViewChild(BrandsModelsSeriesComponent, { static: true }) brandsModelSerie!: BrandsModelsSeriesComponent;
  @ViewChild(AccountingGroupSelectorComponent, { static: true }) accGroup!: AccountingGroupSelectorComponent;
  constructor(private formBuilder: UntypedFormBuilder,route: ActivatedRoute, routerS: RouterService){
    
    this.form = this.formBuilder.group({
      id:[''],
      serie :['',[Validators.required]],
      name : ['',[Validators.required]],
      brand_id :['',[Validators.required]],
      brand_name :[''],
      accounting_id : [''],
      accounting_detail : [''],
      accounting_agent : [''],
    })
    if(this.serie){
      console.log("hola");
      setTimeout(() => {
        if (this.serie?.brand_id) {
          this.brandsModelSerie?.brandSearcherComponent?.select(this.serie.brand_id);
        }},0);
    }
  }
  onSelectBrand(b:M_Brand){
    this.form.patchValue({brand_id:b?.id,brand_name:b.name});
  }
  selectAcc(acc:M_AccountingGroup | null){
    this.form.patchValue({accounting_id:acc?.id});
  }
  get isSerie(){
    if(this.serie && this.serie.accounting){
      this.form.get('accounting')?.disable();
      return this.serie.accounting.id != undefined;
    }
    return false;
  }
}
