<app-page-structure #ps [pageTitle]="'Marcas'" [masterClass]="undefined" [autocompleteID]="'marca'"
    searchLabel="Buscar marca" listTitleText="Listado de marcas" [data]="[]"
    [displayedHeader]=" ['Nombre']"
    [displayedColumns]="['name',]" [quickFilter]="quickFilter"
    [preIcon]="[typeVehicle]"
    [goBackCustom]="{
        text:'Listado vehículos',
        view:v.garage
    }"
    [filter]="f" [filters]="filtersToShow" (onclickRow)="onDialogRef($event)" [createButton]="
        {
            text : 'Crear marca' , 
            icon : 'style',
            tooltip: 'Todavía no disponible 🕒',
            disabled:true
        }"  [quickFilter]="quickFilter"
    (onClickCreateButton)="onDialogRef($event)">
</app-page-structure>